import styled from "styled-components";
import { maxDevice } from "components/common/breakpoints";

export const Aside = styled.aside`
  flex: 0 0 50px;
  display: flex;
  flex-direction: column;
  width: 50px;
  height: calc(100% - 48px);

  background-color: var(--color--white, #fff);
  transition: 200ms linear;
  box-shadow: var(--box-shadow--main);
  overflow-y: scroll;

  &.isActive { flex: 0 0 200px; width: 200px}

  #modal-root & {
    position: fixed;
    top: 48px;
    right: 100%;
    width: 100%;
    height: 100%;

    z-index: 1;
    transition: 200ms linear;
    background-color: var(--color--white, #fff);

    @media ${maxDevice.laptop} {
      height: calc(100% - 48px);
    }
  }

  #modal-root &.isActive {right: 0;}
`;