import React from 'react'
import { InfoBtnIcon, InfoButton } from './InfoBtn.styled'

const InfoBtn = ({showInfo, setShowInfo }) => {
  return (
    <InfoButton onClick={() => setShowInfo(!showInfo)}>
      <InfoBtnIcon />
    </InfoButton>
  )
}

export default InfoBtn