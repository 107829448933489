import { BtnBasic } from "components/AddRouteForm/AddRouteForm.styled";
import { minDevice } from "components/common/breakpoints";
import styled from "styled-components";
// import { minDevice } from "components/common/breakpoints";


export const BookingFormTitleWrap = styled.div`
  position: relative;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const BookingFormTitle = styled.strong`
  // display: flex;
  // align-items: center;
  // justify-content: space-between;

  min-height: 20px;
  margin-top: 20px;

  font-size: 16px;
  font-weight: 500;
`;

export const BookingFormPriceWrap = styled.div`
  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  display: flex;
  align-items: center;
  gap: 16px;
`;

export const BookingFormPrice = styled.p`
  // flex-grow: 1;
  // flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  flex: 0 0 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  height: 44px;

  background-color: var(--color--bg-light);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;

  & span {
    font-weight: 500;
  }
`;

export const BookingFormBox = styled.div` 
  position: relative;
  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 16px;
  min-height: 44px;
`;

export const BookingFormSelectTag = styled.div`
  position: relative;

  min-height: 44px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.locations_country { 
    flex: 0 0 160px;
    max-width: 200px;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
  &.locations_city { 
    flex: 0 0 160px;
    max-width: 200px;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
  &.locations_address { 
    flex: 1 0 120px;
    // max-width: 200px;
  }
  &.locations_name { 
    flex: 1 0 120px;
    max-width: 200px;
  }
`;

export const BookingFormAddPassengerBtn = styled(BtnBasic)`
  position: absolute;
  bottom: 0px;
  left: 0px;

  height: 32px;
  width: 200px;

  font-size: 12px;
  font-weight: 600;
  color: var(--color--accent);

  background-color: #fff;
  border: 1px solid var(--color--accent);
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: var(--color--accent);
  }
`;
export const BookingFormDeletePassengerBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  color: var(--color--gray);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    &::before {
      color: var(--color--accent);
    }
  }

  &::before {
    content: "+";

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 40px;
    transform: rotate(45deg);
    pointer-events: none;
  }
`;
