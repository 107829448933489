import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
// components
import Header from "components/Header/Header";
import SidebarWrap from "components/Sidebar/SidebarWrap";
import LoaderLocal from "components/Loader/LoaderLocal";
import { BREAKPOINT_FOR_MOBILE_MENU } from "components/common/constants";
// styled
import { PageWrap, Main, MainWrap } from "./SharedLayout.styled";

const SharedLayout = () => {
  const { width } = useWindowDimensions();
  const [isSmallView, setIsSmallView] = useState(width < BREAKPOINT_FOR_MOBILE_MENU);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(width >= BREAKPOINT_FOR_MOBILE_MENU);

  const body = document.querySelector("body");
  if(isSmallView && isBurgerMenuOpen) {body.classList.add('mobileMenuIsOpen')}
  if(isSmallView && !isBurgerMenuOpen) {body.classList.remove('mobileMenuIsOpen')}

  useEffect(() => { setIsSmallView(width < BREAKPOINT_FOR_MOBILE_MENU); }, [width]);

  return (
    <PageWrap>
      <Header isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      <MainWrap>
        <SidebarWrap isSmallView={isSmallView} isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
        <Main>
          <Suspense fallback={ <LoaderLocal />}>
          {/* <Suspense > */}
            <Outlet />
          </Suspense>
        </Main>
      </MainWrap>
    </PageWrap>
  );
};

export default SharedLayout;
