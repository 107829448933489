import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const addLocationDistance = createAsyncThunk(
  "locations/addLocationDistance",
  async (data, thunkAPI) => {
    try {
      const res = await axios.post("/locations/distance", data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
