export const selectLocationsStatus = state => state?.locations?.status;
export const selectLocationsError = state => state?.locations?.error;

export const selectLocationsList = state => state?.locations?.list;
export const selectLocation = state => state?.locations?.location;
export const selectCountriesList = state => state?.locations?.countries;
export const selectCitiesList = state => state?.locations?.cities;
export const selectAddressesList = state => state?.locations?.addresses;

export const selectLocationsDistance = state => state?.locations?.distance;

