import React from "react";
import { BookingBtnTag } from "./BookingBtn.styled";

const BookingBtn = ({ price, type, data, currentPrice, currentLang, handleBookingBtnClick }) => {
  const isCurrent = type === currentPrice?.type && "current"

  return (
    <BookingBtnTag type="button" onClick={() => handleBookingBtnClick({price, type}, data)} className={isCurrent && "current"} disabled={isCurrent} >
      <span>{price}€</span>{text.booking[currentLang]}
    </BookingBtnTag>
  );
};

export default BookingBtn;

// €

const text = {
  booking: {
    de: "reservierung",
    ru: "бронировать",
    ua: "бронювати",
  },
};
