import styled from "styled-components";
import { ReactComponent as IconLogout } from "./icons/logout.svg";
import { maxDevice } from "components/common/breakpoints";

export const LogoutBtnTag = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0 16px;
  margin: auto 0 16px;

  color: var(--color--dark, #1e1e1e);
  font-size: 0px;
  white-space: nowrap;

  border: none;
  cursor: pointer;

  @media ${maxDevice.laptop} { padding: 0 32px; }
  @media ${maxDevice.tablet} { padding: 0 16px; }

  &:hover, &:focus {
    color: var(--color--white, #fff);
    background: var(--color--accent, #14a0fd);
  }

  aside.isActive & {
    font-size: 16px;
  }
`;

export const LogoutBtnIcon = styled(IconLogout)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;