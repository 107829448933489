import React from "react";
import { createPortal } from 'react-dom';

import { LoaderBackdroop, LoaderContent } from "./Loader.styled";

const modalRoot = document.querySelector('#modal-root');

const Loader = () => {
  return createPortal(
    <LoaderBackdroop>
      <LoaderContent />
    </LoaderBackdroop>,
    modalRoot
  );
};

export default Loader;
