import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getTripsBySearchQuery = createAsyncThunk(
  "trips/getTripsBySearchQuery",
  async ({data, pathname = ''}, thunkAPI) => {
    try {
      const res = await axios.post(`/directions/search${pathname}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);

