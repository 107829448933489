import { createSlice } from "@reduxjs/toolkit";
import { createTrip, getDirections, getDirectionsByTripIdAndSearchQuery, getTrips, getTripById, getTripsBySearchQuery, updateTrip } from "./operations";
import { getTripByIdReverse } from "./operations/getTripByIdReverse";
import { updateTripReverse } from "./operations/updateTripReverse";
import { updateDirections } from "./operations/updateDirections";

const initialState = {};

export const tripsSlise = createSlice({
  name: "trips",
  initialState,
  reducers: {
    resetTripsState() { return initialState; },
    resetTripsStatus(state) { state.status = ""; },
    resetTripsError(state) { state.error = null; },
    // resetTripsSearch(state) { state.search = []; },
    resetTripsSearch(state) { state.search = null; },
    addTripPoint(state, action) {
      const {storageKey, selectedItem} = action.payload;

      state.new = state.new ? state.new = {...state.new} : {}
      state.new[storageKey] = selectedItem;
    },
    updateExtraPoints(state, action) {
      state.new.extraPoints = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createTrip.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createTrip.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.new = action.payload;
      })
      .addCase(createTrip.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getTrips.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getTrips.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getTrips.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getTripsBySearchQuery.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getTripsBySearchQuery.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.search = action.payload;
      })
      .addCase(getTripsBySearchQuery.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getTripById.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getTripById.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.trip = action.payload;
      })
      .addCase(getTripById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getTripByIdReverse.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getTripByIdReverse.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.tripReverse = action.payload;
      })
      .addCase(getTripByIdReverse.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateTrip.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateTrip.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.trip = action.payload;
      })
      .addCase(updateTrip.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateTripReverse.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateTripReverse.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.tripReverse = action.payload;
      })
      .addCase(updateTripReverse.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      
      .addCase(getDirections.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getDirections.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.directions = action.payload;
      })
      .addCase(getDirections.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getDirectionsByTripIdAndSearchQuery.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getDirectionsByTripIdAndSearchQuery.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.direction = action.payload;
      })
      .addCase(getDirectionsByTripIdAndSearchQuery.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateDirections.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateDirections.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;
      })
      .addCase(updateDirections.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      // .addCase(getDirectionById.pending, (state, action) => {
      //   state.status = "pending";
      //   state.error = null;
      // })
      // .addCase(getDirectionById.fulfilled, (state, action) => {
      //   state.status = "fulfilled";
      //   state.error = null;

      //   state.direction_by_id = action.payload;
      // })
      // .addCase(getDirectionById.rejected, (state, action) => {
      //   state.status = "rejected";
      //   state.error = action.payload;
      // })

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetTripsState, resetTripsStatus, resetTripsError, addTripPoint, updateExtraPoints, resetTripsSearch } = tripsSlise.actions;
export const tripsReducer = tripsSlise.reducer;
