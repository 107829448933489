import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateAgencyById = createAsyncThunk(
  "agencies/updateAgencyById ",
  async ({ id, data }, thunkAPI) => {
    try {
      const res = await axios.post(`/agencies/${id}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
