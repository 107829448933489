import React from 'react'
// components
import InputTypeText from 'components/Form/Input/InputTypeText'
import InputTypeEmail from 'components/Form/Input/InputTypeEmail'

const BookingFormOrderer = ({ currentLang, resetState }) => {
 function handleGetIsValid () {}

  return (
    <>
      <InputTypeText required={true} resetState={resetState} items={3} name={"salutation"} placeholder={text.salutation[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"user_last_name"} placeholder={text.last_name[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"user_first_name"} placeholder={text.first_name[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"street"} placeholder={text.street[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"postal_code"} placeholder={text.postcode[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"city"} placeholder={text.city[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"contact_tel"} placeholder={text.tel[currentLang]} />
      <InputTypeText required={true} resetState={resetState} items={3} name={"contact_tel_mobile"} placeholder={text.tel_mobile[currentLang]} />
      <InputTypeEmail required={true} resetState={resetState} items={3} name={"contact_email"} handleGetIsValid={handleGetIsValid} />
    </>
  )
}

export default BookingFormOrderer

const text = {
  salutation: {
    de: "Anrede",
    ru: "Приветствие",
    ua: "Привітання",
  },
  first_name: {
    de: "Vorname",
    ru: "Имя",
    ua: "І'мя",
  },
  last_name: {
    de: "Nachname",
    ru: "Фамилия",
    ua: "Прізвище",
  },
  street: {
    de: "Straße",
    ru: "Улица",
    ua: "Вулиця",
  },
  postcode: {
    de: "PLZ",
    ru: "Почтовый индекс",
    ua: "Поштовий індекс",
  },
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  tel: {
    de: "Telefonnummer",
    ru: "Номер телефона",
    ua: "Номер телефону",
  },
  tel_mobile: {
    de: "Handynummer",
    ru: "Номер мобильного телефона",
    ua: "Номер мобільного телефону",
  },
}
