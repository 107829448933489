import { createSlice } from "@reduxjs/toolkit";
import { createBooking, getBookings, getPassengers } from "./operations";
import { updateBookings } from "./operations/updateBookings";
import { getBookingById } from "./operations/getBookingById";
import { getAllPassengers } from "./operations/getAllPassengers";

const initialState = {};

export const bookingsSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {
    resetBookingsState() { return initialState; },
    resetBookingsStatus(state) { state.status = ""; },
    resetBookingsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createBooking.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getBookings.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getBookings.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getBookings.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(getPassengers.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getPassengers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.passengers = action.payload;
      })
      .addCase(getPassengers.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getAllPassengers.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getAllPassengers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.allPassengers = action.payload;
      })
      .addCase(getAllPassengers.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(getBookingById.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getBookingById.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.booking = action.payload;
      })
      .addCase(getBookingById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(updateBookings.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateBookings.fulfilled, (state, action) => {
        state.status = "updated";
        state.error = null;

        state.list = [...state.list].map(i => {
          if (action.payload?.find(item => item?._id === i?._id)) return action.payload?.find(item => item?._id === i?._id)

          return i
        })
      })
      .addCase(updateBookings.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetBookingsState, resetBookingsStatus, resetBookingsError} = bookingsSlice.actions;
export const bookingsReducer = bookingsSlice.reducer;
