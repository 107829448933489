import { createSlice } from "@reduxjs/toolkit";
import { createTransport, getTransports } from "./operations";
import { updateTransportById } from "./operations/updateTransportById";

const initialState = {};

export const transportsSlise = createSlice({
  name: "transports",
  initialState,
  reducers: {
    resetTransportsState() { return initialState; },
    resetTransportsStatus(state) { state.status = ""; },
    resetTransportsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createTransport.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createTransport.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createTransport.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })
      .addCase(getTransports.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getTransports.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getTransports.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateTransportById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateTransportById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.transport = action.payload;
      })
      .addCase(updateTransportById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

  

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetTransportsState, resetTransportsStatus, resetTransportsError } = transportsSlise.actions;
export const transportsReducer = transportsSlise.reducer;
