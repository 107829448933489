import React from 'react'
import { BasicFormBoxTitle, BasicNextBtn, FormSuccessMsgBox } from 'components/Form/Form.styled'

const FormSuccessMsg = ({error_msg = "", page_title, success_msg, add_more, go_to_list, item_msg, go_to_item, handleFormStatusBtnClick}) => {
  return (
    <FormSuccessMsgBox>
      <BasicFormBoxTitle style={{textAlign: "center"}}>{error_msg} {page_title} {success_msg}</BasicFormBoxTitle>
      {item_msg && <>
        <BasicFormBoxTitle style={{textAlign: "center"}}>{item_msg}</BasicFormBoxTitle>
        <BasicNextBtn type="button" style={{flexBasis: "240px"}} onClick={() => handleFormStatusBtnClick("item")}>{go_to_item}</BasicNextBtn>
      </>}
      <BasicNextBtn type="button" style={{flexBasis: "240px"}} onClick={() => handleFormStatusBtnClick("list")}>{go_to_list}</BasicNextBtn>
      <BasicNextBtn type="button" style={{flexBasis: "240px"}} onClick={() => handleFormStatusBtnClick("new")}>{add_more}</BasicNextBtn>
    </FormSuccessMsgBox>
  )
}

export default FormSuccessMsg
