import React, { useState } from "react";
// styled
import { BasicSelectTag, BasicSelectOptionsList, BasicSelectOptionsBtn, } from "./BasicSelect.styled";

const BasicSelect = ({ items = 1, className, placeholder, selectOptionsData, disabled, setValue, value }) => {
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [activeOptions, setActiveOptions] = useState(value);

  const handleOptionsBtn = (i) => {
    setShowOptionsList(!showOptionsList);
    setActiveOptions(i?.optionClass);
    setValue(i?.optionType);
  };

  return (
    <BasicSelectTag style={{ "--items": items }} className={disabled ? `${className} disabled` : className}>
      <BasicSelectOptionsList className={showOptionsList && "active"}>
        {placeholder && (
          <BasicSelectOptionsBtn
            type="button"
            disabled={disabled}
            className={!activeOptions ? "title" : ""}
            // onClick={handleOptionsBtn}
            onClick={() => handleOptionsBtn()}
          >
            {placeholder}
          </BasicSelectOptionsBtn>
        )}

        {selectOptionsData &&
          selectOptionsData?.map((i, idx) => {
            return (
              <BasicSelectOptionsBtn
                key={idx}
                type="button"
                disabled={disabled}
                className={
                  i?.optionClass === activeOptions
                    ? `${i?.optionClass} active`
                    : i?.optionClass
                }
                onClick={() => handleOptionsBtn(i, idx)}
              >
                {i?.optionTitle}
              </BasicSelectOptionsBtn>
            );
          })}
      </BasicSelectOptionsList>
    </BasicSelectTag>
  );
};

export default BasicSelect;
