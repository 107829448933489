import React from 'react';
import { useSelector } from 'react-redux';
// react
import { selectLang } from 'redux/lang/langSelectors';
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
// components
import { BREAKPOINT_FOR_MOBILE_MENU } from "components/common/constants";
// styled
import {
  // PageNavIconCog,
  PageNavIconDirection,
  PageNavIconLocation,
  PageNavIconSearch,
  PageNavIconUsers,
  PageNavItem,
  PageNavLink,
  PageNavList,
  PageNavTag,
} from "./PageNav.styled";
import { text_global } from 'components/common/text';

const DispatcherPageNav = ({ setIsBurgerMenuOpen }) => {
  const currentLang = useSelector(selectLang) || "de";
  const { width } = useWindowDimensions();

  const handleClick = () => {
    if (width < BREAKPOINT_FOR_MOBILE_MENU) setIsBurgerMenuOpen(false);
  };

  return (
    <PageNavTag>
      <PageNavList onClick={handleClick}>
        <PageNavItem>
          <PageNavLink to={"/bookings"}> <PageNavIconSearch /> {text.bookings[currentLang]} </PageNavLink>
        </PageNavItem>
        <PageNavItem>
          <PageNavLink to={"/passengers"}> <PageNavIconUsers /> {text_global.passengers[currentLang]} </PageNavLink>
        </PageNavItem>
        <PageNavItem>
          <PageNavLink to={"/trips"}> <PageNavIconDirection /> {text.trips[currentLang]} </PageNavLink>
        </PageNavItem>

        <PageNavItem>
          <PageNavLink to={"/locations"}> <PageNavIconLocation /> {text.locations[currentLang]} </PageNavLink>
        </PageNavItem>
        
        {/* <PageNavItem className="settings">
          <PageNavLink to={"/settings"}> <PageNavIconCog /> {text.settings[currentLang]} </PageNavLink>
        </PageNavItem> */}
      </PageNavList>
    </PageNavTag>
  );
};

export default DispatcherPageNav;

const text = {
  trips: {
    de: "Buslinien",
    ru: "Рейсы",
    ua: "Рейси",
  },
  users: {
    de: "Benutzer",
    ru: "Пользователи",
    ua: "Користувачі",
  },
  carriers: {
    de: "Firmen",
    ru: "Перевозчики",
    ua: "Перевізники",
  },
  agencies: {
    de: "Agenturen",
    ru: "Агентства",
    ua: "Агенції",
  },
  locations: {
    de: "Standorte",
    ru: "Локации",
    ua: "Локації",
  },
  routes: {
    de: "Routen",
    ru: "Маршруты",
    ua: "Маршрути",
  },
  discounts: {
    de: "Rabatte",
    ru: "Скидки",
    ua: "Знижки",
  },
  baggage: {
    de: "Gepäck",
    ru: "Багаж",
    ua: "Багаж",
  },
  transports: {
    de: "Transporte",
    ru: "Транспорт",
    ua: "Транспорт",
  },
  search_trips: {
    de: "Flugsuche",
    ru: "Поиск рейсов",
    ua: "Пошук рейсів",
  },
  bookings: {
    de: "Reservierungen",
    ru: "Бронирование",
    ua: "Бронювання",
  },
  settings: {
    de: "Einstellungen",
    ru: "Настройки",
    ua: "Налаштування",
  },
};
