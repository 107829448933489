import React, { useEffect, useState } from "react";
// styled
import { BasicSelectTag, BasicSelectOptionsList, BasicSelectOptionsBtn, } from "./BasicSelect.styled";

const SelectDiscount = ({initValue, id, placeholder, currentLang = "de", selectOptionsData, handleSelectClick, className, disabled}) => {
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [activeOptions, setActiveOptions] = useState(initValue);
  
  const isActiveOptionsContains = selectOptionsData?.filter(i => i?._id === activeOptions?._id)

  const handleOptionsBtn = (i) => {
    if (i?._id === activeOptions?._id) return setShowOptionsList(!showOptionsList);

    setActiveOptions(i);
    setShowOptionsList(!showOptionsList);
    if(showOptionsList) handleSelectClick(i, id);
  };
  
  useEffect(() => {
    setActiveOptions('');
    setShowOptionsList(false);
  }, [selectOptionsData]);

  useEffect(() => {
    setActiveOptions(initValue);
    setShowOptionsList(false);
  }, [initValue]);

  return (
    <BasicSelectTag style={{height: "44px", flex: "1 0 300px", marginLeft: "auto" }} className={disabled ? `${className} disabled` : className} >
      <BasicSelectOptionsList className={showOptionsList && "active"}>
        <BasicSelectOptionsBtn
          type="button"
          className={(!activeOptions || !isActiveOptionsContains) && "title"}
          style={{ height: "44px"}}
          onClick={() => handleOptionsBtn("")}
        >
          {placeholder ? placeholder : text.select_discounts[currentLang]}
        </BasicSelectOptionsBtn>

        {selectOptionsData &&
          selectOptionsData?.map((i, idx) => {
            const unit = i?.type === "fixed" ? "€" : "%"

            return (
              <BasicSelectOptionsBtn
                key={idx}
                type="button"
                className={ activeOptions && activeOptions?._id === i?._id && "active" }
                style={{ height: "44px", justifyContent: "space-between"}}
                onClick={() => handleOptionsBtn(i, idx)}
              >
                {i?.name[currentLang]} <span>{i?.value} {unit}</span>
              </BasicSelectOptionsBtn>
            );
          })}
      </BasicSelectOptionsList>
    </BasicSelectTag>
  );
};

export default SelectDiscount;

const text = {
  select_discounts: {
    de: "Rabatte auswählen",
    ru: "Выберите скидки",
    ua: "Виберіть знижки",
  },
};