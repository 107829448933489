import styled from "styled-components";
import { Link } from "react-router-dom";

import { maxDevice } from "components/common/breakpoints";

export const WelcomeBoxWrap = styled.div`
  margin-left: auto;
`;

export const WelcomeBoxText = styled.p`
  color: #fff;

  @media ${maxDevice.tablet} { display: none; }
`;
export const WelcomeBoxLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 32px;
  padding: 0 4px;

  color: #fff;

  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  & span {
    @media ${maxDevice.tablet} { display: none}
  }

  &:hover {
    box-shadow: var(--box-shadow--main);
    border-color: #fff;
  }
`;
