import React from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// components
import TableRowAllPassengers from "./TableRowAllPassengers";
// styled
import { TableTag, TableWrap } from "components/Table/Table.styled";
import { text_global } from "components/common/text";
import { TitleH2 } from "components/common/common.styled";

const TableAllPassengers = ({ type }) => {
  const currentLang = useSelector(selectLang) || "de";
  const dataList = useOutletContext();

  return (
    dataList?.length > 0 ? (
      <TableWrap>
        <TableTag>
          <thead>
            <tr>
              {/* <th scope="col" className="id">№</th> */}
              <th scope="col" className="created_date">{text.last_name[currentLang]}</th>
              <th scope="col" className="created_date">{text.first_name[currentLang]}</th>
              <th scope="col" className="created_date">{text.birth_date[currentLang]}</th>
              <th scope="col" className="buch">{text.price[currentLang]}</th>
              <th scope="col" className="buch">hin</th>
              <th scope="col" className="buch">ruk</th>
              <th scope="col" className="buch">buch</th>
              <th scope="col" className="direction">{text_global?.trip[currentLang]}</th>
              <th scope="col" className="carrier_name">{text_global?.carrier_name[currentLang]}</th>
 
            </tr>
          </thead>
          <tbody>
            {type === 'all' && dataList?.map((i, idx) => <TableRowAllPassengers key={idx} idx={idx} tableRowData={i} currentLang={currentLang} />) }
          </tbody>
        </TableTag>
      </TableWrap>
    ) : <TitleH2>{text_global.data_not_found[currentLang]}</TitleH2>
  );
}

export default TableAllPassengers

const text = {
  first_name: {
    de: "Vorname",
    ru: "Имя",
    ua: "І'мя",
  },
  last_name: {
    de: "Nachname",
    ru: "Фамилия",
    ua: "Прізвище",
  },
  birth_date: {
    de: "Geburtsdatum",
    ru: "Дата рождения",
    ua: "Дата народження",
  },
  passport_id: {
    de: "Passport-ID",
    ru: "Номер паспорта",
    ua: "Номер паспорта",
  },
  select: {
    de: "Wählen",
    ru: "Выбрать",
    ua: "Обрати",
  },
  passenger: {
    de: "passenger",
    ru: "Пассажир",
    ua: "Пасажир",
  },
  discounts: {
    de: "Rabatte",
    ru: "Скидки",
    ua: "Знижки",
  },
  price: {
    de: "Preis",
    ru: "Цена",
    ua: "Ціна",
  },
  in_bus: {
    de: "im bus",
    ru: "в автобусе",
    ua: "в автобусі",
  },

  seat_number: {
    de: "Sitz",
    ru: "Место",
    ua: "Місце",
  },
  back: {
    de: "zurück Sitz",
    ru: "обратно Место",
    ua: "назад Місце",
  },

  contact_tel: {
    de: "Telefon",
    ru: "Телефон",
    ua: "Телефон",
  },

  street: {
    de: "Straße",
    ru: "Улица",
    ua: "Вулиця",
  },
  postcode: {
    de: "PLZ",
    ru: "Почтовый индекс",
    ua: "Поштовий індекс",
  },
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  tel: {
    de: "Telefonnummer",
    ru: "Номер телефона",
    ua: "Номер телефону",
  },
  tel_mobile: {
    de: "Handynummer",
    ru: "Номер мобильного телефона",
    ua: "Номер мобільного телефону",
  },


  order_date: {
   de: "Auftragsdatum",
   ru: "Дата заказа",
   ua: "Дата замовлення",
 },
 departure_city: {
   de: "Abfahrtsstadt",
   ru: "Город отправления",
   ua: "Місто відправлення",
 },
 departure_date: {
   de: "Abfahrtdatum",
   ru: "Дата выезда",
   ua: "Дата виїзду",
 },
 departure_time: {
   de: "Zeit",
   ru: "Время",
   ua: "Час",
 },
 return_date: {
   de: "Rückfahrtdatum",
   ru: "Дата выезда обратно",
   ua: "Дата виїзду назад",
 },
 arrival_city: {
   de: "Ankunftsstadt",
   ru: "Город прибытия",
   ua: "Місто прибуття",
 },
 status: {
   de: "Status",
   ru: "Статус",
   ua: "Статус",
 },
 invoice: {
   de: "Rechnung",
   ru: "Счет",
   ua: "Рахунок",
 },
 ticket: {
   de: "Ticket",
   ru: "Билет",
   ua: "Квиток",
 },
 payment: {
   de: "Zahlung",
   ru: "Оплата",
   ua: "Оплата",
 },
 agency_name: {
   de: "Agentur",
   ru: "Агентство",
   ua: "Агентство",
 },
 carrier_name: {
   de: "Firma",
   ru: "Перевозчик",
   ua: "Перевізник",
 },
 group: {
   de: "Gruppe",
   ru: "Группа",
   ua: "Група",
 },


 arrival_time: {
   de: "Zeit",
   ru: "Время",
   ua: "Час",
 },
 departure_days: {
   de: "Abreisetage",
   ru: "Дни выезда",
   ua: "Дні виїзду",
 },
 arrival_days: {
   de: "Anreisetage",
   ru: "Дни прибытия",
   ua: "Дні прибуття",
 },




 route: {
   de: "Route",
   ru: "Маршрут",
   ua: "Маршрут",
 },
 






 title_1: {
   de: "Rabattname",
   ru: "Название скидки",
   ua: "Назва знижки",
 },
 title_2: {
   de: "Rabattart, rabattgröße, währung",
   ru: "Тип скидки, размер скидки, валюта",
   ua: "Тип знижки, розмір знижки, валюта",
 },
 submit: {
   de: "Hinzufügen",
   ru: "Добавить",
   ua: "Додати",
 },

 name: {
   de: "Rabattname",
   ru: "Название скидки",
   ua: "Назва знижки",
 },
 value: {
   de: "Rabattgröße",
   ru: "Размер скидки",
   ua: "Розмір знижки",
 },
 type: {
   de: "Rabattart",
   ru: "Тип скидки",
   ua: "Тип знижки",
 },
 currency: {
   de: "Währung",
   ru: "Валюта",
   ua: "Валюта",
 },
 percent: {
   de: "Prozent %",
   ru: "Процент %",
   ua: "Відсоток %",
 },
 fixed: {
   de: "Fester Rabatt",
   ru: "Фиксированная скидка",
   ua: "Фіксована знижка",
 },
 size: {
   de: "Rabattgröße",
   ru: "Размер скидки",
   ua: "Розмір знижки",
 },
 euro: {
   de: "Euro",
   ru: "Евро",
   ua: "Євро",
 },
}