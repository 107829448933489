import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
// redux
import useAuth from "hooks/useAuth";
import { selectLang } from "redux/lang/langSelectors";
import { resetBookingsStatus } from "redux/bookings/bookingsSlice";
import { createBooking, getBookings } from "redux/bookings/operations";
import { selectBookingsStatus } from "redux/bookings/bookingsSelectors";
// helpers
import { checkIsDateBetweenTwoDates, filterDateBeforeAndAfterCurrentDate, getDateFromDateTime, getDateTime } from "helpers";
// components
import BookingFormOrderer from "./BookingFormOrderer";
import LoaderLocal from "components/Loader/LoaderLocal";
import BookingFormPassenger from "./BookingFormPassenger";
import FormSuccessMsg from "components/Form/FormSuccessMsg";
import InputTypeText from "components/Form/Input/InputTypeText";
import InputTypeRadio from "components/Form/Input/InputTypeRadio";
import SelectTripDate from "components/Form/Select/SelectTripDate";
import BusSeatsSchema from "components/BusSeatsSchema/BusSeatsSchema";
// styled
import { BasicForm, BasicFormBoxTitle, BasicFormDecoreLine, BasicSubmitBtn, } from "components/Form/Form.styled";
import { BookingFormAddPassengerBtn, BookingFormBox } from "./BookingForm.styled";

function setInitDepartureDates(currentTrip, currentPrice) {
  const { prices } = currentTrip ?? {}
  const { ow_price, rt_price, season_ow_price, season_rt_price, start_season_date, end_season_date } = prices ?? {}

  const currentTripDepartureDatesList = currentTrip?.departure?.date_list;
  const currentTripDepartureDatesListFuture = filterDateBeforeAndAfterCurrentDate(currentTripDepartureDatesList)?.futureDates;

  return currentTripDepartureDatesListFuture?.map((i) => {
    const todayIsSeasonDay = checkIsDateBetweenTwoDates(start_season_date, end_season_date, i);
    let price = ow_price;

    if (todayIsSeasonDay && currentPrice?.type === "rt") price = season_rt_price;
    if (todayIsSeasonDay) price = season_ow_price;
    if (currentPrice?.type === "rt") price = rt_price;

    return { date: i, price };
  })
}

const BookingForm = ({ currentPrice, currentTrip, currentTripReverse, handleUpdateSearchState }) => {
  const currentLang = useSelector(selectLang) || "de";
  const bookingsStatus = useSelector(selectBookingsStatus);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [passengersList, setPassengersList] = useState([]);
  const [tripDate, setTripDate] = useState("");
  const [tripReverse, setTripReverse] = useState(false);
  const [tripDateReverse, setTripDateReverse] = useState("");
  
  const [departureDates, setDepartureDates] = useState([]);
  const [departureDatesReverse, setDepartureDatesReverse] = useState("");
  const [busSeatsListReverseDisabled, setBusSeatsListReverseDisabled] = useState(true);
  const [paymentPlace, setPaymentPlace] = useState("");
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const seatsSelection = currentTrip?.seats_selection;
  const seatsSelectionReverse = currentTripReverse?.seats_selection;
  let isSubmitBtnDisabled = true;

  if (Boolean(passengersList?.length)) {
    if (seatsSelection) {
      if (tripReverse) {
        if (busSeatsListReverseDisabled || tripDateReverse?.date === "free") isSubmitBtnDisabled = false;
      } else isSubmitBtnDisabled = false
    } else {
      if (tripReverse) {
        if (tripDateReverse) isSubmitBtnDisabled = false;
      } else isSubmitBtnDisabled = false;
    }
  }
                                                    
  const handleSetTripDate = (tripDateNew) => {
    setTripDate(tripDateNew);
  }
  const handleAddPassenger = () => {
    return addNewPassenger();
  }
  const handleDeletePassenger = (id) => {
    return setPassengersList((prev) =>  prev.filter(i => i?.passenger_id !== id).map((i, idx) => ({...i, passenger_order: idx})) )
  }
  const handleBusSeatsBtnClick = (busSeatData, type) => {
    if (type === "ow") {
      const isSeatNew = !passengersList?.find(i => i?.ow_seat_number === busSeatData?.value);

      if (!isSeatNew) return setPassengersList((prev) => prev.filter(i => i.ow_seat_number !== busSeatData?.value ));
      addNewPassenger(busSeatData, type);
    }

    if (type === "rt") {
      const isSeatNew = !passengersList?.find(i => i?.rt_seat_number === busSeatData?.value);

      if (isSeatNew) {
        const idxNextToChange = passengersList?.find(i => i.rt_seat_number === null);

        return setPassengersList((prev) => prev?.map((i, idx) => {
          if (i?.passenger_id === idxNextToChange?.passenger_id) return {...i, rt_seat_number: busSeatData?.value}
          return i
        }))
      }
      if (!isSeatNew) {
        return setPassengersList((prev) => prev?.map((i) => {
          if (i?.rt_seat_number === busSeatData?.value) return {...i, rt_seat_number: null}
          return i
        }))
      }
    }
  };
  const handleSelectClick = (discountData, id) => {
    setPassengersList((prev) => {
      return prev?.map(i => {
        if (i?.passenger_id === id) return {...i, discount: discountData}
        return i
      })
    })
  }
  const handleSelectSalutationClick = (salutation, id) => {
    setPassengersList((prev) => {
      return prev?.map(i => {
        if (i?.passenger_id === id) return {...i, salutation: salutation}
        return i
      })
    })
  }
  const handleChange = (e) => {
    if (e.target?.name === "first_name" || e.target?.name === "last_name" || e.target?.name === "passport_id" || e.target?.name === "birth_date") {
      setPassengersList((prev) => {
        const currentPassIndex = prev?.findIndex(i => i?.passenger_id === e.target?.id);
        prev[currentPassIndex][e.target?.name] =  e.target?.value;
        return prev
      })
    }
    if (e.target.name === "payment_place") setPaymentPlace(e.target.value);
  }

  const handleInputChange = (e) => {
    setPassengersList((prev) => {
      return prev?.map(i => {
        if (i?.passenger_id === e.target?.id) return {...i, [e.target?.name]: e.target?.value}
        return i
      })
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const finishTripDate = getDateFromDateTime(getDateTime(tripDate?.date, currentTrip?.departure?.time) + currentTrip?.duration?.milliseconds);
    const finishTripDateeReverse = getDateFromDateTime(getDateTime(tripDateReverse?.date, currentTripReverse?.departure?.time) + currentTripReverse?.duration?.milliseconds);

    let newBookingData = {
      type: tripReverse ? "rt" : "ow",
      price: currentPrice?.price,
      passengers_list: passengersList,
      passengers_contact_tel: isLoggedIn ? form.elements.passengers_contact_tel.value?.trim() : form.elements.contact_tel_mobile.value?.trim(),
      payment_place: paymentPlace,

      departure: {
        city: currentTrip?.departure?.city,
        country: currentTrip?.departure?.country,
        address: currentTrip?.departure?.address,
        name: currentTrip?.departure?.name,
        time: currentTrip?.departure?.time,
        date: tripDate?.date,
        point_order: currentTrip?.departure?.point_order,
      },
      arrival: {
        city: currentTrip?.arrival?.city,
        country: currentTrip?.arrival?.country,
        address: currentTrip?.arrival?.address,
        name: currentTrip?.arrival?.name,
        time: currentTrip?.arrival?.time,
        date: finishTripDate,
        point_order: currentTrip?.arrival?.point_order,
      },

      departure_reverse: tripReverse ? {
        city: currentTripReverse?.departure?.city,
        country: currentTripReverse?.departure?.country,
        address: currentTripReverse?.departure?.address,
        name: currentTripReverse?.departure?.name,
        time: currentTripReverse?.departure?.time,
        date: tripDateReverse?.date,
        point_order: currentTripReverse?.departure?.point_order,
      } : null,
      arrival_reverse: tripReverse ? {
        city: currentTripReverse?.arrival?.city,
        country: currentTripReverse?.arrival?.country,
        address: currentTripReverse?.arrival?.address,
        name: currentTripReverse?.arrival?.name,
        time: currentTripReverse?.arrival?.time,
        date: finishTripDateeReverse,
        point_order: currentTripReverse?.arrival?.point_order,
      } : null,

      main_trip_direction: currentTrip?.main_trip_direction,

      consolidator_id: currentTrip?.consolidator_id,
      consolidator_name: currentTrip?.consolidator_name,
      carrier_id: currentTrip?.carrier_id,
      carrier_name: currentTrip?.carrier_name,

      direction_id: currentTrip?._id,
      direction_number_id: currentTrip?.direction_number_id,
      trip_id: currentTrip?.trip_id,
      trip_number_id: currentTrip?.trip_number_id,

      reverse_direction_id: currentTripReverse?._id,
      reverse_direction_number_id: currentTripReverse?.direction_number_id,
      
      reverse_trip_id: currentTrip?.reverse_trip_id,
      reverse_trip_number_id: currentTripReverse?.reverse_trip_number_id,
    }


    if (!isLoggedIn) {
      const userData = {
        salutation: form.elements.salutation.value?.trim(),
        user_last_name: form.elements.user_last_name.value?.trim(),
        user_first_name: form.elements.user_first_name.value?.trim(),
        street: form.elements.street.value?.trim(),
        postal_code: form.elements.postal_code.value?.trim(),
        city: form.elements.city.value?.trim(),
        contact_tel: form.elements.contact_tel.value?.trim(),
        contact_tel_mobile: form.elements.contact_tel_mobile.value?.trim(),
        contact_email: form.elements.contact_email.value?.trim(),
      }

      newBookingData = {...newBookingData, user_data: userData}
      
      return dispatch(createBooking({data: newBookingData, pathname: "/welcome"}))
    }

    dispatch(createBooking({data: newBookingData}))
  };
  const handleFormStatusBtnClick = (type) => {
    dispatch(resetBookingsStatus());

    switch (type) {
      case "new":
        setCurrentStatus(null);
        handleUpdateSearchState("reset");
        break

      case "list":
        setCurrentStatus(null);
        handleUpdateSearchState("reset");

        dispatch(getBookings());
        navigate('/bookings');
        break
    
      default:
        setCurrentStatus(null);
        break 
    }
  }

  function addNewPassenger(busSeatData = null, type = null) {
    const newPassenger = {
      first_name: "",
      last_name: "",
      birth_date: "",
      passport_id: "",
      discount: "",
      ow_seat_number: type === "ow" ? busSeatData?.value : null,
      rt_seat_number: null,

      passenger_order: passengersList?.length,
      passenger_id: nanoid(),
    }
    setPassengersList((prev) => prev.concat(newPassenger))
  }

  useEffect(() => { 
    if (currentTrip && currentPrice ) setDepartureDates(setInitDepartureDates(currentTrip, currentPrice))
  }, [currentTrip, currentPrice]);
  useEffect(() => { 
    if (passengersList) {
      const isReverseSeatsSelect = (Boolean(!passengersList?.filter(i => i?.rt_seat_number === null)?.length))
      setBusSeatsListReverseDisabled(isReverseSeatsSelect);
    }
  }, [passengersList]);
  useEffect(() => { 
    if(currentPrice.type === 'rt') return setTripReverse(true)
    setTripReverse(false)
  }, [currentPrice.type]);
  useEffect(() => {
    if (currentTripReverse && tripDate?.date) {
      const finishTripDate = getDateFromDateTime(getDateTime(tripDate?.date, currentTrip?.departure?.time) + currentTrip?.duration?.milliseconds);
      let departureDatesReverseList = filterDateBeforeAndAfterCurrentDate(currentTripReverse?.departure?.date_list, finishTripDate)?.futureDates?.map((i) => ({ date: i }))
     
      if (finishTripDate === departureDatesReverseList[0]?.date) {
        const isFinishTimeBiggerReverseDepartureTime = currentTrip?.arrival?.time > currentTripReverse?.departure?.time;

        if (isFinishTimeBiggerReverseDepartureTime) departureDatesReverseList = [...departureDatesReverseList]?.slice(1);
      }

      return setDepartureDatesReverse([{date: "free"}].concat(departureDatesReverseList))
    }
  }, [currentTripReverse, tripDate.date, currentTrip.duration.milliseconds, currentTrip.departure.time, currentTrip.arrival.time]);
  
  useEffect(() => { 
    if (tripDateReverse?.date === "free") return setPassengersList((prev) => prev.map(i => ({...i, rt_seat_number: null}))) 
  }, [tripDateReverse]);
  useEffect(() => { 
    if (!tripReverse) {
      setPassengersList((prev) => prev.map(i => ({...i, rt_seat_number: null})));
      setTripDateReverse("");
      return 
    }
  }, [tripReverse]);

  useEffect(() => {
    if (bookingsStatus === "loading") setCurrentStatus("loading");
    if (bookingsStatus === "success") setCurrentStatus("success");
    if (bookingsStatus === "fail") setCurrentStatus("fail");
  }, [bookingsStatus]);

  return (
    <>
      {(!currentStatus || currentStatus === "fail") &&
        <BasicForm onSubmit={handleSubmit} onChange={handleChange} id="bookingForm" className={currentStatus === "success" && "disabled"}>
        <BasicFormDecoreLine style={{ margin: "12px 0" }} />
          <BookingFormBox style={{"--items": 2}}>
            {currentTrip && ( <SelectTripDate currentLang={currentLang} placeholder={text.select_date[currentLang]} selectOptionsData={departureDates} handleSelectClick={handleSetTripDate} /> )}
            {seatsSelection && tripDate &&  <BusSeatsSchema type={"ow"} seatsData={currentTrip?.seats} currentDate={tripDate} handleBusSeatsBtnClick={handleBusSeatsBtnClick} />}
          </BookingFormBox>
          <BookingFormBox style={{"--items": 2}}>
            {tripReverse && currentTripReverse && departureDatesReverse && ( <SelectTripDate currentLang={currentLang} placeholder={text.select_date[currentLang]} selectOptionsData={departureDatesReverse} reserve={true} handleSelectClick={setTripDateReverse} /> )}
            {tripReverse && currentTripReverse && tripDate && seatsSelectionReverse && tripDateReverse && tripDateReverse?.date !== "free"  && <BusSeatsSchema type={"rt"} seatsData={currentTripReverse?.seats} currentDate={tripDateReverse} passengersList={passengersList} right={true} handleBusSeatsBtnClick={handleBusSeatsBtnClick} disabled={busSeatsListReverseDisabled} />}
          </BookingFormBox>
          {!seatsSelection && tripDate &&
            <BookingFormBox style={{"--items": 2}}>
              <BookingFormAddPassengerBtn type="button" onClick={handleAddPassenger}>{text.add_passenger[currentLang]}</BookingFormAddPassengerBtn>
            </BookingFormBox>
          }
  
  
          {isLoggedIn && tripDate && <>
            <BasicFormBoxTitle>{text.payment[currentLang]}</BasicFormBoxTitle> 
            <InputTypeRadio name={"payment_place"} required={true} items={2} hideDot={true} value={"office"} label={text.office[currentLang]} />
            <InputTypeRadio name={"payment_place"} required={true} items={2} hideDot={true} value={"bus"} label={text.bus[currentLang]} />
          </>}

          {passengersList?.length > 0 && <BasicFormDecoreLine style={{ margin: "12px 0" }} />}
          {passengersList?.map((i, idx) => ( <BookingFormPassenger key={idx} passengerData={i} idx={idx} currentLang={currentLang} currentPrice={currentPrice} discounts={currentTrip?.discounts} seatsSelection={seatsSelection} handleSelectClick={handleSelectClick} handleDeletePassenger={handleDeletePassenger} handleInputChange={handleInputChange} handleSelectSalutationClick={handleSelectSalutationClick} /> ))}
          {isLoggedIn && passengersList?.length > 0 && <>
            <BasicFormDecoreLine style={{ margin: "12px 0" }} />
            <BasicFormBoxTitle>{text.passengers_contact_tel[currentLang]}</BasicFormBoxTitle> 
            <InputTypeText required={true} items={3} name={"passengers_contact_tel"} placeholder={text.passengers_contact_tel[currentLang]} /> 
          
          </> }
  
          {!isLoggedIn && Boolean(passengersList?.length) && <>
            <BasicFormDecoreLine style={{ margin: "12px 0" }} />
            <BasicFormBoxTitle>{text.orderer[currentLang]}</BasicFormBoxTitle> 
            <BookingFormOrderer currentLang={currentLang} />
          </>}
  
          <BasicFormDecoreLine style={{ margin: "12px 0" }} />
          <BasicSubmitBtn type="submit" disabled={isSubmitBtnDisabled}> {text.submit[currentLang]} </BasicSubmitBtn>
          {/* <BasicSubmitBtn type="submit" disabled={false}> {text.submit[currentLang]} </BasicSubmitBtn> */}
        </BasicForm>
      }

      { currentStatus === "loading"  && <LoaderLocal />}
      { currentStatus === "success"  && <FormSuccessMsg page_title={text.page_title[currentLang]} success_msg={text.success_msg[currentLang]} go_to_list={text.go_to_list[currentLang]} add_more={text.add_more[currentLang]} handleFormStatusBtnClick={handleFormStatusBtnClick} />}
      { currentStatus === "fail"  && <FormSuccessMsg error_msg={text.error_msg[currentLang]} page_title={text.page_title[currentLang]} success_msg={text.fail_msg[currentLang]} go_to_list={text.go_to_list[currentLang]} add_more={text.add_more_error[currentLang]} handleFormStatusBtnClick={handleFormStatusBtnClick} />}
    </>
  );
};

export default BookingForm;

const text = {
  orderer: {
    de: "Besteller",
    ru: "Заказчик",
    ua: "Замовник",
  },

  add_passenger: {
    de: "Passagier hinzufügen",
    ru: "Добавить пассажира",
    ua: "Додати пасажира",
  },

  salutation: {
    de: "Anrede",
    ru: "Приветствие",
    ua: "Привітання",
  },
  first_name: {
    de: "Vorname",
    ru: "Имя",
    ua: "І'мя",
  },
  last_name: {
    de: "Nachname",
    ru: "Фамилия",
    ua: "Прізвище",
  },

  select_date: {
    de: "Datum auswählen",
    ru: "Выберите дату",
    ua: "Виберіть дату",
  },

  street: {
    de: "Straße",
    ru: "Улица",
    ua: "Вулиця",
  },
  postcode: {
    de: "PLZ",
    ru: "Почтовый индекс",
    ua: "Поштовий індекс",
  },
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  payment: {
    de: "Zahlung",
    ru: "Оплата",
    ua: "Оплата",
  },
  office: {
    de: "Im Büro",
    ru: "В бюро",
    ua: "У бюро",
  },
  bus: {
    de: "Im Bus",
    ru: "В автобусе",
    ua: "В автобусі",
  },


  tel: {
    de: "Telefonnummer",
    ru: "Номер телефона",
    ua: "Номер телефону",
  },
  tel_mobile: {
    de: "Handynummer",
    ru: "Номер мобильного телефона",
    ua: "Номер мобільного телефону",
  },

  passengers_contact_tel: {
    de: "Kontakttelefonnummer der Passagiere",
    ru: "Контактный номер телефона пассажиров",
    ua: "Контактний номер телефону пасажирів",
  },

  pe: {
    de: "Unternehmer",
    ru: "Частный предприниматель",
    ua: "Приватний підприємець",
  },
  tax: {
    de: "Steuernummer",
    ru: "Налоговый номер",
    ua: "Податковий номер",
  },
  owner: {
    de: "Inhaber",
    ru: "Владелец",
    ua: "Власник",
  },

  status: {
    de: "Status",
    ru: "Статус",
    ua: "Статус",
  },
  email: {
    de: "Die E-Mail",
    ru: "Электронная почта",
    ua: "Електронна пошта",
  },
  // firm: {
  //   de: "Firmen",
  //   ru: "Фирма",
  //   ua: "Фірма",
  // },
  and: {
    de: "und",
    ru: "и",
    ua: "і",
  },

  carrier: {
    de: "Transporteur",
    ru: "Перевозчик",
    ua: "Перевізник",
  },
  agent: {
    de: "Agent",
    ru: "Агент",
    ua: "Агент",
  },
  user: {
    de: "Benutzer",
    ru: "Пользователь",
    ua: "Користувач",
  },

  title_email_password: {
    de: "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich bei Ihrem Konto anzumelden",
    ru: "Укажите емейл и пароль для входа в аккаунт",
    ua: "Вкажіть емейл і пароль для входу в акаунт",
  },
  note_email_password: {
    de: "Für die Anmeldung am Konto wird die E-Mail-Adresse verwendet. Das Passwort muss 5-20 Zeichen lang sein, mindestens ein Sonderzeichen und mindestens eine Zahl enthalten.",
    ru: "Email будет использоваться для входа в аккаунт. Пароль должен состоять из 5 -20 символов, содержать хотя бы один специальный символ и хотя бы одну цифру.",
    ua: "Email буде використовуватись для входу в аккаунт. Пароль повинен складатися з 5 -20 символів, містити хоча б один спеціальний символ і хоча б одну цифру.",
  },
  submit: {
    de: "Reservieren",
    ru: "Забронировать",
    ua: "Забронювати",
  },
  add_user_success_text: {
    de: "wurde erfolgreich registriert",
    ru: "успешно зарегистрирован",
    ua: "успішно зареєстрований",
  },
  add_user_error_text: {
    de: "ist bereits registriert. Versuchen Sie es mit einer anderen",
    ru: "уже зарегистрирован, попробуйте другой",
    ua: "вже зареєстрований, спробуйте інший",
  },

  page_title: {
    de: "Reservierung",
    ru: "Бронирование",
    ua: "Бронювання",
  },
  success_msg: {
    de: "erfolgreich hinzugefügt",
    ru: "успешно добавлено",
    ua: "успішно додано",
  },
  fail_msg: {
    de: "nicht hinzugefügt",
    ru: "не добавлено",
    ua: "не додано",
  },
  error_msg: {
    de: "Leider ist ein Fehler aufgetreten!.",
    ru: "К сожалению, произошла ошибка.",
    ua: "На жаль, сталася помилка.",
  },
  go_to_list: {
    de: "Meine Buchungen",
    ru: "Мои бронирование",
    ua: "Мої бронювання",
  },
  // go_to_list: {
  //   de: "Sehen Sie sich meine Buchungen an",
  //   ru: "Посмотреть мои бронирование",
  //   ua: "Переглянути мої бронювання",
  // },
  add_more: {
    de: "Buchen Sie mehr",
    ru: "Забронировать еще",
    ua: "Забронювати ще",
  },
  add_more_error: {
    de: "Versuchen Sie es erneut",
    ru: "Попробовать еще раз",
    ua: "Спробувати ще раз",
  },
};

