import React from 'react';
import { useSelector } from 'react-redux';
// react
import { selectLang } from 'redux/lang/langSelectors';
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
import { text_global } from 'components/common/text';
// components
import { BREAKPOINT_FOR_MOBILE_MENU } from "components/common/constants";
// styled
import {
  PageNavIconUsers,
  PageNavItem,
  PageNavLink,
  PageNavList,
  PageNavTag,
} from "./PageNav.styled";


const CarrierPageNav = ({ setIsBurgerMenuOpen }) => {
  const currentLang = useSelector(selectLang) || "de";
  const { width } = useWindowDimensions();

  const handleClick = () => {
    if (width < BREAKPOINT_FOR_MOBILE_MENU) setIsBurgerMenuOpen(false);
  };

  return (
    <PageNavTag>
      <PageNavList onClick={handleClick}>
        <PageNavItem>
          <PageNavLink to={"/passengers"}> <PageNavIconUsers /> {text_global.passengers[currentLang]} </PageNavLink>
        </PageNavItem>
      </PageNavList>
    </PageNavTag>
  );
};

export default CarrierPageNav;
