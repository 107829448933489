const getFilteredLocations = (arr, key, value, lang) => {
  if (!arr) return "";
  if (!value) return "";

  return arr
    ?.filter((i) => i[key][lang]?.toLowerCase()?.startsWith(value?.toLowerCase()))
    ?.slice(0, 10);
};

export default getFilteredLocations;
