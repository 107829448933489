import React from "react";
import { LogoLink, LogoText } from './Logo.styled';

const Logo = () => {
  return (
    <LogoLink to="/">
      <LogoText>Avtobus</LogoText>
    </LogoLink>
  );
};

export default Logo;

// TODO - logo form IMG to SVG
// TODO - add hover & focus to svg
