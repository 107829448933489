import { maxDevice } from "components/common/breakpoints";
import styled from "styled-components";

export const TodayBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media ${maxDevice.tablet} { display: none; }
  @media ${maxDevice.laptop} { & span { display: none;  }}
`;