import { createSlice } from "@reduxjs/toolkit";
import { createCarrier, getCarriers, updateCarrierById } from "./operations";

const initialState = {};

export const carriersSlise = createSlice({
  name: "carriers",
  initialState,
  reducers: {
    resetCarriersState() {
      return initialState;
    },
    resetCarriersStatus(state) {
      state.status = "";
    },
    resetCarriersError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createCarrier.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createCarrier.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createCarrier.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getCarriers.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getCarriers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getCarriers.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateCarrierById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateCarrierById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.carrier = action.payload;
      })
      .addCase(updateCarrierById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      }),

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetCarriersState, resetCarriersStatus, resetCarriersError } =
  carriersSlise.actions;
export const carriersReducer = carriersSlise.reducer;
