import React, { lazy, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { animateScroll } from "react-scroll";
// redux
import { refreshUser } from "redux/auth/authOperation";
// hooks
import useAuth from "hooks/useAuth";
import useWindowDimensions from "hooks/useWindowDimensions";
import { RestrictedRoute, PrivateRoutes, PermissionRoute, PermissionRoutes } from "hooks/useRoute";
// components
import LoaderLocal from "components/Loader/LoaderLocal";
import SharedLayout from "components/SharedLayout/SharedLayout";
import TableAllPassengers from "components/TablePassengers/TableAllPassengers";
// pages
import { AuthPage, BookingInvoicePage, BookingTicketPage, WelcomePage } from "pages";
// ===== Privat Routes =====
const MainPage = lazy(() => import("pages/MainPage/MainPage"));
// const SettingsPage = lazy(() => import("pages/SettingsPage/SettingsPage"));
const ErrorNotFoundPage = lazy(() => import("pages/ErrorNotFoundPage/ErrorNotFoundPage"));
// ===== ADMIN & Privat Routes =====
const UsersPage = lazy(() => import("pages/UsersPage/UsersPage"));
const CarriersPage = lazy(() => import("pages/CarriersPage/CarriersPage"));
const AgenciesPage = lazy(() => import("pages/AgenciesPage/AgenciesPage"));
const TransportsPage = lazy(() => import("pages/TransportsPage/TransportsPage"));
const DiscountsPage = lazy(() => import("pages/DiscountsPage/DiscountsPage"));
const BaggagePage = lazy(() => import("pages/BaggagePage/BaggagePage"));
const LocationsPage = lazy(() => import("pages/LocationsPage/LocationsPage"));
const RoutesPage = lazy(() => import("pages/RoutesPage/RoutesPage"));
const TripsPage = lazy(() => import("pages/TripsPage/TripsPage"));
const TripsPageClosest = lazy(() => import("pages/TripsPageClosest/TripsPageClosest"));
const TripPage = lazy(() => import("pages/TripPage/TripPage"));
const TripsSearchPage = lazy(() => import("pages/TripsSearchPage/TripsSearchPage"));
const BookingsPage = lazy(() => import("pages/BookingsPage/BookingsPage"));
const PassengersPage = lazy(() => import("pages/PassengersPage/PassengersPage"));
// const BookingInvoicePage = lazy(() => import("pages/BookingInvoicePage/BookingInvoicePage"));
// ===== ADMIN & Privat Routes =====
// TABLE
const TableUsers = lazy(() => import("components/TableUsers/TableUsers"));
const TableUsersEditForm = lazy(() => import("components/TableUsersEditForm/TableUsersEditForm"));
const TableCarriers = lazy(() => import("components/TableCarriers/TableCarriers"));
const TableAgencies = lazy(() => import("components/TableAgencies/TableAgencies"));
const TableAgenciesEditForm = lazy(() => import("components/TableAgenciesEditForm/TableAgenciesEditForm"));
const TableTransports = lazy(() => import("components/TableTransports/TableTransports"));
const TableDiscounts = lazy(() => import("components/TableDiscounts/TableDiscounts"));
const TableBaggage = lazy(() => import("components/TableBaggage/TableBaggage"));
const TableLocations = lazy(() => import("components/TableLocations/TableLocations"));
const TableLocationsEditForm = lazy(() => import("components/TableLocationsEditForm/TableLocationsEditForm"));
const TableRoutes = lazy(() => import("components/TableRoutes/TableRoutes"));
const TableTrips = lazy(() => import("components/TableTrips/TableTrips"));
const TableBookings = lazy(() => import("components/TableBookings/TableBookings"));
const TablePassengers = lazy(() => import("components/TablePassengers/TablePassengers"));
const TableBookingsUser = lazy(() => import("components/TableBookings/TableBookingsUser"));
const TableBookingsEditForm = lazy(() => import("components/TableBookingsEditForm/TableBookingsEditForm"));
const TripSeats = lazy(() => import("components/TripSeats/TripSeats"));
const TripRoute = lazy(() => import("components/TripRoute/TripRoute"));
const TripSchedule = lazy(() => import("components/TripSchedule/TripSchedule"));
const TripText = lazy(() => import("components/TripText/TripText"));
const TripPrices = lazy(() => import("components/TripPrices/TripPrices"));
const TripActivate = lazy(() => import("components/TripActivate/TripActivate"));
const TripDiscounts = lazy(() => import("components/TripDiscounts/TripDiscounts"));
const TripBaggage = lazy(() => import("components/TripBaggage/TripBaggage"));
// FORM
const AddUserForm = lazy(() => import("components/AddUserForm/AddUserForm"));
const AddCarrierForm = lazy(() => import("components/AddCarrierForm/AddCarrierForm"));
const AddAgencyForm = lazy(() => import("components/AddAgencyForm/AddAgencyForm"));
const AddTransportsForm = lazy(() => import("components/AddTransportsForm/AddTransportsForm"));
const AddDiscountsForm = lazy(() => import("components/AddDiscountsForm/AddDiscountsForm"));
const AddBaggageForm = lazy(() => import("components/AddBaggageForm/AddBaggageForm"));
const AddLocationsForm = lazy(() => import("components/AddLocationsForm/AddLocationsForm"));
const AddRouteForm = lazy(() => import("components/AddRouteForm/AddRouteForm"));
const AddTripForm = lazy(() => import("components/AddTripForm/AddTripForm"));

const App = () => {
  const [render, setRender] = useState(true);
  const { isRefreshing, user } = useAuth();
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  // animateScroll.scrollToTop();

  useEffect(() => {
    if (render) return setRender(false);
    // animateScroll.scrollToTop();
    dispatch(refreshUser());
  }, [render, dispatch]);

  useEffect(() => {
    document.getElementById("root").style.minHeight = height + "px";
  }, [height]);

  // [ "superadmin", "consolidator", "chief", "dispatcher", "carrier_manager", "agency_manager", "user"];

  return ( isRefreshing ? <LoaderLocal/> : (
      <Routes>
        <Route path="/" element={<RestrictedRoute component={WelcomePage} redirectTo="/bookings" />} />
        <Route path="login" element={<RestrictedRoute component={AuthPage} redirectTo="/bookings" />} />
        <Route path="/bookings/:bookingId/invoice" element={<BookingInvoicePage />} />
        <Route path="/bookings/:bookingId/ticket" element={<BookingTicketPage />} />

        {/* ===== Privat Routes ===== */}
        <Route element={<PrivateRoutes redirectTo="/login" />}>
          <Route path="/" element={<SharedLayout />}>
            <Route path="/main" element={<MainPage />} />
            {/* <Route path="/settings" element={<SettingsPage />} /> */}

            <Route path="/bookings" element={<PermissionRoute component={BookingsPage} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher", "agency_manager", "user" ]} />} >
              <Route index element={user?.role === "user" ? <TableBookingsUser type={'active'} /> : <TableBookings type={'active'} />} />
              <Route path="archive" element={user?.role === "user" ? <TableBookingsUser type={'archive'} /> : <TableBookings type={'archive'} />} />
              {/* <Route path="archive" element={<TableBookings type={'archive'} />} /> */}
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator", "chief", "dispatcher"]} />}>
                <Route path="users" element={<TableBookings type={'users'} />} />
                <Route path="edit" element={<TableBookingsEditForm />} />
              </Route>
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator", "chief", "dispatcher", "agency_manager"]} />}>
                <Route path="edit-return-date" element={<TableBookingsEditForm extratype={'edit-return-date'} />} />
              </Route>
            </Route>
            <Route path="/passengers" element={<PermissionRoute component={PassengersPage} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher", "carrier_manager"]} />} >
              <Route index element={<TablePassengers type={'active'} />} />
              <Route path="archive" element={<TablePassengers type={'archive'} />} />
              <Route path="all" element={<TableAllPassengers type={'all'} />} />
            </Route>

            <Route path="/trips" element={<PermissionRoute component={TripsPage} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher" ]} />} >
              <Route index element={<TableTrips type={'all'} />} />
              <Route path="directions" element={<TableTrips type={'directions'} />} />
              <Route path="new" element={<PermissionRoute component={AddTripForm} allowedRoles={["superadmin", "consolidator", "chief" ]} />} />
            </Route>
            <Route path="/trips/:tripId" element={<PermissionRoute component={TripPage} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher" ]} />} >
              <Route index element={<TripRoute />} />
              <Route path="schedule" element={<TripSchedule />} />
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator", "chief"]} />}>
                <Route path="seats" element={<TripSeats />} />
                <Route path="prices" element={<TripPrices />} />
                <Route path="activate" element={<TripActivate />} />
                <Route path="discounts" element={<TripDiscounts />} />
                <Route path="baggage" element={<TripBaggage />} />
              </Route>
              <Route path="invoice_ticket_text" element={<TripText />} />
            </Route>
            <Route path="/trips/closest" element={<PermissionRoute component={TripsPageClosest} allowedRoles={["agency_manager"]} />} />
            
            <Route path="/agencies" element={<PermissionRoute component={AgenciesPage} allowedRoles={["superadmin", "consolidator", "chief" ]} />} >
              <Route index element={<TableAgencies type={'status'} />} />
              <Route path="address" element={<TableAgencies type={'address'} />} />
              <Route path="contacts" element={<TableAgencies type={'contacts'} />} />
              <Route path="managers" element={<TableAgencies type={'managers'} />} />
              <Route path="commission" element={<TableAgencies type={'commission'} />} />
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator"]} />}>
                <Route path="edit" element={<TableAgenciesEditForm />} />
              </Route>
              <Route path="new" element={<PermissionRoute component={AddAgencyForm} allowedRoles={["superadmin", "consolidator", "chief" ]} />} />
            </Route>
            <Route path="/carriers" element={<PermissionRoute component={CarriersPage} allowedRoles={["superadmin", "consolidator" ]} />} >
              <Route index element={<TableCarriers type={'contacts'} />} />
              <Route path="managers" element={<TableCarriers type={'managers'} />} />
              <Route path="new" element={<AddCarrierForm/>} />
            </Route>
            <Route path="/transports" element={<PermissionRoute component={TransportsPage} allowedRoles={["superadmin", "consolidator"]} />} >
              <Route index element={<TableTransports type={'all'} />} />
              <Route path="new" element={<PermissionRoute component={AddTransportsForm} allowedRoles={["superadmin", "consolidator"]} />} />
            </Route>
            <Route path="/users" element={<PermissionRoute component={UsersPage} allowedRoles={["superadmin", "consolidator", "chief" ]} />} >
              <Route index element={<TableUsers type={'read'} />} />
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator", "chief"]} />}>
                <Route path="edit" element={<TableUsersEditForm />} />
              </Route>
              <Route path="new" element={<PermissionRoute component={AddUserForm} allowedRoles={["superadmin", "consolidator"]} />} />
            </Route>

            <Route path="/locations" element={<PermissionRoute component={LocationsPage} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher" ]} />} >
              <Route index element={<TableLocations type={'all'} />} />
              <Route element={<PermissionRoutes allowedRoles={["superadmin", "consolidator"]} />}>
                <Route path="distance" element={<TableLocations type={'distance'} />} />
                <Route path="edit" element={<TableLocationsEditForm type={'edit'} />} />
              </Route>
              <Route path="new" element={<PermissionRoute component={AddLocationsForm} allowedRoles={["superadmin", "consolidator", "chief", "dispatcher" ]} />} />
            </Route>
            <Route path="/routes" element={<PermissionRoute component={RoutesPage} allowedRoles={["superadmin", "consolidator", "chief" ]} />} >
              <Route index element={<TableRoutes type={'all'} />} />
              <Route path="new" element={<PermissionRoute component={AddRouteForm} allowedRoles={["superadmin", "consolidator", "chief" ]} />} />
            </Route>
            <Route path="/discounts" element={<PermissionRoute component={DiscountsPage} allowedRoles={["superadmin", "consolidator", "chief" ]} />} >
              <Route index element={<TableDiscounts type={'all'} />} />
              <Route path="new" element={<PermissionRoute component={AddDiscountsForm} allowedRoles={["superadmin", "consolidator", "chief" ]} />} />
            </Route>
            <Route path="/baggage" element={<PermissionRoute component={BaggagePage} allowedRoles={["superadmin", "consolidator", "chief"]} />} >
              <Route index element={<TableBaggage type={'all'} />} />
              <Route path="new" element={<PermissionRoute component={AddBaggageForm} allowedRoles={["superadmin", "consolidator", "chief"]} />} />
            </Route>


            <Route path="/trips/search" element={<PermissionRoute component={TripsSearchPage} allowedRoles={["superadmin", "agency_manager", "user" ]} />} />
            
            <Route path="*" element={ <ErrorNotFoundPage/> } />
          </Route>
        </Route>
      </Routes>
    )
  );
};

export default App;
