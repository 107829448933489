export default function getLocalDateWithDayOfWeek(date, weekdayFormat = "short") {
  if (!date) return "";
  const newDate = new Date(date);

  const options = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  const resultDate = new Intl.DateTimeFormat("uk-UA", options).format(newDate);

  const de = new Intl.DateTimeFormat("de-De", { weekday: weekdayFormat}).format(newDate);
  const ru = new Intl.DateTimeFormat("ru-Ru", { weekday: weekdayFormat}).format(newDate);
  const ua = new Intl.DateTimeFormat("uk-UA", { weekday: weekdayFormat}).format(newDate);


  // const result = { 
  //   de: resultDate + " | " + de.charAt(0).toUpperCase() + de.slice(1),
  //   ru: resultDate + " | " + ru.charAt(0).toUpperCase() + ru.slice(1),
  //   ua: resultDate + " | " + ua.charAt(0).toUpperCase() + ua.slice(1),
  // };
  const result = { 
    de: de.charAt(0).toUpperCase() + de.slice(1) + " | " + resultDate,
    ru: ru.charAt(0).toUpperCase() + ru.slice(1) + " | " + resultDate,
    ua: ua.charAt(0).toUpperCase() + ua.slice(1) + " | " + resultDate,
  };
  return result;
}
