import React from 'react';
// hooks
import useAuth from "hooks/useAuth";

// components
import SuperAdminPageNav from './SuperAdminPageNav';
import ConsolidatorPageNav from './ConsolidatorPageNav';
import CarrierPageNav from "./CarrierPageNav";
import UserPageNav from "./UserPageNav";
// styled
import { PageNavList, PageNavTag, } from "./PageNav.styled";
import ChiefPageNav from './ChiefPageNav';
import DispatcherPageNav from './DispatcherPageNav';
import AgentPageNav from './AgentPageNav';

const PageNav = ({ setIsBurgerMenuOpen }) => {
  const { user } = useAuth();

  // [ "consolidator", "chief", "dispatcher", "carrier_manager", "agency_manager", "user"];
  const isSuperAdmin = user?.role === "superadmin";
  const isConsolidator = user?.role === "consolidator";
  const isСhief = user?.role === "chief";
  const isDispatcher = user?.role === "dispatcher";
  const isCarrier = user?.role === "carrier_manager";
  const isAgent = user?.role === "agency_manager";
  const isUser = user?.role === "user";

  return (
    <PageNavTag>
      <PageNavList>
        {isSuperAdmin && <SuperAdminPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
        {isConsolidator && <ConsolidatorPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
   
        {isСhief && <ChiefPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
        {isDispatcher && <DispatcherPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}

        {isAgent && <AgentPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
        {isCarrier && <CarrierPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
       
        {isUser && <UserPageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />}
      </PageNavList>
    </PageNavTag>
  );
};

export default PageNav;
