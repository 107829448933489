import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// styled
import { BasicLabel, BasicInputTypeText, BasicFormValidaMsg } from "../Form.styled";
import { validateText } from "helpers/AuthValidate";

const InputTypeText = ({ name, placeholder, required, resetState, items = 1, id = "", value ="" }) => {
  const currentLang = useSelector(selectLang) || "de";
  const [render, setRender] = useState(true);
  const [text, setText] = useState(value);
  const [textDirty, setTextDirty] = useState(false);
  const [textError, setTextError] = useState("");
  const [textClass, setTextClass] = useState("");
  const [isTextValid, setIsTextValid] = useState(false);

  const handleText = (e) => {
    setText(e.target.value);
    setTextError("");
    setTextError(validateText(e.target.value));
  };

  const handleReset = () => {
    setText("");
    setTextDirty(false);
    setTextError("");
    setTextClass("");
  };

  useEffect(() => {
    if (textDirty && !textError && text) {
      setIsTextValid(true);  
      setTextClass("success");
    }
    if (textDirty && textError) {
      setIsTextValid(false);
      setTextClass("fail");
    }
  }, [textDirty, textError, text]);

  useEffect(() => {
    if (render) return setRender(false);
    if (resetState) handleReset();
  }, [render, resetState]);

  return (
    <BasicLabel className={textClass} style={{"--items": items}}>
      <BasicInputTypeText
        value={text}
        onBlur={() => setTextDirty(true)}
        onChange={(e) => handleText(e)}
        type="text"
        name={name}
        placeholder={placeholder}
        required={required}
        data-valid={isTextValid}
        id={id}
      />
      {textDirty && textError && ( <BasicFormValidaMsg> {langText[textError][currentLang]} </BasicFormValidaMsg> )}

    </BasicLabel>
  );
};

export default InputTypeText;

const langText = {
  text_error_text_1: {
    de: "Das Feld muss 2-40 Zeichen enthalten",
    ru: "Поле должно содержать 2-40 символов",
    ua: "Поле повинно містити 2-40 символів",
  },
}