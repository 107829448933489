import React from "react";
// styled
import { BasicLabel, BasicInputTypeText } from "../Form.styled";

const InputTypeTextWithOutValid = ({ initValue, name, placeholder, required, items = 1, id = "", handleInputChange}) => {
  return (
    <BasicLabel style={{"--items": items}}>
      <BasicInputTypeText
        type="text"
        name={name}
        placeholder={placeholder}
        required={required}
        id={id}
        value={initValue}
        onChange={(e) => handleInputChange(e, id)}
      />

    </BasicLabel>
  );
};

export default InputTypeTextWithOutValid;