import React from 'react'
import { useDispatch, useSelector } from "react-redux";
// redux
import { logout } from "redux/auth/authOperation";
import { selectLang } from 'redux/lang/langSelectors';
import { resetUsersState } from 'redux/users/usersSlice';
import { resetTripsState } from 'redux/trips/tripsSlice';
import { resetRoutesState } from 'redux/routes/routesSlice';
import { resetBaggageState } from 'redux/baggage/baggageSlice';
import { resetBookingsState } from 'redux/bookings/bookingsSlice';
import { resetAgenciesState } from 'redux/agencies/agenciesSlice';
import { resetCarriersState } from 'redux/carriers/carriersSlice';
import { resetLocationsState } from 'redux/locations/locationsSlice';
import { resetDiscountsState } from 'redux/discounts/discountsSlice';
import { resetTransportsState } from 'redux/transports/transportsSlice';
// styled
import { LogoutBtnIcon, LogoutBtnTag } from "./LogoutBtn.styled";

const LogoutBtn = () => {
  const currentLang = useSelector(selectLang) || "de";
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(resetUsersState());
    dispatch(resetAgenciesState());
    dispatch(resetCarriersState());
    dispatch(resetLocationsState());
    dispatch(resetDiscountsState());
    dispatch(resetBaggageState());
    dispatch(resetRoutesState());
    dispatch(resetTripsState());
    dispatch(resetTransportsState());
    dispatch(resetBookingsState());
    dispatch(logout());
  }

  return (
    <LogoutBtnTag type="button" onClick={handleLogOut}><LogoutBtnIcon />{text.logout[currentLang]}</LogoutBtnTag>
  )
}

export default LogoutBtn

const text = {
  logout: {
    de: "Ausloggen",
    ru: "Выход",
    ua: "Вихід",
  },
}