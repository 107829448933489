import { minDevice } from "components/common/breakpoints";
import styled from "styled-components";

export const BasicSelectTag = styled.div`
  position: relative;

  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  @media ${minDevice.tablet} {
    --items: 2;
  }
  height: 32px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.locations_country { 
    flex: 0 0 160px;
    max-width: 200px;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
  &.locations_city { 
    flex: 0 0 160px;
    max-width: 200px;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
  &.locations_address { 
    flex: 1 0 120px;
    // max-width: 200px;
  }
  &.locations_name { 
    flex: 1 0 120px;
    max-width: 200px;
  }

  &.salutation {
    height: 44px;
  }
`;
export const BasicSelectOptionsList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  gap: 1px;
  width: 100%;
  min-height: 32px;
  max-height: 320px;

  overflow-y: scroll;

  &.active {
    background-color: #fff;
    box-shadow: var(--box-shadow--main);
    z-index: 2;
  }
`;
export const BasicSelectOptionsBtn = styled.button`
  position: relative;

  display: none;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  // height: 32px;
  min-height: 32px;
  padding: 0 16px;

  color: var(--color--gray);
  font-size: 12px;
  font-weight: 500;

  background-color: var(--color--white);
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 100ms linear;

  &.title { display: flex; }
  &.active { display: flex; }
  .active & { 
    display: flex;
    border-top-color: var(--color--bg-light);
    border-bottom-color: var(--color--bg-light);
  }

  &:hover, &.active { 
    color: var(--color--accent);
    border-color: var(--color--accent);
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  .salutation & {
    height: 44px;
  }
`;