import React from "react";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// components
import TableRowTripsSearch from "./TableRowTripsSearch";
// styled
import { TableTag, TableWrap } from "components/Table/Table.styled";

const TableTripsSearch = ({tripsList, currentPrice, handleBookingBtnClick, handleUpdateSearchState}) => {
  const currentLang = useSelector(selectLang) || "de";
  const renderTable = Boolean(tripsList && tripsList?.length > 0);

  return (
    renderTable && (
      <TableWrap>
        <TableTag>
          <thead>
            <tr>
              <th scope="col" className="id"></th>
              <th scope="col" className="departure_city">{text.departure_city[currentLang]}</th>
              <th scope="col" className="departure_days">{text.departure_days[currentLang]}</th>
              <th scope="col" className="departure_time">{text.departure_time[currentLang]}</th>
              <th scope="col" className="arrow"></th>
              <th scope="col" className="arrival_time">{text.arrival_time[currentLang]}</th>
              <th scope="col" className="arrival_days">{text.arrival_days[currentLang]}</th>
              <th scope="col" className="arrival_city">{text.arrival_city[currentLang]}</th>
              <th scope="col" className="ow_btn">one way ticket</th>
              <th scope="col" className="rt_btn">round trip ticket</th>
              
              <th scope="col" className="carrier_name">{text.carrier_name[currentLang]}</th>
            </tr>
          </thead>
          <tbody>
            {renderTable && tripsList?.map(i => <TableRowTripsSearch key={i._id} tableRowData={i} currentPrice={currentPrice} currentLang={currentLang} handleBookingBtnClick={handleBookingBtnClick}/>) }
          </tbody>
        </TableTag>
      </TableWrap>
    )
  );
};

export default TableTripsSearch

const text = {
  route: {
    de: "Route",
    ru: "Маршрут",
    ua: "Маршрут",
  },
  status: {
    de: "Status",
    ru: "Статус",
    ua: "Статус",
  },
  carrier_name: {
    de: "Firmenname",
    ru: "Название компании",
    ua: "Назва компанії",
  },
  group: {
    de: "Gruppe",
    ru: "Группа",
    ua: "Група",
  },
  departure_city: {
    de: "Abfahrtsstadt",
    ru: "Город отправления",
    ua: "Місто відправлення",
  },
  arrival_city: {
    de: "Ankunftsstadt",
    ru: "Город прибытия",
    ua: "Місто прибуття",
  },
  departure_time: {
    de: "Zeit",
    ru: "Время",
    ua: "Час",
  },
  arrival_time: {
    de: "Zeit",
    ru: "Время",
    ua: "Час",
  },
  departure_days: {
    de: "Abreisetage",
    ru: "Дни выезда",
    ua: "Дні виїзду",
  },
  arrival_days: {
    de: "Anreisetage",
    ru: "Дни прибытия",
    ua: "Дні прибуття",
  },





  title_1: {
    de: "Rabattname",
    ru: "Название скидки",
    ua: "Назва знижки",
  },
  title_2: {
    de: "Rabattart, rabattgröße, währung",
    ru: "Тип скидки, размер скидки, валюта",
    ua: "Тип знижки, розмір знижки, валюта",
  },
  submit: {
    de: "Hinzufügen",
    ru: "Добавить",
    ua: "Додати",
  },

  name: {
    de: "Rabattname",
    ru: "Название скидки",
    ua: "Назва знижки",
  },
  value: {
    de: "Rabattgröße",
    ru: "Размер скидки",
    ua: "Розмір знижки",
  },
  type: {
    de: "Rabattart",
    ru: "Тип скидки",
    ua: "Тип знижки",
  },
  currency: {
    de: "Währung",
    ru: "Валюта",
    ua: "Валюта",
  },
  percent: {
    de: "Prozent %",
    ru: "Процент %",
    ua: "Відсоток %",
  },
  fixed: {
    de: "Fester Rabatt",
    ru: "Фиксированная скидка",
    ua: "Фіксована знижка",
  },
  size: {
    de: "Rabattgröße",
    ru: "Размер скидки",
    ua: "Розмір знижки",
  },
  euro: {
    de: "Euro",
    ru: "Евро",
    ua: "Євро",
  },
}