import { createSlice } from "@reduxjs/toolkit";
import { addLocationDistance, createLocation, getAddressesByCity, getCities, getCitiesByCountry, getCountries, getLocationDistance, getLocationDistancebyUser, getLocations } from "./operations";
import { updateLocationById } from "./operations/updateLocationById";

const initialState = {};

export const locationsSlise = createSlice({
  name: "locations",
  initialState,
  reducers: {
    resetLocationsState() { return initialState; },
    resetLocationsStatus(state) { state.status = ""; },
    resetLocationsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      // createLocation
      .addCase(createLocation.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })
      // getLocations
      .addCase(getLocations.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getCountries.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getCities.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.cities = action.payload;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getCitiesByCountry.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getCitiesByCountry.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.cities = action.payload;
      })
      .addCase(getCitiesByCountry.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getAddressesByCity.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getAddressesByCity.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.addresses = action.payload;
      })
      .addCase(getAddressesByCity.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(addLocationDistance.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(addLocationDistance.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.distance = [...state.distance]?.map(i => {
          const currentCity = action?.payload?.find(item => item?.city.de === i?.city.de)
          if (currentCity) return {...i, ...currentCity}
          return i
        })
      })
      .addCase(addLocationDistance.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })
      .addCase(getLocationDistance.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getLocationDistance.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.distance = action.payload;
      })
      .addCase(getLocationDistance.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getLocationDistancebyUser.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getLocationDistancebyUser.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.distance = action.payload;
      })
      .addCase(getLocationDistancebyUser.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateLocationById.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateLocationById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.location = action.payload;
      })
      .addCase(updateLocationById.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetLocationsState, resetLocationsStatus, resetLocationsError } = locationsSlise.actions;
export const locationsReducer = locationsSlise.reducer;
