// global
import styled from "styled-components";
import { NavLink } from "react-router-dom";
// local
import { minDevice } from "components/common/breakpoints";

export const BurgerMenuBtnTag = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;

  color: var(--color--dark);

  background-color: transparent;
  border: none;
  cursor: pointer;
`;
export const BurgerMenuBtnLine = styled.span`
  position: absolute;

  width: 24px;
  height: 2px;

  border-radius: 4px;
  background-color: var(--color--white, #fff);
  transition-property: height, opacity, transform;
  transition-duration: 0.4s;

  &:first-child {
    transform: translateY(8px);
  }
  &:last-child {
    transform: translateY(-8px);
  }
  .mobileMenuIsOpen &:first-child {
    transform: rotate(-45deg);
  }
  .mobileMenuIsOpen &:nth-child(2n) {
    opacity: 0;
  }
  .mobileMenuIsOpen &:last-child {
    transform: rotate(45deg);
  }
`;

export const BurgerMenuTag = styled.div`
  position: fixed;
  top: 48px;
  left: 100%;
  width: 100%;
  height: 100%;

  z-index: 1;
  transition: 300ms linear;
  background-color: var(--color--bg-light);

  &.mobileMenuIsOpen {left: 0;}
`;
export const BurgerMenuNav = styled.nav`
  padding: 16px 0;

  @media ${minDevice.tablet} { padding: 32px 0; }
`;
export const BurgerMenuNavList = styled.ul`
  display: flex;
  flex-direction: column;
`;
export const BurgerMenuNavItem = styled.li`
  display: flex;
  align-items: center;
  height: 32px;
`;
export const BurgerMenuNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 32px;
`;
export const BurgerMenuBtn = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
`;