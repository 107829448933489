function filterFutureAndPastDates(datesList) {
  if (!datesList) return;
  const today = new Date().toJSON()?.slice(0, 10);
  
  const pastDates = datesList.filter(date => date < today);
  const futureDates = datesList.filter(date => date >= today);

  return {pastDates, futureDates}
}

export default filterFutureAndPastDates;