import React from 'react'
import { useSelector } from 'react-redux';
// redux
import { selectLang } from 'redux/lang/langSelectors';
// styled
import { TodayBox } from './Today.styled'
import { getLocalDateWithDayOfWeek } from 'helpers';

const Today = () => {
  const currentLang = useSelector(selectLang) || "de";

  return (
    <TodayBox><span>{text.today[currentLang]}:</span> {getLocalDateWithDayOfWeek(new Date(), "long")[currentLang]}</TodayBox>
  )
}

export default Today

const text = {
  today: {
    de: "Heute",
    ru: "Сегодня",
    ua: "Сьогодні",
  }
}