import React from "react";
import { BurgerMenuBtnLine, BurgerMenuBtnTag } from "./BurgerMenu.styled";

const BurgerMenuBtn = ({ isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  return (
    <BurgerMenuBtnTag
      className={isBurgerMenuOpen ? "mobileMenuIsOpen" : ""}
      onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
    >
      <BurgerMenuBtnLine />
      <BurgerMenuBtnLine />
      <BurgerMenuBtnLine />
    </BurgerMenuBtnTag>
  );
};

export default BurgerMenuBtn;
