import React, { useState } from "react";
// helpers
import { getFilteredLocations } from "helpers";
// styled
import { InputLocationList, InputLocationItem, InputLocationItemBtn, InputLocationLangTitle } from "./InputLocation.styled";
import { BasicLabel, BasicInputTypeText } from "../../Form.styled";

const InputLocationCity2 = ({ dataList, items = 1, name, placeholder, disabled = false, required = true, inputLang, value, setValue, setValueDe, setValueRu, setValueUa }) => {
  const [filteredList, setFilteredList] = useState(null);

  const handleInput = (e) => {
    const filteredDataList = getFilteredLocations(dataList, "city", e.target.value, inputLang);

    setValue(e.target.value);
    setFilteredList(filteredDataList);
  };

  const onInputLocationItemBtnClick = (i) => {
    setValue(i?.city[inputLang]);
    setValueDe(i?.city?.de);
    setValueRu(i?.city?.ru);
    setValueUa(i?.city?.ua);

    setFilteredList("");
  };

  return (
    <>
      <BasicLabel className={disabled && "disabled"} style={{ "--items": items }}>
        <BasicInputTypeText
          value={value}
          onInput={handleInput}
          type="text"
          name={name}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
        />

        <InputLocationLangTitle>{inputLang}</InputLocationLangTitle>

        {filteredList && (
          <InputLocationList>
            {filteredList.map((i, idx) => (
              <InputLocationItem key={idx}>
                <InputLocationItemBtn type="button" onClick={(e) => onInputLocationItemBtnClick(i)}>{i.city[inputLang]} <span>{i.country[inputLang]}</span></InputLocationItemBtn>
              </InputLocationItem>
            ))}
          </InputLocationList>
        )}
      </BasicLabel>
    </>
  );
};

export default InputLocationCity2;

