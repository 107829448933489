function getDatesBetweenDates(start, end) {
  if(!start || !end) return []

  for (
    var arr = [], dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }

  return arr;
}

export default getDatesBetweenDates;

// ===== original =====
// var getDaysArray = function(start, end) {
//   for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
//       arr.push(new Date(dt));
//   }
//   return arr;
// };
// ===== short version =====
// var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=new Date(e);d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
// ===== original =====
// daylist.map((v) => v.toISOString().slice(0, 10)).join("");
// const testDay = new Date("2023-12-05").getDay();
