import { jwtDecode } from "jwt-decode";

export default function getCredentials(token, key) {
  if (!token) return {};

  const decoded = jwtDecode(token);
  const { login, password } = decoded[key];

  return { login, password };
}
