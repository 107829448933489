import styled from "styled-components";

export const LangSwitcherBtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
export const LangSwitcherBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 36px;

  color: var(--color--bg-light, #eff1f4);
  font-size: 16px;
  text-transform: uppercase;
  
  background-color: var(--color--accent);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &.current {
    color: var(--color--accent, #63060a);
    background-color: var(--color--bg-light, #eff1f4);
  }
  &:hover {
    box-shadow: var(--box-shadow--main);
    border-color: #fff;
  }
  
  &:disabled {
    cursor: auto;
    pointer-events: none;
  }
`;