import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getBookingById = createAsyncThunk(
  "bookings/getBookingById",
  async (bookingId, thunkAPI) => {
    try {
      const res = await axios.get(`/bookings/${bookingId}`);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
