import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// styled
import {
  BasicFormValidaMsg,
  BasicInputTypeText,
  BasicLabel,
} from "../Form.styled";

const InputTypeEmail = ({required, resetState, handleGetIsValid, name = "email", items = 1, value = ""}) => {
  const currentLang = useSelector(selectLang) || "de";
  const [render, setRender] = useState(true);
  const [email, setEmail] = useState(value);
  const [emailDirty, setEmailDirty] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [emailClass, setEmailClass] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(String(e.target.value).toLowerCase())) setEmailError( "Email can contain only latin letters, numbers, '@' and '.'" );
    else setEmailError("");
  };

  const handleReset = () => {
    setEmail("");
    setEmailDirty(false);
    setEmailError("");
    setEmailClass("");
  };

  useEffect(() => {
    if (emailDirty && !emailError && email) {
      setIsEmailValid(true);
      handleGetIsValid(true, name);
      setEmailClass("success");
    }
    if (emailDirty && emailError) {
      setIsEmailValid(false);
      handleGetIsValid(false, name);
      setEmailClass("fail");
    }
  }, [emailDirty, emailError, email, handleGetIsValid, name]);

  useEffect(() => {
    if (render) return setRender(false);
    if (resetState) handleReset();
  }, [render, resetState]);

  return (
    <BasicLabel className={emailClass} style={{"--items": items}}>
      <BasicInputTypeText
        value={email}
        onBlur={() => setEmailDirty(true)}
        onChange={(e) => handleEmail(e)}
        type="email"
        name={name}
        placeholder="Email"
        autoComplete="off"
        required={required}
        data-valid={isEmailValid}
      />
      {emailDirty && emailError && ( <BasicFormValidaMsg> {text.email_error_text[currentLang]} </BasicFormValidaMsg> )}
    </BasicLabel>
  );
};

export default InputTypeEmail;

const text = {
  email_error_text: {
    de: "E-Mails dürfen nur lateinische Buchstaben, Zahlen, „@“ und „.“ enthalten.",
    ru: "Электронная почта могут содержать только латинские буквы, цифры, «@» и «.»",
    ua: "Електронна пошта може містити лише латинські літери, цифри, «@» і «.»",
  },
};
