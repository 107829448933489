export default function checkIsDateBetweenTwoDates(startDate, endDate, currentDate) {
  if (!startDate || !endDate || !currentDate) return null

  const today = new Date(currentDate);
  const startSeasonDate = new Date(startDate);
  const endSeasonDate = new Date(endDate);

  const todayIsSeasonStarted = startSeasonDate <= today;
  const todayIsSeasonEnded = today <= endSeasonDate;
  const todayIsSeasonDay = todayIsSeasonStarted && todayIsSeasonEnded;
  
  return todayIsSeasonDay
}
