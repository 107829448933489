import React from "react";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
// hooks
import useAuth from "hooks/useAuth";
// styled
import { WelcomeBoxLink, WelcomeBoxText, WelcomeBoxWrap } from "./WelcomeBox.styled";

const WelcomeBox = () => {
  const currentLang = useSelector(selectLang) || "de";

  const { isLoggedIn, user } = useAuth();
  const userName = user?.name;
  const userRole = user?.role;

  return (
    <WelcomeBoxWrap>
      {isLoggedIn 
        ? <WelcomeBoxText>{text?.hello[currentLang]} {userName}! [{userRole}] </WelcomeBoxText>
        : <WelcomeBoxLink to={"/login"}>
          {text?.sign_in_1[currentLang]} {" "} <span>{text?.sign_in_2[currentLang]}</span>
        </WelcomeBoxLink>
      }
    </WelcomeBoxWrap>
  )
};

export default WelcomeBox;

const text = {
  sign_in: {
    de: "Melden Sie sich beim Konto an",
    ru: "Войти в аккаунт",
    ua: "Увійти в аккаунт",
  },
  sign_in_1: {
    de: "Einloggen",
    ru: "Войти",
    ua: "Увійти",
  },
  sign_in_2: {
    de: "auf das Konto",
    ru: "в аккаунт",
    ua: "в аккаунт",
  },
  hello: {
    de: "Hallo",
    ru: "Привет",
    ua: "Привіт",
  },
};

