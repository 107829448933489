export const selectUser = state => state.auth.user;
export const selectToken = state => state.auth.token;
export const selectIsLoggedIn = state => state.auth.isLoggedIn;
export const selectIsLoggedOut = state => state.auth.isLoggedOut;
export const selectIsRefreshing = state => state.auth.isRefreshing;
export const selectAuthError = state => state.auth.error;

// === ===
// export const selectAuthSelectors = {
//   user: selectUser,
//   isLoggedIn: selectIsLoggedIn,
//   isRefreshing: selectIsRefreshing,
// };
