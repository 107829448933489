import { dayOfTheWeek } from "helpers/constants";

export default function getDayOfWeekByIndex(index, currentLang) {
  // const dayOfWeekNames = ["НД", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
  // const DayOfWeekName = dayOfWeekNames[index];
  // const dayOfWeekNames = ["НД", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
  const DayOfWeekName = dayOfTheWeek[index][currentLang];
  return DayOfWeekName
}

