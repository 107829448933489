import React from "react";
// styled
import { BasicLabel, BasicInputTypeRadio, BasicInputTypeRadioText } from "../Form.styled";

  const InputTypeRadio = ({ name, value, label, required, items, hideDot }) => {

  return (
    <BasicLabel style={{"--items": items}}>
      <BasicInputTypeRadio
        className={hideDot && 'hideDot'}
        type="radio"
        name={name}
        value={value}
        required={required}
      />
      <BasicInputTypeRadioText>{label}</BasicInputTypeRadioText>
    </BasicLabel>
  );
};

export default InputTypeRadio;
