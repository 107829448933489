import styled from "styled-components";
import { breakpoint, minDevice } from "components/common/breakpoints";

import { ReactComponent as UserIcon } from "./icons/user.svg";
import { ReactComponent as MailIcon } from "./icons/mail.svg";
import { ReactComponent as LockIcon } from "./icons/lock.svg";
import { ReactComponent as EyeIcon } from "./icons/eye.svg";
import { ReactComponent as EyeBlockedIcon } from "./icons/eye-blocked.svg";
// import { ReactComponent as EyeIcon } from './icons/eye-solid.svg';
// import { ReactComponent as EyeBlockedIcon } from './icons/eye-slash-solid.svg';
// import { ReactComponent as RedIcon } from './icons/red.svg';
// import { ReactComponent as YellowIcon } from './icons/yellow.svg';
// import { ReactComponent as GreenIcon } from './icons/green.svg';
import { Link, NavLink } from "react-router-dom";

export const AuthFormTag = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 32px;
  margin: 0 auto;

  color: var(--color--dark, #1e1e1e);

  background: var(--color--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow--main);

  @media ${minDevice.tablet} {
    width: ${breakpoint.mobile}px;
    padding: 32px;
  }
  @media ${minDevice.desktop} {
    padding: 44px 50px;
  }
`;
export const AuthFormTitle = styled.h1`
  // margin-bottom: 20px;

  font-weight: 700;
  font-size: 28px;

  // @media ${minDevice.tablet} { margin-bottom: 40px; }
`;

export const AuthLabel = styled.label`
  position: relative;
  flex-grow: 1;
`;
export const AuthInput = styled.input`
  width: 100%;
  height: 44px;
  padding: 12px;

  color: var(--color--dark, #1e1e1e);
  font-size: 14px;
  line-height: 1;

  background-color: var(--color--bg-light);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;

  &:hover {
    box-shadow: var(--box-shadow--main);
  }

  .disabled & {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }
`;
export const AuthFormBtnSubmit = styled.button`
  height: 48px;
  // margin-top: 12px;

  color: #fff;
  font-weight: 700;
  font-size: 20px;

  border: 0px;
  border-radius: var(--border-radius);
  background-color: var(--color--accent);
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.9;
    box-shadow: var(--box-shadow--main);
  }

`;




export const AuthFormLabel = styled.label`
  position: relative;
  margin-bottom: 12px;

  // @media ${minDevice.tablet} { margin-bottom: 24px; }

  &:hover,
  &:focus {
    color: blue;
  }

  &.red {
    svg {
      color: #e74a3b;
    }
    input {
      border-color: #e74a3b;
      opacity: 1;
    }
    p {
      color: #e74a3b;
    }
  }

  &.yellow {
    svg {
      color: #f6c23e;
    }
    input {
      border-color: #f6c23e;
      opacity: 1;
    }
    p {
      color: #f6c23e;
    }
  }

  &.green {
    svg {
      color: #3cbc81;
    }
    input {
      border-color: #3cbc81;
      opacity: 1;
    }
    p {
      color: #3cbc81;
    }
  }
`;

export const AuthFormIconUser = styled(UserIcon)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;

  color: var(--color--gray, #a6acb1);
  opacity: 0.8;
  transform: translateY(-50%);
`;
export const AuthFormIconMail = styled(MailIcon)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;

  color: var(--color--gray, #a6acb1);
  opacity: 0.8;
  transform: translateY(-50%);
`;
export const AuthFormIconLock = styled(LockIcon)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: 18px;
  height: 18px;

  color: var(--color--gray, #a6acb1);
  opacity: 0.8;
  transform: translateY(-50%);
`;
export const AuthFormEyeBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  border: none;
  background-color: transparent;
`;
export const AuthFormEyeIcon = styled(EyeIcon)`
  // position: absolute;
  // top: 50%;
  // right: 12px;
  // transform: translateY(-50%);
  width: 18px;
  height: 18px;

  fill: var(--color--dark, #1e1e1e);
  opacity: 0.8;
`;
export const AuthFormEyeBlockedIcon = styled(EyeBlockedIcon)`
  // position: absolute;
  // top: 50%;
  // right: 12px;
  // transform: translateY(-50%);

  width: 18px;
  height: 18px;

  fill: var(--color--dark, #1e1e1e);
  opacity: 0.8;
`;

export const AuthFormInput = styled.input`
  width: 100%;
  padding: 12px 12px 12px 40px;

  color: var(--color--dark, #1e1e1e);
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.02em;

  border: 1px solid;
  border-radius: var(--border-radius);
  border-color: var(--color--gray, #1e1e1e);
  opacity: 0.3;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media ${minDevice.tablet} {
    padding: 16px 18px 16px 50px;

    font-size: 18px;
    line-height: 27px;
  }
`;
export const AuthFormValidaMsg = styled.p`
  position: absolute;
  top: calc(100% + 4px);

  left: 0px;

  color: #e74a3b;
  font-size: 11px;
  line-height: 1;

  @media ${minDevice.tablet} {
    // bottom: -21px;

    font-size: 14px;
    line-height: 21px;
  }
`;
export const AuthFormPassValidaMsg = styled.p`
  position: absolute;
  bottom: -12px;

  left: 0px;

  color: #e74a3b;
  font-size: 10px;
  line-height: 1;
  text-align: center;

  @media ${minDevice.tablet} {
    bottom: -21px;

    font-size: 14px;
    line-height: 21px;
  }
`;
export const AuthFormPassValidaMsg2 = styled.p`
  position: absolute;
  bottom: -24px;

  left: 0px;

  color: #e74a3b;
  font-size: 10px;
  line-height: 1;
  text-align: center;

  @media ${minDevice.tablet} {
    bottom: -42px;

    font-size: 14px;
    line-height: 21px;
  }
`;


export const AuthPageNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  color: var(--color--gray, #a6acb1);
  font-weight: 700;
  font-size: 16px;

  background-color: var(--color--bg-light, #eff1f4);
  border-radius: var(--border-radius);
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.9;
    color: var(--color--dark);
  }
`;

export const AuthFormRadioTitle = styled.p``;
export const AuthFormRadioList = styled.ul`
  display: flex;
  align-items: center;
  --gap: 16px;
  gap: var(--gap);
`;
export const AuthFormRadioItem = styled.li`
  flex: 0 0 calc((100% - var(--gap)) / 2);
  gap: var(--gap);
`;
export const AuthFormRadioLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;

  color: var(--color--gray, #a6acb1);
  font-weight: 700;
  font-size: 16px;

  background-color: var(--color--bg-light, #eff1f4);
  border-radius: var(--border-radius);
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: 0.9;
    color: var(--color--dark);
  }
  :checked ~ & {
    color: var(--color--white, #fff);
    background-color: var(--color--accent, #14a0fd);
  }
`;
export const AuthFormRadioInput = styled.input`
  display: none;
`;

export const AuthFormcheckboxWrap = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;
export const AuthFormcheckboxInput = styled.input`
  display: none;
`;
export const AuthFormcheckboxLabel = styled.label`
  position: relative;

  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;

  color: var(--color--gray);

  border-radius: 4px;
  border: 1px solid var(--color--gray);
  background-color: #fff;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    font-size: 22px;
    font-weight: 500;
    color: currentColor;
    transition: 200ms linear;
    // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  input:disabled ~ & {
    background-color: var(--color--bg-light);
    cursor: not-allowed;
    // border-color: var(--color--accent-lite);
  }
  input:checked ~ & {
    color: var(--color--white);
    border-color: var(--color--accent);
    background-color: var(--color--accent);
    background-image: url("${process.env.PUBLIC_URL}/icons/check-solid.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;

    // &::before {opacity: 1}
  }
  input:checked:disabled ~ & {
    color: var(--color--accent);
    background-color: var(--color--accent-lite);
    border-color: var(--color--accent-lite);
  }
`;
export const AuthFormcheckboxText = styled.p``;
export const AuthFormcheckboxLink = styled(Link)`
  margin-left: 8px;
`;
