import { createSlice } from "@reduxjs/toolkit";
import { createAgency, getAgencies, updateAgencies, updateAgencyById, } from "./operations";

const initialState = {};

export const agenciesSlise = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    resetAgenciesState() {
      return initialState;
    },
    resetAgenciesStatus(state) {
      state.status = "";
    },
    resetAgenciesError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createAgency.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createAgency.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createAgency.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getAgencies.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getAgencies.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getAgencies.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(updateAgencies.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateAgencies.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.list = [...state.list].map((i) => {
          if (action.payload?.find((item) => item?._id === i?._id))
            return action.payload?.find((item) => item?._id === i?._id);

          return i;
        });
      })
      .addCase(updateAgencies.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(updateAgencyById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateAgencyById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.agency = action.payload;
      })
      .addCase(updateAgencyById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      }),

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetAgenciesState, resetAgenciesStatus, resetAgenciesError } =
  agenciesSlise.actions;
export const agenciesReducer = agenciesSlise.reducer;
