import styled from "styled-components";

export const BusSeatsList = styled.div`
  flex: 0 0 412px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  min-height: 156px;
  max-height: 156px;
  max-width: 412px;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
export const BusSeatsItem = styled.div`
  flex: 0 0 28px;

  &.emtpy_long {  
    flex: 1 0 380px;
  }
`;
export const BusSeatsBtnTag = styled.button`
  flex: 0 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-height: 28px;
  height: 28px;
  padding: 0;

  color: #000;

  border: none;
  cursor: pointer;

  &.booked {
    color: #fff;
    background-color: var(--color--accent);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.free {
    background-color: var(--color--success);
  }
  &.select {
    color: #fff;
    background-color: var(--color--accent);
  }
`;
