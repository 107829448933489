export function sortStings(arr, direction, key) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key]?.localeCompare(b[key]);
    return b[key]?.localeCompare(a[key]);
  });
}
export function sortStings2keys(arr, direction, key1, key2) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key1][key2]?.localeCompare(b[key1][key2]);
    return b[key1][key2]?.localeCompare(a[key1][key2]);
  });
}
export function sortStings3keys(arr, direction, key1, key2, key3) {
  return [...arr]?.sort((a, b) => {
    if (direction) return a[key1][key2][key3]?.localeCompare(b[key1][key2][key3]);
    return b[key1][key2][key3]?.localeCompare(a[key1][key2][key3]);
  });
}
