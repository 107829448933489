function filterDateBeforeAndAfterCurrentDate(datesList, currentDate) {
  if (!datesList) return;
  let today = new Date(currentDate).toJSON()?.slice(0, 10);

  if (!currentDate) today = new Date().toJSON()?.slice(0, 10);
  
  const pastDates = datesList.filter(date => date < today);
  const futureDates = datesList.filter(date => date >= today);

  return {pastDates, futureDates}
}

export default filterDateBeforeAndAfterCurrentDate;