import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getDirectionsByTripIdAndSearchQuery = createAsyncThunk(
  "trips/getDirectionsByTripIdAndSearchQuery",
  async ({data, pathname = ''}, thunkAPI) => {
    try {
      const res = await axios.post(`/directions/search/trip${pathname}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);

