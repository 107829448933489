function getDayOfWeek(date) {
  if (!date) return "";
  // const dayIndex = new Date(date).getDay();
  const newDate = new Date(date);

  const options = {
    weekday: "short",
    day: "numeric",
    month: "numeric",
  };
  const dayIndex = newDate.getDay();
  const en = new Intl.DateTimeFormat("en-US", options)
    .format(newDate)
    .toUpperCase();
  const ua = new Intl.DateTimeFormat("uk-UA", options)
    .format(newDate)
    .toUpperCase();
  const ru = new Intl.DateTimeFormat("ru-Ru", options)
    .format(newDate)
    .toUpperCase();
  const de = new Intl.DateTimeFormat("de-De", options)
    .format(newDate)
    .toUpperCase();

  const result = { dayIndex, en, ua, ru, de };
  return result;
}

// const weekdayDateMap = {
//   Mon: new Date('2020-01-06T00:00:00.000Z'),
//   Tue: new Date('2020-01-07T00:00:00.000Z'),
//   Wed: new Date('2020-01-08T00:00:00.000Z'),
//   Thu: new Date('2020-01-09T00:00:00.000Z'),
//   Fri: new Date('2020-01-10T00:00:00.000Z'),
//   Sat: new Date('2020-01-11T00:00:00.000Z'),
//   Sun: new Date('2020-01-12T00:00:00.000Z'),
// };

export default getDayOfWeek;
