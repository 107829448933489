import React from "react";
// helpers
import { getLocalDate } from "helpers";

const TableRowAllPassengers = ({ idx, tableRowData, currentLang = "de", }) => {
  const { buch, birth_date, first_name, last_name, price, ow_seat_number, rt_seat_number, main_trip_direction, carrier_name } = tableRowData ?? {}
  const departureCity = main_trip_direction?.departure?.city[currentLang] || "";
  const arrivalCity = main_trip_direction?.arrival?.city[currentLang] || "";
 
  return (
    <tr>
      {/* <td className="id">{idx + 1}</td> */}
      <td className="created_date">{last_name}</td>
      <td className="created_date">{first_name}</td>
      <td className="created_date" style={{justifyContent: "flex-end"}}>{getLocalDate(birth_date)}</td>
      <td className="buch">{price} €</td>
      <td className="buch">{ow_seat_number}</td>
      <td className="buch">{rt_seat_number || "-"}</td>
      <td className="buch">{buch}</td>
      <td className="direction"> {departureCity} - {arrivalCity} </td>
      <td className="carrier_name">{carrier_name}</td>

    </tr>
);
}

export default TableRowAllPassengers