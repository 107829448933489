import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
// local
import { breakpoint, minDevice } from "./breakpoints";

export const Section = styled.section`
  width: 100%;
  padding: 16px 0;

  @media ${minDevice.tablet} {
    padding: 32px 0;
  }
`;
export const SectionSearch = styled.section`
  width: 100%;
  padding: 16px 0;

  background-color: var(--color--accent);

  @media ${minDevice.tablet} {
    padding: 32px 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  // padding: 0 16px;
  // padding: 0 16px 0 40px;
  padding: 0 40px;

  @media ${minDevice.tablet} {
    // padding: 0 32px;
    padding: 0 40px;
  }
  @media ${minDevice.laptop} {
    // padding: 0;
    // max-width: ${breakpoint.laptop - 50 - 40*2 - 150}px;
  }
  @media ${minDevice.desktop} {
   
    // max-width: ${breakpoint.desktop - 50 - 40*2 - 150}px;
  }
  @media ${minDevice.desktopL} {
    padding: 0 60px;
    // max-width: ${breakpoint.desktopL - 50 - 40*2 - 150}px;
  }
  @media ${minDevice.desktopL2} {
    // max-width: ${breakpoint.desktopL2 - 50 - 40*2 - 150}px;
  }
  @media ${minDevice.desktopXL} {
    max-width: ${breakpoint.desktopXL - 50 - 100*2 - 150}px;
  }


  // @media ${minDevice.laptop} {
  //   padding: 0;
  //   max-width: ${breakpoint.laptop - 50 - 32*2 - 150}px;
  // }
  // @media ${minDevice.desktop} {
  //   max-width: ${breakpoint.desktop - 50 - 32*2 - 150}px;
  // }
  // @media ${minDevice.desktopL} {
  //   max-width: ${breakpoint.desktopL - 50 - 32*2 - 150}px;
  // }
  // @media ${minDevice.desktopL2} {
  //   max-width: ${breakpoint.desktopL2 - 50 - 32*2 - 150}px;
  // }
  // @media ${minDevice.desktopXL} {
  //   max-width: ${breakpoint.desktopXL - 50 - 32*2 - 150}px;
  // }

`;

export const Backdroop= styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 300px;
  min-height: 300px;
  padding: 20px 0;

  background-color: var(--color--bg-light, #eff1f4);
  z-index: 3000;
  overflow: scroll;
`

export const TitleWrap = styled.div`
  display: flex;
  align-items: baseline;
`;

export const PageTitle = styled.h1`
  margin-bottom: 20px;

  font-size: 28px;
  font-weight: 600;
  // text-transform: capitalize;
`;
export const TitleH2 = styled.h2`
  // margin-bottom: 16px;

  font-size: 20px;
  font-weight: 500;
`;
export const TitleH3 = styled.h3`
  margin-bottom: 8px;

  font-size: 14px;
  font-weight: 500;
`;
export const BoxWrap = styled.div`
  background-color: #fff;
  border-radius: var(--border-radius, 8px);
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(90, 100, 109, 0.2);
`;
export const Box = styled.div`
  padding: 16px;
`;
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Item = styled.li``;

export const InputText = styled.input`
  width: 100%;
  height: 48px;
  padding: 8px;

  background-color: #fff;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  transition: 200ms linear;

  &:focus {
    border-color: var(--color--accent);
  }
`;
export const InputTextLabel = styled.label``;


export const BreadcrumbsList = styled.ul`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;
export const BreadcrumbsItem = styled.li`
  position: relative;

  font-size: 12px;
  color: var(--color--gray);
  // text-transform: uppercase;

  &:not(:first-of-type):before {
    content: "/";

    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
  }

  .light & { color: var(--color--bg-light); }
`;
export const BreadcrumbsLink = styled(Link)`
  font-size: 12px;
  color: var(--color--accent);
  .light & { color: var(--color--white); }
`;

export const BasicNavLink = styled(NavLink)`
  color: currentColor;
  text-decoration: underline;
`;