import styled from "styled-components";

export const Invoice = styled.div`
  display: flex;
  flex-direction: column;

  width: 210mm;
  min-height: 297mm;
  height: 297mm;
  margin: 0 auto;
  padding: 20px 40px 0px;

  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;

  background-color: #fff;
  overflow: hidden;
  page-break-after: always;
`;
export const InvoiceLogoWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
`;
export const InvoiceLogo = styled.img`
  width: 200px;
  height: 124px;
`;
export const InvoiceLogoText = styled.h1`
  font-size: 52px;
  font-weight: 500;
`;

export const InvoiceHeader= styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const InvoiceAgencyBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const InvoiceBookingBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 220px;

  & p {
    display: flex;
    justify-content: space-between;
    gap: 12px;
  }
`;


export const InvoiceBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InvoiceTable= styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;
export const InvoiceTableRow= styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 20px;
  padding: 4px 0;

  &.title {
    font-weight: 500;
    border-top: 1px solid var(--color--gray);
  }
  &.title, &.row-1 {
    border-bottom: 1px solid var(--color--gray);

    & p:nth-of-type(1) { flex: 0 0 100px; }
    & p:nth-of-type(2) { flex: 0 0 108px; }
    & p:nth-of-type(3) { flex-grow: 1; }
    & p:nth-of-type(4) { flex: 0 0 100px;}
    & p:nth-of-type(5) { flex: 0 0 92px; text-align: end;}
    & p:nth-of-type(6) { flex: 0 0 92px; text-align: end;}
  }
  &.row-2 {
    gap: 32px;
    border: 0;
  }

  &.row-3 {
    margin-top: 20px;
    font-weight: 500;

    & p:nth-of-type(4) { justify-content: center; }
  }
  &.row-3, &.row-4 {
    border-bottom: 1px solid var(--color--gray);

    & p:nth-of-type(1) { flex-grow: 1; }
    & p:nth-of-type(2) { 
      flex: 0 0 80px; 
      margin-right: 12px;
      text-align: end;
    }
    & p:nth-of-type(3) { flex: 0 0 100px; }
    & p:nth-of-type(4) { 
      flex: 0 0 40px;  
      display: flex;
      gap: 12px;
      width: 40px;
      margin-right: 12px;
    }
    & p:nth-of-type(5) { flex: 0 0 120px; }
    & p:nth-of-type(6) { flex: 0 0 80px; text-align: end;}
  }

  &.row-5,  &.row-7 {
    gap: 24px;

    & p:nth-of-type(1) { flex-grow: 1; }
    & p:nth-of-type(2) { flex: 0 0 180px; }
    & p:nth-of-type(3) { flex: 0 0 150px; }
    & p:nth-of-type(4) { flex: 0 0 150px; }
    & p:nth-of-type(5) { flex: 0 0 64px; }
  }

  &.row-5 {
    color: var(--color--confirmed, #3b5ae7);
  }
  &.row-6,  &.row-7 {
    color: var(--color--accent, #63060a);
  }
  &.row-7 {
    border-bottom: 1px solid var(--color--gray);
  }
  &.row-6, &.row-8, &.row-9, &.row-10 {
    gap: 16px;

    & p:nth-of-type(1) { flex: 0 0 168px; text-align: end;}
    & p:nth-of-type(2) { flex: 0 0 60px; gap: 12px; text-align: end;}
    p:nth-last-of-type(1) { flex: 0 0 80px; text-align: end;}
    p:nth-last-of-type(2) { flex: 0 0 120px; text-align: end;}
  }

  &.row-11 {
    position: relative;
    gap: 16px;
    margin-top: 12px;

    p:nth-last-of-type(1) { flex: 0 0 80px; text-align: end;}
    p:nth-last-of-type(2) { text-align: end;}
  }

`;

export const InvoiceMessageBox = styled.div`
  margin-top: 20px;
`
export const InvoiceMessageTitle = styled.h3`
  margin-bottom: 16px;

  font-size: 12px;
  font-weight: 600;
  text-align: center;
`
export const InvoiceMessage = styled.div`
  line-height: 1.2;
`

export const InvoiceFooter = styled.div`
  display: flex;
  justify-content: space-between;
  --gap: 20px;
  gap: var(--gap);
  margin-top: auto;
  padding: 4px 0 20px;

  border-top: 1px solid var(--color--gray);
  page-break-after: always;
`;
export const InvoiceFooterBox = styled.div`
  --items: 3;  
  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));

  display: flex;
  flex-direction: column;
  gap: 4px;

  & p {
    display: flex;
    gap: 12px;
  }

  & span {
    display: block;
    width: 60px;
  }
`;
// export const Invoice = styled.div``;











// body {
//   background: rgb(204,204,204); 
// }
// page[size="A4"] {
//   background: white;
//   width: 21cm;
//   height: 29.7cm;
//   display: block;
//   margin: 0 auto;
//   margin-bottom: 0.5cm;
//   box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
// }
// @media print {
//   body, page[size="A4"] {
//     margin: 0;
//     box-shadow: 0;
//   }
// }