import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateLocationById = createAsyncThunk(
  "locations/updateLocationById",
  async ({id, data}, thunkAPI) => {
    try {
      const res = await axios.post(`/locations/${id}`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
