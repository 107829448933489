import React from "react";
import { LoaderContent, LocalLoaderBackdroop } from "./Loader.styled";

const LoaderLocal = () => {
  return (
    <LocalLoaderBackdroop>
      <LoaderContent />
    </LocalLoaderBackdroop>
  );
};

export default LoaderLocal;
