import React, { useEffect, useState } from "react";
// components
import BusSeatsBtn from "./BusSeatsBtn";
// styled
import { BusSeatsItem, BusSeatsList, } from "./BusSeatsSchema.styled";

const BusSeatsSchema = ({type, seatsData, currentDate, passengersList, handleBusSeatsBtnClick, disabled, right }) => {
  const [busSeatsListToRender, setBusSeatsListToRender] = useState(busSeatsList);

  useEffect(() => { 
    if (seatsData && seatsData.length > 0 && currentDate) {
      const updSeatsData = seatsData?.map(i => {
        if (i?.booked?.includes(currentDate?.date)) return {seat: i?.seat, status: "booked"}
        return {seat: i?.seat, status: "free"}
      })

      const filteredSeatsData = updSeatsData.filter(seat => seat.status === 'booked').map(i => i.seat);

      if (!filteredSeatsData) setBusSeatsListToRender(busSeatsList);

      const newBusSeatsList = busSeatsList?.map(i => {
        if (filteredSeatsData?.includes(i?.value)) return {value: i?.value, status: "booked"}
        return i
      })

      setBusSeatsListToRender(newBusSeatsList)
    }
}, [seatsData, currentDate]);

  return (
    <BusSeatsList style={right && {marginLeft: "auto"}} >
      {busSeatsListToRender?.map((i, idx) => {
        const btnSelected = passengersList?.map(i => i?.rt_seat_number)?.includes(i?.value);
        if (i?.status === ("emtpy_long")) return (<BusSeatsItem key={idx} className={i?.status}></BusSeatsItem>)
        if (i?.status === ("emtpy")) return (<BusSeatsItem key={idx} className={i?.status}></BusSeatsItem>)

        return ( <BusSeatsItem key={idx}><BusSeatsBtn data={i} type={type} btnSelected={btnSelected} disabled={disabled} handleBusSeatsBtnClick={handleBusSeatsBtnClick}/> </BusSeatsItem> );
      })}
    </BusSeatsList>
  );
};

export default BusSeatsSchema;

const busSeatsList = [
  { value: 45, status: "free" },
  { value: 41, status: "free" },
  { value: 37, status: "free" },
  { value: 33, status: "free" },
  { value: 29, status: "free" },
  { value: 27, status: "free" },
  { value: 25, status: "free" },
  { value: 21, status: "free" },
  { value: 17, status: "free" },
  { value: 13, status: "free" },
  { value: 9, status: "free" },
  { value: 5, status: "free" },
  { value: 1, status: "free" },

  { value: 46, status: "free" },
  { value: 42, status: "free" },
  { value: 38, status: "free" },
  { value: 34, status: "free" },
  { value: 30, status: "free" },
  { value: 28, status: "free" },
  { value: 26, status: "free" },
  { value: 22, status: "free" },
  { value: 18, status: "free" },
  { value: 14, status: "free" },
  { value: 10, status: "free" },
  { value: 6, status: "free" },
  { value: 2, status: "free" },

  { value: 49, status: "free" },
  { value: "", status: "emtpy_long" },

  { value: 50, status: "free" },
  { value: 47, status: "free" },
  { value: 43, status: "free" },
  { value: 39, status: "free" },
  { value: 35, status: "free" },
  { value: 31, status: "free" },

  { value: "", status: "emtpy" },

  { value: 23, status: "free" },
  { value: 19, status: "free" },
  { value: 15, status: "free" },
  { value: 11, status: "free" },
  { value: 7, status: "free" },
  { value: 3, status: "free" },

  { value: 51, status: "free" },
  { value: 48, status: "free" },
  { value: 44, status: "free" },
  { value: 40, status: "free" },
  { value: 36, status: "free" },
  { value: 32, status: "free" },

  { value: "", status: "emtpy" },

  { value: 24, status: "free" },
  { value: 20, status: "free" },
  { value: 16, status: "free" },
  { value: 12, status: "free" },
  { value: 8, status: "free" },
  { value: 4, status: "free" },
];