import React, { lazy } from "react";
import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";
import useAuth from "hooks/useAuth";

const ErrorNotFoundPage = lazy(() => import("pages/ErrorNotFoundPage/ErrorNotFoundPage"));
const ErrorPermissionPage = lazy(() => import("pages/ErrorPermissionPage/ErrorPermissionPage"));

  // [ "consolidator", "chief", "dispatcher", "carrier_manager", "agency_manager", "user"];

export const RestrictedRoute = ({ component: Component, redirectTo }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname;

  // TODO
  // const isCarrierManager = user?.role === "carrier_manager";

  return isLoggedIn ? <Navigate to={from || redirectTo} state={{ from: location }} /> : <Component />;
};
export const PrivateRoute = ({ component: Component, redirectTo }) => {
  const { isLoggedIn, isRefreshing } = useAuth();
  const shouldRedirect = !isRefreshing && !isLoggedIn;
  return shouldRedirect ? <Navigate to={redirectTo} /> : <Component />;
};
export const PermissionRoute = ({component: Component,  allowedRoles}) => {
  const { user } = useAuth();

  return user?.permissions?.find((permission) => allowedRoles?.includes(permission)) 
      ? <Component /> 
      : <ErrorPermissionPage />
};

export const PermissionRoutes = ({ allowedRoles }) => {
  const contextValue = useOutletContext();
  const { user } = useAuth();

  return user?.permissions?.find((permission) => allowedRoles?.includes(permission)) 
      ? <Outlet context={contextValue} />
      : <ErrorPermissionPage />
};

export const PrivateRoutes = ({ redirectTo }) => {
  const location = useLocation();
  const { isLoggedIn, isRefreshing } = useAuth();
  const shouldRedirect = !isRefreshing && !isLoggedIn;

  return shouldRedirect ? <Navigate to={redirectTo} replace state={{ from: location }} /> : <Outlet />;
};







// export const RestrictedRoute = ({ component: Component, redirectTo }) => {
//   const { isLoggedIn } = useAuth();
//   return isLoggedIn ? <Navigate to={redirectTo} /> : <Component />;
// };


// export const PermissionRoutes = ({allowedRoles, redirectTo}) => {
//   const { user } = useAuth();

//   return user?.permissions?.find((permission) => allowedRoles?.includes(permission)) 
//       ? <Outlet /> 
//       : <p>У вас немає доступу</p>
// };

export const ErrorRoute = () => {
  const { isLoggedIn } = useAuth();
  const ErroeElem = isLoggedIn ? (
    <ErrorNotFoundPage />
  ) : (
    <Navigate to="/" replace />
  );

  return ErroeElem;
};

// export const RequireAuthRoute = ({ allowedRoles }) => {
//   const { user, isLoggedIn, isRefreshing } = useAuth();
//   const location = useLocation();
//   const shouldRedirect = !isRefreshing && !isLoggedIn;

//   return (user?.permissions?.find(permission => allowedRoles?.includes(permission))
//     ? <Outlet />
//     : shouldRedirect
//       ? <Navigate to="/login" state={{ from: location }} replace/>
//       : <p>У вас немає доступу</p>
//   );
// };
// export const RequireAuthRoute = ({ allowedRoles }) => {
//   const { user, isLoggedIn, isRefreshing } = useAuth();
//   const location = useLocation();
//   const shouldRedirect = !isRefreshing && !isLoggedIn;

//   return (
//     <Suspense fallback={"ТРИВАЄ ПЕРЕВІРКА"}>
//       {user?.permissions?.find((permission) =>
//         allowedRoles?.includes(permission)
//       ) ? (
//         <Outlet />
//       ) : shouldRedirect ? (
//         <Navigate to="/login" state={{ from: location }} replace />
//       ) : (
//         <p>У вас немає доступу</p>
//       )}
//     </Suspense>
//   );
// };

// export const PrivateRouteROLES = ({
//   component: Component,
//   redirectTo,
//   allowedRoles,
// }) => {
//   const { isLoggedIn, isRefreshing, user } = useAuth();
//   const location = useLocation();
//   const shouldRedirect = !isRefreshing && !isLoggedIn;

//   return shouldRedirect ? (
//     <Navigate to={redirectTo} state={{ from: location }} replace />
//   ) : user?.permissions?.find((permission) =>
//       allowedRoles?.includes(permission)
//     ) ? (
//     <Component />
//   ) : (
//     <p>У вас немає доступу</p>
//   );
// };



// export const PrivateComponentROLES = ({ allowedRoles }) => {
//   const { user } = useAuth();

//   return user?.permissions?.find((permission) => allowedRoles?.includes(permission)) 
//     ? <Outlet /> 
//     : ''
// };

// export const RequireAuthRoute = ({ allowedRoles }) => {
//   const { user, isLoggedIn, isRefreshing } = useAuth();
//   const location = useLocation();
//   const shouldRedirect = !isRefreshing && !isLoggedIn;

//   return (user?.permissions?.find(permission => allowedRoles?.includes(permission))
//     ? <Outlet />
//     : shouldRedirect
//       ? <Navigate to="/login" state={{ from: location }} replace/>
//       : <p>У вас немає доступу</p>
//   );
// };