import styled from "styled-components";
// local
import { minDevice } from "components/common/breakpoints";

export const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
`;

export const TableTag = styled.table`
  width: 100%;
  
  font-size: 12px;
  border-collapse: collapse;

  & tr {
    position: relative;
    display: flex;
    // align-items: flex-start;
    min-height: 32px;
    heigth; 100%;

    &:hover  { 
      color: #fff;

      & td {
        background-color: var(--color--accent);
      }

      & svg {
        fill: #fff;
      }
    }
  }

  & tr.un_hover {
    color: var(--color--dark);

      & td {
        background-color: #fff;
      }

      & svg {
        fill: currentColor;
      }
  }

  & tbody.edit {
    tr { margin-bottom: 16px; }

    td.access.true.edit {background-color: var(--color--succes, #3CBC81)}
    td.access.false.edit {background-color: var(--color--fail, #E74A3B)}
    td.access.edit { color: #fff }
  }

  & th {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 42px;
    padding: 4px 4px 8px;

    color: #000;
    font-weight: 500;
    text-transform: lowercase;
    // text-transform: capitalize;

    background-color: var(--color--bg-light, #eff1f4);
  }
  
  & td {
    display: flex;
    align-items: center;
    height: 32px;
    min-height: 32px;
    padding: 4px;

    background-color: #fff;
    border: 2px solid var(--color--bg-light, #eff1f4);

    @media ${minDevice.desktopL} { padding: 4px 8px}
  }

// ========== TH and TD ==========

  & th, td {
    // ========== for ALL ==========
    &.checkbox { 
      flex: 0 0 28px;
      max-width: 28px;
      padding: 0 8px 0 0;

      background-color: transparent;
    }
    &.id { 
      flex: 0 0 44px;
      max-width: 44px;
    }


    // ========== for ALL ==========

    &.buch { 
      flex: 0 0 60px;
      max-width: 60px;
      justify-content: center;
    }
    &.arrow { 
      flex: 0 0 20px;
      max-width: 20px;
      justify-content: center;
      // background-color: transparent;
    }
    &.number { 
      flex: 0 0 68px;
      max-width: 68px;
      justify-content: center;
    }
    &.name { 
      flex: 0 0 208px;
      max-width: 248px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.role { 
      flex: 0 0 110px;
      max-width: 110px;
    }
    &.short_name { 
      flex: 0 0 120px;
      max-width: 130px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.tax { 
      flex: 0 0 100px;
      max-width: 128px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.enterprise_status { 
      flex: 0 0 110px;
      max-width: 110px;
    }
    &.enterprise_owner { 
      flex: 1 0 120px;
    }

    &.street { 
      flex: 2 0 160px;
    }
    &.postal_code { 
      flex: 0 0 72px;
      max-width: 120px;
      // justify-content: center;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.city { 
      flex: 0 0 120px;
      max-width: 200px;

      @media ${minDevice.desktop} { flex-grow: 2;}
    }

    &.contact_tel { 
      flex: 0 0 120px;
      max-width: 148px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.contact_fax { 
      flex: 0 0 104px;
      max-width: 120px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.contact_email { 
      flex: 1 0 168px;
    }

    &.login { 
      flex: 0 0 208px;
      // max-width: 248px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.password { 
      position: relative;
      flex: 0 0 180px;
      max-width: 180px;

      & button {
        right: 4px;
      };
      & svg {
        width: 14px;
        height: 14px;
      };
    }
    &.access { 
      flex: 0 0 100px;
      max-width: 100px;
      justify-content: center;
    }

    &.firm_name {
      flex: 0 0 100px;
      max-width: 100px;
    }
    &.group {
      flex: 0 0 100px;
      max-width: 100px;
    }
    &.commission {
      flex: 0 0 68px;
    }
    // ========== for AGENCY ==========
    // ========== for LOCATIONS ==========
    &.locations_country { 
      flex: 0 0 160px;
      max-width: 180px;

      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.locations_city { 
      flex: 0 0 160px;
      max-width: 180px;

      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    &.locations_address { 
      flex: 1 0 120px;
    }
    &.locations_name { 
      flex: 1 0 120px;
      max-width: 320px;
    }
    &.locations_distance { 
      flex: 1 0 120px;
    }
    // ========== for LOCATIONS ==========
    // ========== for Discounts ==========
    &.discounts_name { 
      flex: 1 0 160px;
    }
    &.discounts_type { 
      flex: 1 0 118px;
      max-width: 186px;
      justify-content: center;
      text-align: center;
    }
    &.discounts_value { 
      flex: 1 0 80px;
      max-width: 132px;
      justify-content: center;
    }
    &.currency { 
      flex: 1 0 80px;
      max-width: 132px;
      justify-content: center;
    }

    // ========== for Discounts ==========
    // ========== for Routes ==========
    &.route { 
      flex: 1 0 300px;
      // max-width: 300px;
    }
    // ========== for Routes ==========
    
    // ========== for Trips ==========


    &.trip_route { 
      flex: 1 0 300px;
      justify-content: flex-start;
    }

    &.departure_city,
    &.arrival_city {
      flex: 0 0 120px;
      max-width: 120px;
    }

    &.return_date,
    &.departure_date,
    &.departure_days,
    &.arrival_days {
      flex: 0 0 106px;
      max-width: 106px;
    }
    // &.departure_days {
    //   justify-content: flex-end;
    //   text-align: end;
    // }
    &.departure_time, 
    &.arrival_time { 
      flex: 0 0 44px;
      max-width: 44px;
      justify-content: center;
    }


    &.agency_name,
    &.carrier_name { 
      flex: 1 0 104px;
      // max-width: 120px;
    }
   
    &.ow { 
      flex: 0 0 40px;
      max-width: 40px;
      justify-content: flex-end;
    }
    &.rt { 
      flex: 0 0 40px;
      max-width: 40px;
      justify-content: flex-end;
    }
    &.ow_btn {
      flex: 0 0 150px;
      max-width: 150px;
      justify-content: center;
    }
    &.rt_btn {
      flex: 0 0 150px;
      max-width: 150px;
      justify-content: center;
    }
    &.payment {
      flex: 0 0 86px;
      max-width: 86px;
    }
    &.status { 
      flex: 0 0 108px;
      max-width: 108px;
      justify-content: center;
    }
    &.direction { 
      flex: 0 0 160px;
      max-width: 160px;
    }
    &.select_btn { 
      flex: 0 0 106px;
      max-width: 120px;
      padding: 0 2px;
    }
    // ========== for Trips ==========
    // ========== for transports ==========
    &.license_plate { 
      flex: 0 0 132px;
      max-width: 132px;
    }
    &.color { 
      flex: 0 0 80px;
      max-width: 80px;
    }
    &.seats { 
      flex: 0 0 100px;
      max-width: 120px;
      justify-content: center;

      // @media ${minDevice.tablet} { flex-grow: 1;}
    }
    &.phone { 
      flex: 0 0 104px;
      max-width: 120px;
      @media ${minDevice.desktop} { flex-grow: 1;}
    }
    // ========== for transports ==========
    // ========== for bookings ==========
    &.created_date { 
      flex: 0 0 100px;
      max-width: 100px;
      // justify-content: center;
    }
    &.ticket,
    &.invoice { 
      flex: 0 0 60px;
      max-width: 60px;
      justify-content: center;
    }

    &.full_name {
      flex: 0 0 160px;
    }


    // ========== for bookings ==========
    // ========== for edit ==========
    &.checkbox {
      flex: 0 0 24px;
      max-width: 24px;
      padding: 0;
      justify-content: center;
    }

    // ========== for edit ==========
    
    


    &.access.true {color: var(--color--succes, #3CBC81)}
    &.access.false {color: var(--color--fail, #E74A3B)}
    &.status.true {color: var(--color--succes, #3CBC81)}
    &.status.false {color: var(--color--fail, #E74A3B)}


    // &.access.true.edit {background-color: var(--color--succes, #3CBC81)}
    // &.access.false.edit {background-color: var(--color--fail, #E74A3B)}
    // &.access.edit { color: var(--color--black, #000 ) }
  }
  // ========== TH and TD ==========

  // ========== TD ONLY ==========
  td {
    &.edit {padding: 0}
    &.id {justify-content: flex-end;}
  }
  // ========== TD ONLY ==========

  & td.status.new { color: var(--color--new, #E74A3B) }
  & td.status.confirmed { color: var(--color--confirmed, #3b5ae7) }
  & td.status.paid { color: var(--color--paid, #3CBC81) }
  & td.status.canceled { color: var(--color--canceled, #1e1e1e) }
  
  & tr.booking-passengers {
    // width: 100%;

    & td:nth-of-type(1) { 
      flex: 0 0 200px; 
      // padding-left: 40px;
      gap: 12px;
      justify-content: flex-end;

      & span { font-weight: 600; }
    }
    & td:nth-of-type(2) { 
      flex: 0 0 180px; 
      justify-content: flex-end;

      & span { font-weight: 600; }
    }
    & td:nth-of-type(3) { 
      flex: 0 0 60px; 
      justify-content: flex-end;
      padding: 4px;
      // & span { font-weight: 600; }
    }
    & td:nth-of-type(4) { 
      flex: 0 0 60px; 
      justify-content: flex-end;
      padding: 4px;
    }
    & td:nth-of-type(5) { 
      flex: 0 0 60px; 
      justify-content: flex-end;
      padding: 4px;
    }
    & td:nth-of-type(6) { 
      flex: 0 0 260px; 
      justify-content: flex-end;

      & span { font-weight: 600; }
    }
    & td:nth-of-type(7) { 
      flex-grow: 1;
    }
  }
`;

export const TableSelectBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100%;
  height: 24px;
  padding: 0;

  color: var(--color--gray);
  font-size: 12px;
  font-weight: 500;

  background-color: var(--color--white);
  // border-radius: var(--border-radius);
  border: 1px solid transparent;
  cursor: pointer;
  // transition: all 10ms linear;

  &:hover { 
    color: var(--color--accent);
    border-color: var(--color--accent);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
`;
