import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// redux
import { selectLang } from 'redux/lang/langSelectors';
import { getTripsBySearchQuery } from 'redux/trips/operations';
// components
import InputLocationCity2 from 'components/Form/Input/InputLocation/InputLocationCity2';
// styled
import { BasicForm, BasicSubmitBtn } from 'components/Form/Form.styled';

const TripsSearchForm = ({ citiesList, welcome }) => {
  const currentLang = useSelector(selectLang) || "de";
  const [cityList, setCityList] = useState(null);

  const [cityDeDeparture, setCityDeDeparture] = useState("");
  const [cityRuDeparture, setCityRuDeparture] = useState("");
  const [cityUaDeparture, setCityUaDeparture] = useState("");
  const [cityValueDeparture, setCityValueDeparture] = useState("");

  const [cityDeArrival, setCityDeArrival] = useState("");
  const [cityRuArrival, setCityRuArrival] = useState("");
  const [cityUaArrival, setCityUaArrival] = useState("");
  const [cityValueArrival, setCityValueArrival] = useState("");

  const idDeparture = cityDeDeparture && cityRuDeparture && cityUaDeparture;
  const isArrival = cityDeArrival && cityRuArrival && cityUaArrival;
  const dispatch = useDispatch();


  const handleSubmit = e => {
    e.preventDefault();

    const searchQuery = {
      departure: {
        city: {
          de: cityDeDeparture,
          ru: cityRuDeparture,
          ua: cityUaDeparture,
        },
      },
      arrival: {
        city: {
          de: cityDeArrival,
          ru: cityRuArrival,
          ua: cityUaArrival,
        },
      }
    }
  
    dispatch(getTripsBySearchQuery({data: searchQuery, pathname: welcome}));
  }

  useEffect(() => {if(citiesList) setCityList(citiesList)}, [citiesList])
  useEffect(() => {
    switch (currentLang) {
      case "de":
        setCityValueDeparture(cityDeDeparture)
        setCityValueArrival(cityDeArrival)
        break;
      case "ru":
        setCityValueDeparture(cityRuDeparture)
        setCityValueArrival(cityRuArrival)
        break;
      case "ua":
        setCityValueDeparture(cityUaDeparture)
        setCityValueArrival(cityUaArrival)
        break;
    
      default:
        break;
    }
  }, [currentLang, cityDeDeparture,cityRuDeparture,cityUaDeparture, cityDeArrival, cityRuArrival, cityUaArrival])

  return (
    <BasicForm onSubmit={handleSubmit}>
      <InputLocationCity2
        items={3}
        dataList={cityList}
        name={"departure_city"}
        placeholder={text.departure_city[currentLang]}
        inputLang={[currentLang]}
        value={cityValueDeparture}
        setValue={setCityValueDeparture}
        setValueDe={setCityDeDeparture}
        setValueRu={setCityRuDeparture}
        setValueUa={setCityUaDeparture}
      />
      <InputLocationCity2
        items={3}
        dataList={cityList}
        name={"arrival_city"}
        placeholder={text.arrival_city[currentLang]}
        inputLang={[currentLang]}
        value={cityValueArrival}
        setValue={setCityValueArrival}
        setValueDe={setCityDeArrival}
        setValueRu={setCityRuArrival}
        setValueUa={setCityUaArrival}
      />

      <BasicSubmitBtn style={{margin: 0}} type="submit" disabled={!(idDeparture && isArrival)}> {text.search[currentLang]} </BasicSubmitBtn>
    </BasicForm>
  )
}

export default TripsSearchForm

const text = {
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  departure_city: {
    de: "Abfahrtstadt",
    ru: "Город отправления",
    ua: "Місто відправлення",
  },
  arrival_city: {
    de: "Anreisestadt",
    ru: "Город прибытия",
    ua: "Місто прибуття",
  },

  search: {
    de: "Suchen",
    ru: "Поиск",
    ua: "Пошук",
  },
}