import { createSlice } from "@reduxjs/toolkit";
import { createBaggage, getBaggage, updateBaggageById, } from "./operations";

const initialState = {};

export const baggageSlise = createSlice({
  name: "baggage",
  initialState,
  reducers: {
    resetBaggageState() { return initialState; },
    resetBaggageStatus(state) { state.status = ""; },
    resetBaggageError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createBaggage.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(createBaggage.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createBaggage.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(getBaggage.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getBaggage.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getBaggage.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateBaggageById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateBaggageById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.item = action.payload;
      })
      .addCase(updateBaggageById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

  

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetBaggageState, resetBaggageStatus, resetBaggageError } = baggageSlise.actions;
export const baggageReducer = baggageSlise.reducer;
