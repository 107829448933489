import styled from "styled-components";

export const BookingBtnTag = styled.button`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  width: 100%;
  height: 100%;

  background-color: var(--color--bg-light);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow--main);
    background-color: var(--color--accent);
    color: #fff;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  & span {
    font-weight: 500;
  }

  &.current {
    box-shadow: var(--box-shadow--main);
    background-color: var(--color--accent);
    color: #fff;

    
  }
`;