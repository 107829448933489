import styled from "styled-components";

export const AddTripScheduleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const AddTripScheduleBoxItem = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;
  width: 100%;
  padding: 16px 0;

  border-bottom: 1px solid var(--color--gray);
`;

export const TripFormDateTimeLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 104px;
  max-width: 104px;
  height: 32px;

  color: var(--color--dark);
  // font-weight: 500;

  background-color: #fff;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;

  & p {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 32px;

    background-color: #fff;
    border-radius: var(--border-radius);
    cursor: pointer;
  }

  &:hover {
    color: var(--color--accent);
  }

  &.disabled p {
    color: var(--color--gray);
    cursor: auto;
    background-color: var(--color--bg-light);
  }
  &.disabled select {
    color: var(--color--gray);
    cursor: auto;
    background-color: var(--color--bg-light);
  }
`;
export const TripFormDateTimeLabelTime = styled(TripFormDateTimeLabel)`
  cursor: pointer;
`;
export const TripFormDateTimeInput = styled.input`
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 8px 4px;
  width: 100%;
  height: 36px;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
`;
export const TripFormDateTimeInputClassic = styled.input`
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 8px 4px;
  width: 100%;
  height: 36px;
`;
export const TripFormNote = styled.p`
  display: flex;
  gap: 8px;

  font-size: 14px;
  color: var(--color--accent-dark);

  background-color: var(--color--accent-lite);
  border-radius: var(--border-radius);

  &::before {
    content: "i";
    flex: 0 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;

    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    background-color: var(--color--accent);
  }
`;
export const TripFormScheduleWeekList = styled.ul`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
export const TripFormScheduleWeekItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  // flex-grow: 1;
  // gap: 12px;

  &:first-of-type {
    order: 7;
  }
`;
export const TripFormScheduleWeekName = styled.p`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
`;

export const TripFormScheduleWeekLabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 36px;
`;
export const TripFormScheduleWeekLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  color: var(--color--gray);

  border-radius: 4px;
  border: 1px solid var(--color--gray);
  background-color: #fff;
  cursor: pointer;
  transition: all 100ms linear;

  &::before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    font-size: 22px;
    font-weight: 500;
    color: currentColor;
    transition: 200ms linear;
    opacity: 0;
  }

  input:disabled ~ & {
    background-color: var(--color--bg-light);
    cursor: not-allowed;
    // border-color: var(--color--accent-lite);
  }
  input:checked ~ & {
    color: var(--color--white);
    background-color: var(--color--accent);
    border-color: var(--color--accent);
    background-image: url("${process.env.PUBLIC_URL}/icons/check-solid.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;
  }
  input:checked:disabled ~ & {
    color: var(--color--accent);
    background-color: var(--color--accent);
    border-color: var(--color--accent);
    opacity: 0.5;
  }
`;
export const TripFormScheduleWeekInputCheckbox = styled.input`
  display: none;
`;

export const TripFormFeaturesTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
export const TripFormDateTimeList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;
export const TripFormDateTimeItem = styled.li`
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px 0;
  height: 60px;

  border-bottom: 1px solid var(--color--gray);
  // &:last-of-type {
  //   border-bottom: 1px solid var(--color--gray);
  // }
`;
export const TripFormDateTimeName = styled.p`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
`;
export const TripFormDateTimeSelect = styled.select`
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 8px 4px;
  width: 100%;
  height: 36px;

  cursor: pointer;
  background-color: var(--color--accent-lite);

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;
