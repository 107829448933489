import React from "react";
// hooks
import useAuth from "hooks/useAuth";
// components
import BurgerMenuBtn from "components/BurgerMenu/BurgerMenuBtn";
import Logo from "components/Logo/Logo";
import WelcomeBox from "components/WelcomeBox/WelcomeBox";
import LangSwitcher from "components/LangSwitcher/LangSwitcher";
import Today from "components/Today/Today";
// styled
import { HeaderContainer, HeaderLogoWrap, HeaderTag } from "./Header.styled";

const Header = ({ isBurgerMenuOpen, setIsBurgerMenuOpen, welcome }) => {
  const { isLoggedIn } = useAuth();

  return (
    <HeaderTag>
      <HeaderContainer className={welcome && "welcome"}>
        <HeaderLogoWrap>
          {isLoggedIn && <BurgerMenuBtn isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />} 
          <Logo />
        </HeaderLogoWrap>
        {isLoggedIn && <Today />}
        <WelcomeBox /> 
        <LangSwitcher />
      </HeaderContainer>
    </HeaderTag>
  );
};

export default Header;
