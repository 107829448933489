export const text_global = {
  role: {
    de: "Rolle",
    ru: "Роль",
    ua: "Роль",
  },
  superadmin: {
    de: "Superadmin",
    ru: "Суперадмин",
    ua: "Суперадмін",
  },
  consolidator: {
    de: "Konsolidierer",
    ru: "Консолидатор",
    ua: "Консолідатор",
  },
  chief: {
    de: "Chef",
    ru: "Шеф",
    ua: "Шеф",
  },
  dispatcher: {
    de: "Dispatcher",
    ru: "Диспетчер",
    ua: "Диспетчер",
  },
  carrier_manager: {
    de: "Transporteur",
    ru: "Перевозчик",
    ua: "Перевізник",
  },
  agency_manager: {
    de: "Agent",
    ru: "Агент",
    ua: "Агент",
  },
  user: {
    de: "Benutzer",
    ru: "Пользователь",
    ua: "Користувач",
  },

  user_name: {
    de: "Spitzname / Name",
    ru: "Никнейм / имя",
    ua: "Псевдонім / ім'я",
  },
  agency_name: {
    de: "Agentur",
    ru: "Агентство",
    ua: "Агентство",
  },
  carrier_name: {
    de: "Firmenname",
    ru: "Название компании",
    ua: "Назва компанії",
  },
  consolidator_name: {
    de: "Gruppe",
    ru: "Группа",
    ua: "Група",
  },
  group: {
    de: "Gruppe",
    ru: "Группа",
    ua: "Група",
  },

  commission: {
    de: "Kommission",
    ru: "Комиссия",
    ua: "Комісія",
  },
  commissions: {
    de: "Kommission",
    ru: "Комиссии",
    ua: "Комісії",
  },


  login: {
    de: "Benutzer",
    ru: "Пользователь",
    ua: "Користувач",
  },
  password: {
    de: "Kennwort",
    ru: "Пароль",
    ua: "Пароль",
  },
  access: {
    de: "Zugang",
    ru: "Доступ",
    ua: "Доступ",
  },
  true: {
    de: "Aktiv",
    ru: "Активный",
    ua: "Активний",
  },
  false: {
    de: "Deaktiviert",
    ru: "Отключен",
    ua: "Відключено",
  },
  // true: {
  //   de: "Aktiv",
  //   ru: "Активный",
  //   ua: "Активний",
  // },
  // false: {
  //   de: "Passiv",
  //   ru: "Пассивный",
  //   ua: "Пасивний",
  // },
  available:{
    de: "Verfügbar",
    ru: "Доступно",
    ua: "Доступно",
  },
  not_available:{
    de: "Nicht verfügbar",
    ru: "Недоступно",
    ua: "Недоступно",
  },
  block: {
    de: "Block",
    ru: "Заблокировать",
    ua: "Заблокувати",
  },
  unblock: {
    de: "Entblocken",
    ru: "Разблокировать",
    ua: "Розблокувати",
  },
  one_date: {
    de: "Ein Datum",
    ru: "Одна дата",
    ua: "Одна дата",
  },
  range_dates: {
    de: "Datumsbereich",
    ru: "Диапазон дат",
    ua: "Діапазон дат",
  },


  status: {
    de: "Status",
    ru: "Статус",
    ua: "Статус",
  },
  new: {
    de: "Neu",
    ru: "Новый",
    ua: "Новий",
  },
  paid: {
    de: "Bezahlt",
    ru: "Оплачено",
    ua: "Сплачено",
  },
  confirmed: {
    de: "Bestätig",
    ru: "Подтверждено",
    ua: "Підтверджено",
  },
  canceled: {
    de: "Abgesagt",
    ru: "Отменено",
    ua: "Скасовано",
  },
  null: {
    de: "-",
    ru: "-",
    ua: "-",
  },

  created_date: {
    de: "Erstelldatum",
    ru: "Дата создания",
    ua: "Дата створення",
  },
  order_date: {
    de: "Auftragsdatum",
    ru: "Дата заказа",
    ua: "Дата замовлення",
  },
  departure_date: {
    de: "Abfahrtdatum",
    ru: "Дата выезда",
    ua: "Дата виїзду",
  },
  departure_time: {
    de: "Zeit",
    ru: "Время",
    ua: "Час",
  },
  return_date: {
    de: "Rückfahrtdatum",
    ru: "Дата выезда обратно",
    ua: "Дата виїзду назад",
  },
  invoice: {
    de: "Rechnung",
    ru: "Счет",
    ua: "Рахунок",
  },
  ticket: {
    de: "Ticket",
    ru: "Билет",
    ua: "Квиток",
  },
  payment: {
    de: "Zahlung",
    ru: "Оплата",
    ua: "Оплата",
  },

  departure_country: {
    de: "Abflugland",
    ru: "Страна отправления",
    ua: "Країна відправлення",
  },
  arrival_country: {
    de: "Ankunftsland",
    ru: "Страна прибытия",
    ua: "Країна прибуття",
  },
  departure_city: {
    de: "Abfahrtort",
    ru: "Город отправления",
    ua: "Місто відправлення",
  },
  arrival_city: {
    de: "Ankunftsort",
    ru: "Город прибытия",
    ua: "Місто прибуття",
  },

  country: {
    de: "Land",
    ru: "Страна",
    ua: "Країна",
  },
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  discounts: {
    de: "Rabatte",
    ru: "Скидки",
    ua: "Знижки",
  },
  discounts_name: {
    de: "Rabattname",
    ru: "Название скидки",
    ua: "Назва знижки",
  },
  discounts_value: {
    de: "Rabattgröße",
    ru: "Размер скидки",
    ua: "Розмір знижки",
  },
  discounts_type: {
    de: "Rabattart",
    ru: "Тип скидки",
    ua: "Тип знижки",
  },
  percent: {
    de: "Prozent %",
    ru: "Процент %",
    ua: "Відсоток %",
  },
  fixed: {
    de: "Fester wert",
    ru: "Фиксированное значение",
    ua: "Фіксоване значення",
  },
  price: {
    de: "Preis",
    ru: "Цена",
    ua: "Ціна",
  },
  prices: {
    de: "Preise",
    ru: "Цены",
    ua: "Ціни",
  },
  price_ow: {
    de: "Einwegpreis",
    ru: "Цена в одну сторону",
    ua: "Ціна в одну сторону",
  },
  price_rt: {
    de: "Preise",
    ru: "Цены",
    ua: "Ціни",
  },
  currency: {
    de: "Währung",
    ru: "Валюта",
    ua: "Валюта",
  },
  euro: {
    de: "Euro",
    ru: "Евро",
    ua: "Євро",
  },
  baggage: {
    de: "Gepäck",
    ru: "Багаж",
    ua: "Багаж",
  },
  baggage_name: {
    de: "Name des Freigepäcks",
    ru: "Название нормы багажа",
    ua: "Назва норми багажу",
  },
  baggage_value: {
    de: "Der Wert der Norm",
    ru: "Значение нормы",
    ua: "Значення норми",
  },
  baggage_type: {
    de: "Art der Freigepäckmenge",
    ru: "Вид нормы багажа",
    ua: "Вид норми багажу",
  },
  baggage_currency: {
    de: "Einheiten",
    ru: "Единицы измерения",
    ua: "Одиниці виміру",
  },
  units: {
    de: "Einheiten",
    ru: "Единицы измерения",
    ua: "Одиниці виміру",
  },
  weight: {
    de: "Gewicht",
    ru: "Вес",
    ua: "Вага",
  },
  kg: {
    de: "Kg",
    ru: "Кг",
    ua: "Кг",
  },

  bookings: {
    de: "Reservierungen",
    ru: "Бронирование",
    ua: "Бронювання",
  },
  bus_seats: {
    de: "Sitzplätze im Bus",
    ru: "Места в автобусе",
    ua: "Місця в автобусі",
  },
  passengers_list: {
    de: "Passagiere",
    ru: "Список пассажиров",
    ua: "Список пасажирів",
  },
  passengers: {
    de: "Passagiere",
    ru: "Список пассажиров",
    ua: "Список пасажирів",
  },
  invoice_ticket_text: {
    de: "Rechnungstext und tickettext",
    ru: "Текст счета и билета",
    ua: "Текст рахунку та квитка",
  },

  bus: {
    de: "im Bus",
    ru: "в автобусе",
    ua: "в автобусі",
  },
  office: {
    de: "im Büro",
    ru: "в бюро",
    ua: "в бюро",
  },
  empty: {
    de: "leer",
    ru: "пусто",
    ua: "пусто",
  },
  direction: {
    de: "Richtung",
    ru: "Направление",
    ua: "Напрямок",
  },
  trips: {
    de: "Buslinien",
    ru: "Рейсы",
    ua: "Рейси",
  },
  trip: {
    de: "Buslinien",
    ru: "Рейс",
    ua: "Рейс",
  },
  routes: {
    de: "Routen",
    ru: "Маршруты",
    ua: "Маршрути",
  },
  route: {
    de: "Route",
    ru: "Маршрут",
    ua: "Маршрут",
  },
  directions: {
    de: "Richtungen",
    ru: "Направления",
    ua: "Напрямки",
  },
  schedule: {
    de: "Zeitplan",
    ru: "Расписание",
    ua: "Розклад",
  },



  all: {
    de: "Alle",
    ru: "Все",
    ua: "Всі",
  },

  add: {
    de: "Hinzufügen",
    ru: "Добавить",
    ua: "Додати",
  },
  save: {
    de: "Sparen",
    ru: "Сохранить",
    ua: "Зберегти",
  },
  edit: {
    de: "Bearbeiten",
    ru: "Редактировать",
    ua: "Редагувати",
  },
  cancel: {
    de: "Stornieren",
    ru: "Отмена",
    ua: "Скасувати",
  },
  select: {
    de: "Wählen",
    ru: "Выбрать",
    ua: "Обрати",
  },
  select_time: {
    de: "Wählen Sie eine Zeit",
    ru: "Выбрать время",
    ua: "Обрати час",
  },

  select_date: {
    de: "Datum auswählen",
    ru: "Выберите дату",
    ua: "Виберіть дату",
  },

  start_from: {
    de: "beginnend mit",
    ru: "начиная с",
    ua: "починаючи з",
  },
  change: {
    de: "Ändern",
    ru: "Изменить",
    ua: "Змінити",
  },
  start: {
    de: "Anfang",
    ru: "Старт",
    ua: "Старт",
  },
  end: {
    de: "Ende",
    ru: "Конец",
    ua: "Кінець",
  },
  from: {
    de: "von",
    ru: "от",
    ua: "від",
  },
  to: {
    de: "bis",
    ru: "до",
    ua: "до",
  },
  next: {
    de: "Weiter",
    ru: "Далее",
    ua: "Далі",
  },
  booked: {
    de: "Reserviert",
    ru: "Забронировано",
    ua: "Заброньовано",
  },
  blocked: {
    de: "Verstopft",
    ru: "Заблокировано",
    ua: "Заблоковано",
  },
  ltd: {
    de: "Firma",
    ru: "Фірма",
    ua: "Фірма",
  },
  pe: {
    de: "Reisebüro",
    ru: "Турагентство",
    ua: "Турагентство",
  },
  data_not_found: {
    de: "Daten nicht gefunden",
    ru: "Данные не найдены",
    ua: "Дані не знайдені",
  },
  activate: {
    de: "Aktivieren",
    ru: "Активировать",
    ua: "Активувати",
  },
  activated: {
    de: "aktiviert",
    ru: "активировано",
    ua: "активовано",
  },
  deactivated: {
    de: "deaktiviert",
    ru: "деактивировано",
    ua: "деактивовано",
  },
  delete: {
    de: "Entfernen",
    ru: "Удалить",
    ua: "Видалити",
  },
  sum: {
    de: "Summe",
    ru: "Сумма",
    ua: "Сума",
  },
  contact_tel: {
    de: "Telefon",
    ru: "Телефон",
    ua: "Телефон",
  },
  salutation: {
    de: "Anrede",
    ru: "Приветствие",
    ua: "Привітання",
  },
  mr: {
    de: "Herr",
    ru: "Мистер",
    ua: "Містер",
  },
  mrs: {
    de: "Frau",
    ru: "Миссис / Мисс",
    ua: "Місіс / Міс",
  },
  free: {
    de: "offene",
    ru: "открытая",
    ua: "відкрита",
  },
}