import React, { useEffect, useState } from "react";
import { BusSeatsBtnTag } from "./BusSeatsSchema.styled";

const BusSeatsBtn = ({ data, type, btnSelected, disabled, handleBusSeatsBtnClick }) => {
  const [select, setSelect] = useState(false);

  const handleClick = () => {
    handleBusSeatsBtnClick(data, type)
    setSelect(!select);
  };

  useEffect(() => { setSelect(btnSelected) }, [btnSelected])

  return (
    <BusSeatsBtnTag
      type="button"
      className={select ? `${data?.status} select` : data?.status}
      disabled={data?.status === "booked" || (disabled && !select)}
      onClick={handleClick}
    >
      {data?.value}
    </BusSeatsBtnTag>
  );
};

export default BusSeatsBtn;
