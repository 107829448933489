import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoint, minDevice, } from "components/common/breakpoints";
import { BtnBasic } from "components/AddRouteForm/AddRouteForm.styled";

export const WelcomePageSection = styled.section`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 20px 0 80px;

  background-color: var(--color--accent);
`;

export const WelcomePageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 16px 16px 32px;
  margin: 0 auto;

  color: var(--color--dark, #1e1e1e);

  background-color: var(--color--white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow--main);

  @media ${minDevice.tablet} {
    width: ${breakpoint.mobile}px;
    padding: 32px;
  }
  @media ${minDevice.desktop} {
    padding: 44px 50px;
  }
`;
export const WelcomePageTitle = styled.h1`
  font-size: 40px;
  font-weight: 600;
  color: #fff;
`;
export const WelcomePageSubtitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #fff;
`;

export const WelcomePageFindTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  height: 32px;
  margin-bottom: 20px;
`;
export const WelcomePageFindTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
`;
export const WelcomePageText = styled.p`
  margin: 20px 0;

  font-size: 20px;
  color: #000;
`;

export const WelcomePageNavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 104px;
  height: 32px;
  padding: 12px;

  color: #fff;

  background-color: var(--color--accent);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow--main);
    border-color: #fff;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &.relax {
    color: var(--color--gray);
    background-color: #fff;

    &:hover {
      color: var(--color--accent);
    }
  }
`;


export const WelcomePageCancelBtn = styled(BtnBasic)`
  height: 32px;
  width: 200px;
  margin-left: auto;

  font-size: 12px;
  font-weight: 600;
  color: var(--color--accent);

  background-color: #fff;
  border: 1px solid var(--color--accent);
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: var(--color--accent);
  }
`;
