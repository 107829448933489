import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import { selectDirectionsItem, selectTripsSearch } from "redux/trips/tripsSelectors";
import { getDirectionsByTripIdAndSearchQuery } from "redux/trips/operations";
import { selectCitiesList } from "redux/locations/locationsSelectors";
import { resetBookingsStatus } from "redux/bookings/bookingsSlice";
import { resetTripsSearch } from "redux/trips/tripsSlice";
import { getCities } from "redux/locations/operations";
import { selectLang } from "redux/lang/langSelectors";
import useAuth from "hooks/useAuth";
// components
import Header from "components/Header/Header";
import LoaderLocal from "components/Loader/LoaderLocal";
import BookingForm from "components/BookingForm/BookingForm";
import TableTripsSearch from "components/TableTrips/TableTripsSearch";
import TripsSearchForm from "components/TripsSearchForm/TripsSearchForm";
// styled
import { Container, Section } from "components/common/common.styled";
import { WelcomePageTitle, WelcomePageSection, WelcomePageSubtitle, WelcomePageFindTitle, WelcomePageFindTitleWrap, WelcomePageCancelBtn, } from "./WelcomePage.styled";

const WelcomePage = () => {
  const currentLang = useSelector(selectLang) || "de";
  const citiesList = useSelector(selectCitiesList);
  const tripsSearch = useSelector(selectTripsSearch);
  const tripReverse = useSelector(selectDirectionsItem);

  const [render, setRender] = useState(true);
  const [dataToRender, setDataToRender] = useState(tripsSearch);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [currentTrip, setCurrentTrip] = useState(null);
  const [currentTripReverse, setCurrentTripReverse] = useState(tripReverse);
  const { isRefreshing } = useAuth();
  const dispatch = useDispatch();

  const handleBookingBtnClick = (priceData, tripData) => {
    setCurrentTrip(tripData);
    setDataToRender([tripData]);
    setCurrentPrice(priceData);
  }

  function handleUpdateSearchState (type) {
    setCurrentTrip(null);
    setCurrentPrice(null);
    dispatch(resetBookingsStatus());

    switch (type) {
      case "new":
        setDataToRender(tripsSearch);
        break;

      case "reset":
        dispatch(resetTripsSearch());
        break;
    
      default:
        setDataToRender(tripsSearch);
        break;
    }
  }

  useEffect(() => {
    if (render) return setRender(false);
    if (!citiesList) dispatch(getCities());
  }, [render, dispatch, citiesList]);
  useEffect(() => { 
    setDataToRender(tripsSearch);
    setCurrentTrip(null);
    setCurrentPrice(null);
  }, [tripsSearch]);
  useEffect(() => { 
    setCurrentTripReverse(tripReverse)
  }, [tripReverse]);
  useEffect(() => {
    if (currentPrice?.type === "rt") {
      dispatch(getDirectionsByTripIdAndSearchQuery({
        data: {
          trip_id: currentTrip?.reverse_trip_id,
          departure: {city: currentTrip?.arrival?.city},
          arrival: {city: currentTrip?.departure?.city},
        },
        pathname: "/welcome",
      })) 
    }
  }, [currentPrice, dispatch, currentTrip]);

 

  return isRefreshing ? (
    <LoaderLocal />
  ) : (
    <>
      <Header welcome={true} />
      <WelcomePageSection>
        <Container>
          <WelcomePageTitle>{text.title[currentLang]}</WelcomePageTitle>
          <WelcomePageSubtitle>
            {" "}
            {text.subtitle[currentLang]}{" "}
          </WelcomePageSubtitle>
        </Container>
      </WelcomePageSection>
      <Section>
        <Container>
          <TripsSearchForm citiesList={citiesList} welcome={"/welcome"} />
        </Container>
      </Section>
      <Section>
        <Container>
          {dataToRender && (
            <WelcomePageFindTitleWrap>
              <WelcomePageFindTitle>
                {text.find_trips[currentLang]}: {tripsSearch?.length}.{" "}
                {!dataToRender?.length &&
                  text.choose_another_route[currentLang]}
              </WelcomePageFindTitle>
              {currentTrip && (
                <WelcomePageCancelBtn
                  type="button"
                  onClick={() => handleUpdateSearchState("new")}
                >
                  {" "}
                  {text.сancel_selection[currentLang]}{" "}
                </WelcomePageCancelBtn>
              )}
            </WelcomePageFindTitleWrap>
          )}

          {dataToRender && (
            <TableTripsSearch
              tripsList={dataToRender}
              currentPrice={currentPrice}
              handleBookingBtnClick={handleBookingBtnClick}
              handleUpdateSearchState={handleUpdateSearchState}
            />
          )}
          {currentTrip && (
            <BookingForm
              currentPrice={currentPrice}
              currentTrip={currentTrip}
              currentTripReverse={currentTripReverse}
              handleUpdateSearchState={handleUpdateSearchState}
            />
          )}
        </Container>
      </Section>
    </>
  );
};

export default WelcomePage;

const text = {
  title: {
    de: "Reise Zentrum ",
    ru: "Туристический центр",
    ua: "Центр подорожей",
  },
  subtitle: {
    de: "Informations- und Reservierungssystem",
    ru: "Система информации и бронирования",
    ua: "Інформаційно-бронювальна система",
  },
  sign_in: {
    de: "Melden Sie sich beim Konto an",
    ru: "Войти в аккаунт",
    ua: "Увійти в аккаунт",
  },
  find_trips: {
    de: "Gefundene Flüge",
    ru: "Найдено рейсов",
    ua: "Знайдено рейсів",
  },
  сancel_selection: {
    de: "Auswahl abbrechen",
    ru: "Отменить выбор",
    ua: "Скасувати вибір",
  },
  choose_another_route: {
    de: "Wählen Sie eine andere Route!",
    ru: "Выберите другой маршрут!",
    ua: "Оберіть інший маршрут!",
  },
};
