const { addLocationDistance } = require("./addLocationDistance");
const { createLocation } = require("./createLocation");
const { getAddressesByCity } = require("./getAddressesByCity");
const { getCities } = require("./getCities");
const { getCitiesByCountry } = require("./getCitiesByCountry");
const { getCountries } = require("./getCountries");
const { getLocationDistance } = require("./getLocationDistance");
const { getLocationDistancebyUser } = require("./getLocationDistancebyUser");
const { getLocations } = require("./getLocations");

module.exports = {
  createLocation,
  getLocations,
  getCountries,
  getCities,
  getCitiesByCountry,
  getAddressesByCity,
  addLocationDistance,
  getLocationDistance,
  getLocationDistancebyUser,
};
