import React from 'react';
import { useSelector } from 'react-redux';
// react
import { selectLang } from 'redux/lang/langSelectors';
// hooks
import useWindowDimensions from "hooks/useWindowDimensions";
// components
import { BREAKPOINT_FOR_MOBILE_MENU } from "components/common/constants";
// styled
import {
  // PageNavIconCog,
  PageNavIconCreditCard,
  PageNavIconSearch,
  PageNavItem,
  PageNavLink,
  PageNavList,
  PageNavTag,
} from "./PageNav.styled";


const UserPageNav = ({ setIsBurgerMenuOpen }) => {
  const currentLang = useSelector(selectLang) || "de";
  const { width } = useWindowDimensions();

  const handleClick = () => {
    if (width < BREAKPOINT_FOR_MOBILE_MENU) setIsBurgerMenuOpen(false);
  };

  return (
    <PageNavTag>
      <PageNavList onClick={handleClick}>
        <PageNavItem>
          <PageNavLink to={"/bookings"}> <PageNavIconCreditCard /> {text.bookings[currentLang]} </PageNavLink>
        </PageNavItem>
        <PageNavItem>
          <PageNavLink to={"/trips/search"}> <PageNavIconSearch /> {text.search_trips[currentLang]} </PageNavLink>
        </PageNavItem>

        {/* <PageNavItem className="settings">
          <PageNavLink to={"/settings"}> <PageNavIconCog /> {text.settings[currentLang]} </PageNavLink>
        </PageNavItem> */}
      </PageNavList>
    </PageNavTag>
  );
};

export default UserPageNav;

const text = {
  add_user: {
    de: "Benutzer hinzufügen",
    ru: "Добавить пользователя",
    ua: "Додати користувача",
  },
  bookings: {
    de: "Reservierungen",
    ru: "Бронирование",
    ua: "Бронювання",
  },
  search_trips: {
    de: "Flugsuche",
    ru: "Поиск рейсов",
    ua: "Пошук рейсів",
  },
  settings: {
    de: "Einstellungen",
    ru: "Настройки",
    ua: "Налаштування",
  },
}

