import { createSlice } from "@reduxjs/toolkit";
import { register, login, logout, refreshUser } from "./authOperation";

const initialState = {
  isLoggedIn: false,
  isRefreshing: true,
};

const handlePending = (state) => {
  state.isRefreshing = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.isRefreshing = false;
  state.isLoggedIn = false;

  state.error = action.payload;
};
const handleFulfilled = (state, action) => {
  state.isRefreshing = false;
  state.isLoggedIn = true;
  state.error = null;

  const { email, role, permissions, name } = action.payload;
  state.user = { email, role, permissions, name };
  state.token = action.payload.token;
};
const handleLogout = (state) => {
  state.isRefreshing = false;
  state.isLoggedIn = false;
  state.isLoggedOut = true;
  delete state.user;
  delete state.token;
};

export const authSlise = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(register.pending, (state) => handlePending(state))
      .addCase(register.fulfilled, (state, action) => handleFulfilled(state, action) )
      .addCase(register.rejected, (state, action) => { handleRejected(state, action); })

      .addCase(login.pending, (state) => handlePending(state))
      .addCase(login.fulfilled, (state, action) => handleFulfilled(state, action) )
      .addCase(login.rejected, (state, action) => { handleRejected(state, action); })

      .addCase(refreshUser.pending, (state) => handlePending(state))
      .addCase(refreshUser.fulfilled, (state, action) => handleFulfilled(state, action) )
      .addCase(refreshUser.rejected, (state, action) => handleLogout(state, action))


      .addCase(logout.pending, (state) => handlePending(state))
      .addCase(logout.fulfilled, (state) => {
        handleLogout(state);
        state.isLoggedOut = true;
      })
      .addCase(logout.rejected, (state, action) => { handleRejected(state, action); })
});

export const { setIsRefreshing } = authSlise.actions;
export const authReducer = authSlise.reducer;
