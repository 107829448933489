import styled from "styled-components";

export const PageWrap = styled.div`
  position: fixed;

  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;
export const MainWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: calc(100% - 48px);

  transition: all 300ms linear;
  overflow-y: scroll;
`;