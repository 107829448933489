import styled from "styled-components";
import { maxDevice, minDevice } from "components/common/breakpoints";

export const RouteForm = styled.form`
  margin: 0 auto;
`;

export const BtnBasic = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 36px;
  padding: 0 8px;

  color: inherit;
  font-size: inherit;
  line-height: 1;

  border-radius: var(--border-radius, 8px);
  border: none;
  cursor: pointer;
  transition: 200ms linear;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  @media ${minDevice.mobile} {
    width: 160px;
  }
`;
export const RouteFormBackBtn = styled(BtnBasic)`
  width: calc(100% - 40px);

  max-width: 60px;
  min-height: 32px;

  font-size: 12px;
  font-weight: 500;

  color: var(--color--gray);
  background-color: var(--color--bg-light);
  border: 1px solid var(--color--gray);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:disabled {
    opacity: 0;
    cursor: not-allowed;
    pointer-events: none;
  }

  &:hover {
    border-color: var(--color--accent-lite);
    color: var(--color--accent);
  }
  &:focus {
    outline: 2px solid var(--color--accent);
    border: none;
  }
`;
export const RouteFormStepBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px 0;
`;
export const RouteFormBoxTitle = styled.h2`
  font-size: 18px;
`;
export const RouteFormList = styled.ul`
  display: flex;
  flex-direction: column;
  --gap: 16px;
  gap: var(--gap, 16px);
  width: 100%;
`;
export const RouteFormItem = styled.li`
  position: relative;
  display: flex;

  &.last-point {
    margin-top: 40px;
  }
`;
export const RouteFormDecore = styled.div`
  position: relative;
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;

  &::before {
    content: "";

    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid var(--color--accent, #14a0fd);
  }
  &::after {
    content: "";
    position: absolute;
    top: 16px;

    width: 2px;
    height: calc(100% + var(--gap, 16px) - 16px);
    border-left: 2px dashed var(--color--accent, #14a0fd);
  }

  &.last-point::after {
    // display: none;
    top: -45px;
    height: 45px;
  }
  &.last-point-basic::after {
    top: -45px;
    height: 45px;
  }
`;
export const RouteFormLabelWrap = styled.div`
  flex: 1 0 calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 4px;

  // &.last-point {
  //   margin-top: 20px;
  // }
`;
export const RouteFormLabelTitle = styled.h3`
  display: flex;
  align-items: center;
  height: 24px;

  font-size: 14px;
  font-weight: 500;
`;

export const RouteFormLabeAddBtn = styled(BtnBasic)`
  position: absolute;
  bottom: 80px;
  left: 40px;

  height: 32px;
  width: 200px;

  font-size: 12px;
  font-weight: 600;
  color: var(--color--accent);

  background-color: #fff;
  border: 1px solid var(--color--accent);
  cursor: pointer;

  &:hover {
    color: #fff;
    background-color: var(--color--accent);
  }
`;
export const RouteFormLabeDeleteBtn = styled.button`
  position: absolute;
  top: 0;
  left: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  color: var(--color--gray);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    &::before {
      color: var(--color--accent);
    }
  }

  &::before {
    content: "+";

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 32px;
    transform: rotate(45deg);
    pointer-events: none;
  }
`;

export const RouteFormNote = styled.p`
  display: flex;
  padding: 8px;
  gap: 8px;

  font-size: 14px;
  color: var(--color--accent-dark);

  background-color: var(--color--accent-lite);
  border-radius: var(--border-radius);

  &::before {
    content: "i";
    flex: 0 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;

    font-size: 12px;
    color: #fff;
    border-radius: 50%;
    background-color: var(--color--accent-dark);
  }
`;

export const RouteFormFeaturesTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
export const RouteFormFeaturesTitle = styled.p`
  flex: 0 0 64px;
  width: 64px;
  height: 20px;
`;
export const RouteFormFeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const RouteFormFeaturesItem = styled.li`
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px 0;
  height: 60px;

  border-top: 1px solid var(--color--gray);
  &:last-of-type {
    border-bottom: 1px solid var(--color--gray);
  }
`;
export const RouteFormFeaturesName = styled.p`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
`;
export const RouteFormFeaturesLabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 64px;
  width: 64px;
`;
export const RouteFormFeaturesLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  color: var(--color--gray);

  border-radius: 4px;
  border: 1px solid var(--color--gray);
  background-color: #fff;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    font-size: 22px;
    font-weight: 500;
    color: currentColor;
    transition: 200ms linear;
    // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  input:disabled ~ & {
    background-color: var(--color--bg-light);
    cursor: not-allowed;
    // border-color: var(--color--accent-lite);
  }
  input:checked ~ & {
    color: var(--color--white);
    background-color: var(--color--accent);
    border-color: var(--color--accent);
    background-image: url("${process.env.PUBLIC_URL}/icons/check-solid.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;

    // &::before {opacity: 1}
  }
  input:checked:disabled ~ & {
    color: var(--color--accent);
    background-color: var(--color--accent-lite);
    border-color: var(--color--accent-lite);
  }
`;
export const RouteFormFeaturesInputCheckbox = styled.input`
  display: none;
`;

export const RouteFormDateTimeList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const RouteFormDateTimeItem = styled.li`
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px 0;
  height: 60px;

  border-top: 1px solid var(--color--gray);
  &:last-of-type {
    border-bottom: 1px solid var(--color--gray);
  }
`;
export const RouteFormDateTimeName = styled.p`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
`;
export const RouteFormDateTimeLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 96px;
  max-width: 96px;
  min-height: 20px;
  height: 36px;

  // color: var(--color--accent);
  background-color: var(--color--accent-lite);
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;

  // @media ${maxDevice.tablet} {
  //   margin-left: auto;
  // }

  & p {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 32px;

    background-color: var(--color--accent-lite);
    border-radius: var(--border-radius);
  }

  &.disabled p {
    color: var(--color--gray);
  }
`;
export const RouteFormDateTimeLabelTime = styled(RouteFormDateTimeLabel)`
  // flex: 0 0 88px;
  // max-width: 88px;
  // margin-left: 0;
  cursor: pointer;
`;
export const RouteFormDateTimeInput = styled.input`
  border: none;
  box-sizing: border-box;
  outline: 0;
  // padding: .75rem;
  padding: 8px 4px;
  width: 100%;
  height: 36px;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
`;
export const RouteFormDateTimeSelect = styled.select`
  border: none;
  box-sizing: border-box;
  outline: 0;
  // padding: .75rem;
  padding: 8px 4px;
  width: 100%;
  height: 36px;

  cursor: pointer;
  background-color: var(--color--accent-lite);

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
`;
export const RouteFormDateTimeTitle = styled.p`
  flex: 0 0 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 96px;
  height: 20px;
`;

export const RouteFormScheduleWeekList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const RouteFormScheduleWeekItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 12px;

  &:first-of-type {
    order: 7;
  }
`;
export const RouteFormScheduleWeekName = styled.p`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
`;

export const RouteFormScheduleWeekLabelWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 64px;
  width: 64px;
`;
export const RouteFormScheduleWeekLabel = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  color: var(--color--gray);

  border-radius: 4px;
  border: 1px solid var(--color--gray);
  background-color: #fff;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    font-size: 22px;
    font-weight: 500;
    color: currentColor;
    transition: 200ms linear;
    // transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }

  input:disabled ~ & {
    background-color: var(--color--bg-light);
    cursor: not-allowed;
    // border-color: var(--color--accent-lite);
  }
  input:checked ~ & {
    color: var(--color--white);
    background-color: var(--color--accent);
    border-color: var(--color--accent);
    background-image: url("${process.env.PUBLIC_URL}/icons/check-solid.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px 16px;

    // &::before {opacity: 1}
  }
  input:checked:disabled ~ & {
    color: var(--color--accent);
    background-color: var(--color--accent-lite);
    border-color: var(--color--accent-lite);
  }
`;
export const RouteFormScheduleWeekInputCheckbox = styled.input`
  display: none;
`;

export const RouteFormScheduleWeekLastDateWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const RouteFormPriceTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;
export const RouteFormPriceTitle = styled.p`
  // flex: 0 0 64px;
  // width: 64px;
  height: 20px;
`;

export const RouteFormPriceItem = styled.li`
  position: relative;
  display: flex;
  gap: 16px;
  padding: 12px 0;
  height: 60px;

  border-top: 1px solid var(--color--gray);
  &:last-of-type {
    border-bottom: 1px solid var(--color--gray);
  }
`;

export const RouteFormPriceName = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;

  & span {
    font-size: 12px;
  }
  &:first-of-type {
    flex: 0 0 120px;
  }
  &:last-of-type {
    flex: 1 0 120px;
  }
`;
