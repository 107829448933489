import { createSlice } from "@reduxjs/toolkit";
import { createRoute, getRoutes } from "./operations";
import { updateRouteById } from "./operations/updateRouteById";

const initialState = {};

export const routesSlise = createSlice({
  name: "routes",
  initialState,
  reducers: {
    resetRoutesState() { return initialState; },
    resetRoutesStatus(state) { state.status = ""; },
    resetRoutesError(state) { state.error = null; },
    addRoutePoint(state, action) {
      const {storageKey, selectedItem} = action.payload;

      state.new = state.new ? state.new = {...state.new} : {}
      state.new[storageKey] = selectedItem;
    },
    updateExtraPoints(state, action) {
      state.new.extraPoints = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      // createRoute
      .addCase(createRoute.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(createRoute.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createRoute.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      // getRoutes
      .addCase(getRoutes.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getRoutes.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getRoutes.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateRouteById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateRouteById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.route = action.payload;
      })
      .addCase(updateRouteById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

  

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetRoutesState, resetRoutesStatus, resetRoutesError, addRoutePoint, updateExtraPoints } = routesSlise.actions;
export const routesReducer = routesSlise.reducer;
