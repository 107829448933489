import styled from "styled-components";

export const InputLocationList = styled.ul`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;

  display: flex;
  flex-direction: column;
  width: 100%;

  border-radius: var(--border-radius);
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(90, 100, 109, 0.2);

  overflow: hidden;
  z-index: 2;
`;
export const InputLocationItem = styled.li`
  &:not(:first-of-type) {
    border-top: 1px solid var(--color--gray);
  }
`;
export const InputLocationItemBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 8px;

  background-color: #fff;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--color--white);
    background-color: var(--color--accent);
  }
`;
export const InputLocationLangTitle = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;

  text-transform: uppercase;
`;
