import getDurationBetweenTwoPoints from "./getDurationBetweenTwoPoints";

export default function addTripBusinessDirections(pointsList) {
  const availableDepartureArrivalPoints = pointsList?.reduce(
    (previousValue, element) => {
      const { availableDeparture, availableArrival } = previousValue;
      
      if (element) availableDeparture.push(element);
      if (element) availableArrival.push(element);
      
      return { availableDeparture, availableArrival };
    },
    { availableDeparture: [], availableArrival: [] }
    );
  const { availableDeparture, availableArrival } = availableDepartureArrivalPoints;
  const tripDirections = availableDeparture.reduce((previousValue, element) => {
    const result = availableArrival
      .filter((i) => i.point_order > element.point_order)
      .map((i) => {
        const departure = { ...element };
        const arrival = { ...i };
        const duration = getDurationBetweenTwoPoints(departure, arrival)
        return { departure, arrival, duration};
      })
      .sort((a, b) => { return b.arrival.point_order - a.arrival.point_order; });

    const final = previousValue.concat(result)
      .map((item, index) => {
      return {dfb_order: index + 1, ...item}
    })

    return final
  }, []);
  
  return tripDirections;
}




// OLD VERSION


// export default function addTripBusinessDirections(pointsList) {
//   const availableDepartureArrivalPoints = pointsList?.reduce(
//     (previousValue, element) => {
//       const { availableDeparture, availableArrival } = previousValue;

//       if (element.features.boarding) availableDeparture.push(element);
//       if (element.features.unboarding) availableArrival.push(element);

//       return { availableDeparture, availableArrival };
//     },
//     { availableDeparture: [], availableArrival: [] }
//   );
//   const { availableDeparture, availableArrival } = availableDepartureArrivalPoints;
//   const tripDirections = availableDeparture.reduce((previousValue, element) => {
//     const result = availableArrival
//       .filter((i) => i.point_order > element.point_order)
//       .map((i) => {
//         // set departure and arrival from points
//         const departure = { ...element };
//         const arrival = { ...i };
//         delete departure.features;
//         delete arrival.features;
//         // delete departure.order
//         // delete arrival.order
//         // set duration from points
//         // const duration = getTimeBetweenDates(
//         //   new Date(`${departure.date}, ${departure.time}`),
//         //   new Date(`${arrival.date}, ${arrival.time}`)
//         // );

//         return {
//           // order: index + 1,
//           departure,
//           arrival,
//           // duration,
//         };
//       })
//       .sort((a, b) => {
//         return b.arrival.point_order - a.arrival.point_order;
//       });

//     const final = previousValue.concat(result)
//       .map((item, index) => {
//       return {...item, order: index + 1}
//     })

//     return final
//   }, []);

//   return tripDirections;
// }