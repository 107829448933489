import React from "react";
import { createPortal } from "react-dom";
import Sidebar from "./Sidebar";

const modalRoot = document.querySelector("#modal-root");

const SidebarWrap = ({ isSmallView, isBurgerMenuOpen, setIsBurgerMenuOpen  }) => {
  return isSmallView 
    ? createPortal(<Sidebar isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} isSmallView={isSmallView}/>, modalRoot) 
    : <Sidebar isBurgerMenuOpen={isBurgerMenuOpen} setIsBurgerMenuOpen={setIsBurgerMenuOpen} />;
};

export default SidebarWrap;
