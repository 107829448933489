import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
// redux
import { selectUser, selectIsLoggedIn, selectIsRefreshing, selectIsLoggedOut, selectToken } from 'redux/auth/authSelectors';

const useAuth = () => {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);

  let authState = {
    user,
    isLoggedIn: useSelector(selectIsLoggedIn),
    isLoggedOut: useSelector(selectIsLoggedOut),
    isRefreshing: useSelector(selectIsRefreshing),
  }

  if (token) {
    const decoded = jwtDecode(token);
    const { role, permissions } = decoded?.userInfo;

    authState = {...authState, user: {...user, role, permissions}}
  }


  return authState
};

export default useAuth;