import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const updateDirections = createAsyncThunk(
  "trips/updateDirections",
  async (data, thunkAPI) => {
    try {
      const res = await axios.post(`/directions`, data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);

