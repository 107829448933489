import React from "react";
// components
import SelectDiscount from "components/Form/Select/SelectDiscount";
import InputTypeTextWithOutValid from "components/Form/Input/InputTypeTextWithOutValid";
// styled
import { TripFormDateTimeInputClassic, TripFormDateTimeLabel } from "components/AddTripForm/AddTripSchedule.styled";
import { BookingFormDeletePassengerBtn, BookingFormPrice, BookingFormPriceWrap, BookingFormTitle, BookingFormTitleWrap } from "./BookingForm.styled";
import BasicSelect from "components/Form/Select/BasicSelect";
import { text_global } from "components/common/text";

const BookingFormPassenger = ({ passengerData, idx, currentLang, resetState, currentPrice, discounts, seatsSelection, handleSelectClick, handleDeletePassenger, handleInputChange, handleSelectSalutationClick }) => {
  const today = new Date().toJSON()?.slice(0, 10);
  const selectOptionsDataSalutation = [
    { optionType: 'mr', optionClass: "mr", optionTitle: text_global.mr[currentLang], },
    { optionType: 'mrs', optionClass: "mrs", optionTitle: text_global.mrs[currentLang], },
  ]

  const handleSelectSalutation = (data) => {
    if (!data) return
    handleSelectSalutationClick(data, passengerData?.passenger_id)
  }

  return (
    <>
      <BookingFormTitleWrap>
        <BookingFormTitle>
          {text.passenger[currentLang]} {idx + 1}
          <span> {passengerData?.ow_seat_number ? ` | ${text.seat_number[currentLang]} ${passengerData?.ow_seat_number}` : ""}</span>
          <span style={{marginLeft: "auto"}}> {passengerData?.rt_seat_number ? ` | ${text.back[currentLang]} ${passengerData?.rt_seat_number}` : ""}</span>
        </BookingFormTitle>
        {seatsSelection && idx > 0 && <BookingFormDeletePassengerBtn type="button" onClick={() => handleDeletePassenger(passengerData?.passenger_id)} />}
      </BookingFormTitleWrap>
      
      <InputTypeTextWithOutValid handleInputChange={handleInputChange} initValue={passengerData?.last_name} id={passengerData?.passenger_id} required={true} resetState={resetState} items={3} name={'last_name'} placeholder={text.last_name[currentLang]} />
      <InputTypeTextWithOutValid handleInputChange={handleInputChange} initValue={passengerData?.first_name} id={passengerData?.passenger_id} required={true} resetState={resetState} items={3} name={'first_name'} placeholder={text.first_name[currentLang]} />
      <TripFormDateTimeLabel style={{ height: "44px", maxWidth: "none", flex: "1 0 300px"}}>
        {/* <p>{passengerData?.birth_date ? getLocalDate(passengerData?.birth_date) : text.birth_date[currentLang]}</p> */}
        <TripFormDateTimeInputClassic style={{ height: "44px"}} id={passengerData?.passenger_id} required name={"birth_date"} type="date" max={today} value={passengerData?.birth_date} onChange={handleInputChange} />
      </TripFormDateTimeLabel>
      <InputTypeTextWithOutValid handleInputChange={handleInputChange} initValue={passengerData?.passport_id} id={passengerData?.passenger_id} required={true} resetState={resetState} items={3} name={'passport_id'} placeholder={text.passport_id[currentLang]} />
      <BookingFormPriceWrap style={{"--items": 3}}>
        <BasicSelect
          items={3}
          className={"salutation"}
          placeholder={text_global.salutation[currentLang]}
          currentLang={currentLang}
          selectOptionsData={selectOptionsDataSalutation}
          setValue={handleSelectSalutation}
        />
        <BookingFormPrice> {text.price[currentLang]}: <span>{currentPrice?.price || 0} €</span> </BookingFormPrice>
      </BookingFormPriceWrap>
      
      <SelectDiscount initValue={passengerData?.discount} id={passengerData?.passenger_id} currentLang={currentLang} placeholder={text.discounts[currentLang]} selectOptionsData={discounts} handleSelectClick={handleSelectClick} />
    </>
  );
};

export default BookingFormPassenger;

const text = {
  first_name: {
    de: "Vorname",
    ru: "Имя",
    ua: "І'мя",
  },
  last_name: {
    de: "Nachname",
    ru: "Фамилия",
    ua: "Прізвище",
  },
  birth_date: {
    de: "Geburtsdatum",
    ru: "Дата рождения",
    ua: "Дата народження",
  },
  passport_id: {
    de: "Passport-ID",
    ru: "Номер паспорта",
    ua: "Номер паспорта",
  },
  select: {
    de: "Wählen",
    ru: "Выбрать",
    ua: "Обрати",
  },
  passenger: {
    de: "passenger",
    ru: "Пассажир",
    ua: "Пасажир",
  },
  discounts: {
    de: "Rabatte",
    ru: "Скидки",
    ua: "Знижки",
  },
  price: {
    de: "Preis",
    ru: "Цена",
    ua: "Ціна",
  },

  seat_number: {
    de: "Sitz",
    ru: "Место",
    ua: "Місце",
  },
  back: {
    de: "zurück Sitz",
    ru: "обратно Место",
    ua: "назад Місце",
  },


  street: {
    de: "Straße",
    ru: "Улица",
    ua: "Вулиця",
  },
  postcode: {
    de: "PLZ",
    ru: "Почтовый индекс",
    ua: "Поштовий індекс",
  },
  city: {
    de: "Stadt",
    ru: "Город",
    ua: "Місто",
  },
  tel: {
    de: "Telefonnummer",
    ru: "Номер телефона",
    ua: "Номер телефону",
  },
  tel_mobile: {
    de: "Handynummer",
    ru: "Номер мобильного телефона",
    ua: "Номер мобільного телефону",
  },
};
