import { createSlice } from "@reduxjs/toolkit";
import { createDiscount, getDiscounts } from "./operations";
import { updateDiscountById } from "./operations/updateDiscountById";

const initialState = {};

export const discountsSlise = createSlice({
  name: "discounts",
  initialState,
  reducers: {
    resetDiscountsState() { return initialState; },
    resetDiscountsStatus(state) { state.status = ""; },
    resetDiscountsError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      // createDiscount
      .addCase(createDiscount.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createDiscount.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createDiscount.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })
      // getDiscounts
      .addCase(getDiscounts.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getDiscounts.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getDiscounts.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })
      .addCase(updateDiscountById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateDiscountById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.discount = action.payload;
      })
      .addCase(updateDiscountById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })


  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetDiscountsState, resetDiscountsStatus, resetDiscountsError } = discountsSlise.actions;
export const discountsReducer = discountsSlise.reducer;
