import React from "react";
// redux
import { useSelector } from 'react-redux';
import { selectLang } from 'redux/lang/langSelectors';
// styled
import { Container, PageTitle, Section } from "components/common/common.styled";


const ErrorNotFoundPage = () => {
  const currentLang = useSelector(selectLang) || "de";

  return (
    <Section>
      <Container>
        <PageTitle>{text.title[currentLang]}</PageTitle>
      </Container>
    </Section>
  );
};

export default ErrorNotFoundPage;

const text = {
  title: {
    de: "404 Seite nicht gefunden",
    ru: "404 Страница не найдена",
    ua: "404 Сторінка не знайдена",
  },
}
