import { createSlice } from "@reduxjs/toolkit";
import { createUser, getConsolidators, getUsers, updateUserById } from "./operations";
import { updateUsers } from "./operations/updateUsers";

const initialState = {};

export const usersSlise = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetUsersState() { return initialState; },
    resetUsersStatus(state) { state.status = ""; },
    resetUsersError(state) { state.error = null; },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createUser.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getUsers.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.list = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

      .addCase(updateUsers.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(updateUsers.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.updatedList = action.payload;
      })
      .addCase(updateUsers.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })
      .addCase(updateUserById.pending, (state, action) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateUserById.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;

        state.user = action.payload;
      })
      .addCase(updateUserById.rejected, (state, action) => {
        state.status = "fail";
        state.error = action.payload;
      })

      .addCase(getConsolidators.pending, (state, action) => {
        state.status = "pending";
        state.error = null;
      })
      .addCase(getConsolidators.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.error = null;

        state.consolidators = action.payload;
      })
      .addCase(getConsolidators.rejected, (state, action) => {
        state.status = "rejected";
        state.error = action.payload;
      })

  // template
  // .addCase(template.pending, (state, action) => {})
  // .addCase(template.fulfilled, (state, action) => {})
  // .addCase(template.rejected, (state, action) => {})
});

export const { resetUsersState, resetUsersStatus, resetUsersError } = usersSlise.actions;
export const usersReducer = usersSlise.reducer;
