export const selectTripsStatus = state => state?.trips?.status;
export const selectTripsError = state => state?.trips?.error;
export const selectTripsList = state => state?.trips?.list;
export const selectTripsTrip = state => state?.trips?.trip;
export const selectTripsTripReverse = state => state?.trips?.tripReverse;
export const selectTripsNew = state => state?.trips?.new;

export const selectTripsSearch = state => state?.trips?.search;

export const selectDirectionsList = state => state?.trips?.directions;
export const selectDirectionsItem = state => state?.trips?.direction;
// export const selectDirectiondById = state => state?.trips?.direction_by_id;
