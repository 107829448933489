import React, { useState } from "react";
import { useSelector } from "react-redux";
// redux
import { selectLang } from "redux/lang/langSelectors";
import { getDayOfWeekByIndex, isAvailableObject } from "helpers";
// components
import InfoBtn from "components/InfoBtn/InfoBtn";
import BookingBtn from "components/BookingBtn/BookingBtn";

const TableRowTripsSearch = ({ tableRowData, currentPrice, handleBookingBtnClick }) => {
  const [showInfo, setShowInfo] = useState(false);
  const currentLang = useSelector(selectLang) || "de";
  const { departure, arrival, prices, carrier_name, reverse_direction = false, discounts, baggage, main_trip_direction } = tableRowData ?? {}
  const { ow_price, rt_price} = prices ?? {}
  const departure_days = departure?.days_of_week?.map((i) => getDayOfWeekByIndex(i, [currentLang]))?.join(", ");
  const arrival_days = arrival?.days_of_week?.map((i) => getDayOfWeekByIndex(i, [currentLang]))?.join(", ");
  const departureCity = departure?.city ? departure?.city[currentLang] : "-";
  const arrivalCity = arrival?.city ? arrival?.city[currentLang] : "-";

  const departureDateIndex = new Date(departure?.date).getDay();
  const arrivalDateIndex = new Date(arrival?.date).getDay();
  let departureAddress = departure?.address ? departure?.address[currentLang] : "-";
  let arrivalAddress = arrival?.address ? arrival?.address[currentLang] : "-";
  if (departure?.address_for_week && isAvailableObject(departure?.address_for_week)[departureDateIndex]) departureAddress = isAvailableObject(departure?.address_for_week[departureDateIndex]?.address)[currentLang] || departureAddress
  if (arrival?.address_for_week && isAvailableObject(arrival?.address_for_week)[arrivalDateIndex]) arrivalAddress = isAvailableObject(arrival?.address_for_week[arrivalDateIndex]?.address)[currentLang] || arrivalAddress

  return (
    <>
      <tr className="un_hover">
        <td className="id"><InfoBtn showInfo={showInfo} setShowInfo={setShowInfo} /></td>
        <td className="departure_city">{departureCity}</td>
        <td className="departure_days">{departure_days}</td>
        <td className="departure_time">{departure?.time}</td>
        <td className="arrow">-</td>
        <td className="arrival_time">{arrival?.time}</td>
        <td className="arrival_days">{arrival_days}</td>
        <td className="arrival_city">{arrivalCity}</td>
        <td className="ow_btn">{reverse_direction ? "" : <BookingBtn price={ow_price} type={"ow"} currentPrice={currentPrice} data={tableRowData} currentLang={currentLang} handleBookingBtnClick={handleBookingBtnClick} />}</td>
        <td className="rt_btn">{reverse_direction ? text.return_trip[currentLang] : <BookingBtn price={rt_price} type={"rt"} currentPrice={currentPrice} data={tableRowData} currentLang={currentLang} handleBookingBtnClick={handleBookingBtnClick} />}</td>
        {/* <td className="direction"> {main_trip_direction?.departure?.city ? main_trip_direction?.departure?.city[currentLang] : "-"} - {main_trip_direction?.arrival?.city ? main_trip_direction?.arrival?.city[currentLang] : "-"}</td> */}
        <td className="carrier_name">{carrier_name}</td>
      </tr>
      {showInfo && 
        <tr className="un_hover" style={{backgroundColor: "#FFF", alignItems: "flex-start"}}>
          <td style={{height: "100%", width: "100%", alignItems: 'flex-start', gap: "60px"}}>
            <div style={{display: "flex", gap: "8px"}}>
              <div style={{display: "flex", gap: "8px"}}>
                <p style={{fontWeight: 600}}>{text.discounts[currentLang]}:</p>
                <ul style={{display: "flex", flexDirection: "column", gap: '4px'}}>
                  {discounts?.map((i, idx) => 
                  <li key={idx} style={{display: "flex", gap: "16px", justifyContent: "space-between"}}>
                    <p>{i?.name[currentLang]}</p>
                    <p>{i?.value} {i?.type === "fixed" ? "euro": "%"}</p>
                  </li>)}
                </ul>
              </div>
            </div>
            <div style={{display: "flex", gap: "8px"}}>
              <p style={{fontWeight: 600}}>{text.baggage[currentLang]}:</p>
              <ul style={{display: "flex", flexDirection: "column", gap: '4px'}}>
                {baggage?.map((i, idx) => 
                <li key={idx} style={{display: "flex", gap: "16px", justifyContent: "space-between"}}>
                  <p>{i?.name[currentLang]}</p>
                  <p>{i?.value} {i?.type === "baggage" ? "kg": "%"}</p>
                </li>)}
              </ul>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: '4px'}}>
              <p style={{display: "flex", gap: "8px"}}><span style={{fontWeight: 600}}>{text.route[currentLang]}:</span> {main_trip_direction?.departure?.city[currentLang]} - {main_trip_direction?.arrival?.city[currentLang]}</p>
              <p style={{display: "flex", gap: "8px"}}><span style={{fontWeight: 600}}>{text.departure_place[currentLang]}:</span> {departureCity}, {departureAddress}</p>
              <p style={{display: "flex", gap: "8px"}}><span style={{fontWeight: 600}}>{text.arrival_place[currentLang]}:</span> {arrivalCity}, {arrivalAddress}</p>
            </div>
          </td>
        </tr>
      }
    </>
    
  );
};

export default TableRowTripsSearch;

const text = {
  departure_place: {
    de: "Abfahrtsort",
    ru: "Место отправления",
    ua: "Місце відправлення",
  },
  arrival_place: {
    de: "Ankunftsort",
    ru: "Место прибытия",
    ua: "Місце прибуття",
  },
  return_trip: {
    de: "Rückflug",
    ru: "Обратный рейс",
    ua: "Зворотній рейс",
  },
  true: {
    de: "Aktiv",
    ru: "Активный",
    ua: "Активний",
  },
  false: {
    de: "Passiv",
    ru: "Пассивный",
    ua: "Пасивний",
  },
  discounts: {
    de: "Rabatte",
    ru: "Скидки",
    ua: "Знижки",
  },
  baggage: {
    de: "Gepäck",
    ru: "Багаж",
    ua: "Багаж",
  },
  route: {
    de: "Strecke",
    ru: "Маршрут",
    ua: "Маршрут",
  },
};
