import styled from "styled-components";
import { ReactComponent as IconInfo } from "../../icons/info-outline.svg";

export const InfoButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 100%;

  color: var(--color--accent);
  font-size: inherit;
  line-height: 1;

  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: 100ms linear;

  // &:hover {
  //   color: var(--color--accent);

  //   & svg {fill: color: var(--color--accent);}
  // }
`;

export const InfoBtnIcon = styled(IconInfo)`
  flex: 0 0 16px;
  width: 16px;
  height: 16px;

  color: inherit;
  transition: 100ms linear;
  transform: rotate(180deg);
`;
