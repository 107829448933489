import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from "redux-persist";

import { langReducer } from "./lang/langSlice";
import { authReducer } from "./auth/authSlice";
import { usersReducer } from "./users/usersSlice";
import { carriersReducer } from "./carriers/carriersSlice";
import { agenciesReducer } from "./agencies/agenciesSlice";
import { locationsReducer } from "./locations/locationsSlice";
import { discountsReducer } from "./discounts/discountsSlice";
import { baggageReducer } from "./baggage/baggageSlice";
import { routesReducer } from "./routes/routesSlice";
import { tripsReducer } from "./trips/tripsSlice";
import { transportsReducer } from "./transports/transportsSlice";
import { bookingsReducer } from "./bookings/bookingsSlice";

const langPersistConfig = {
  key: "lang",
  storage,
  whitelist: ["lang"],
};
const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: ["token"],
};

export const store = configureStore({
  reducer: {
    lang: persistReducer(langPersistConfig, langReducer),
    auth: persistReducer(authPersistConfig, authReducer),
    users: usersReducer,
    carriers: carriersReducer,
    agencies: agenciesReducer,
    locations: locationsReducer,
    discounts: discountsReducer,
    baggage: baggageReducer,
    routes: routesReducer,
    trips: tripsReducer,
    transports: transportsReducer,
    bookings: bookingsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
