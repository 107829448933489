import styled from "styled-components";
import { minDevice } from "components/common/breakpoints";
import { Link } from "react-router-dom";

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 40px;

  color: inherit;

  header & {
    margin-right: auto;
  }
`;
export const LogoImg = styled.img`
  height: 40px;
  border-radius: 12px;
  
  pointer-events: none;

  @media ${minDevice.tablet} {
    width: 44px;
    height: 44px;
  }
`;
export const LogoText = styled.span`
  font-size: 20px;
  font-weight: 800;
`;