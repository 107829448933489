import React from "react";
import { useSelector } from "react-redux";
// redux
import useAuth from "hooks/useAuth";
import { selectLang } from "redux/lang/langSelectors";
// components
import Header from "components/Header/Header";
import AuthForm from "components/AuthForm/AuthForm";
import LoaderLocal from "components/Loader/LoaderLocal";
// styled
import { Container, Section } from "components/common/common.styled";
import { WelcomePageSection, WelcomePageSubtitle, WelcomePageTitle } from "pages/WelcomePage/WelcomePage.styled";
import { selectAuthError } from "redux/auth/authSelectors";

const AuthPage = () => {
  const { isRefreshing } = useAuth();
  const currentLang = useSelector(selectLang) || "de";
  const authError = useSelector(selectAuthError);

  return ( isRefreshing ? <LoaderLocal/> : (
    <>
      <Header />
      <WelcomePageSection>
        <Container>
          <WelcomePageTitle>{text.title[currentLang]}</WelcomePageTitle>
          <WelcomePageSubtitle>
            {text.subtitle[currentLang]}
          </WelcomePageSubtitle>
        </Container>
      </WelcomePageSection>
      <Section>
        <Container>
          <AuthForm currentLang={currentLang}/>
          {authError && <p style={{margin: "20px auto", maxWidth: "480px", textAlign: "center"}}>{text.error[currentLang]}</p>}
        </Container>
      </Section>
    </>
  ));
};

export default AuthPage;

const text = {
  title: {
    de: "Reise Zentrum ",
    ru: "Туристический центр",
    ua: "Центр подорожей",
  },
  subtitle: {
    de: "Informations- und Reservierungssystem",
    ru: "Система информации и бронирования",
    ua: "Інформаційно-бронювальна система",
  },
  sign_in: {
    de: "Melden Sie sich beim Konto an",
    ru: "Войти в аккаунт",
    ua: "Увійти в аккаунт",
  },
  error: {
    de: "Falscher Login oder Passwort",
    ru: "Неверный логин или пароль",
    ua: "Неправильний логін або пароль",
  }
};