import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLang } from "redux/lang/langSelectors";
import { changeLang } from "redux/lang/langSlice";

import { LangSwitcherBtn, LangSwitcherBtnWrap } from "./LangSwitcher.styled";

const LangSwitcher = () => {
  const [currentLang, setCurrentLang] = useState( useSelector(selectLang) || "de" );
  const dispatch = useDispatch();

  const handleClick = (lang) => {
    dispatch(changeLang(lang));
    setCurrentLang(lang);
  };

  return (
    <LangSwitcherBtnWrap>
      <LangSwitcherBtn
        type="button"
        className={currentLang === "de" && "current"}
        disabled={currentLang === "de"}
        onClick={() => handleClick("de")}
      >
        de
      </LangSwitcherBtn>
      <LangSwitcherBtn
        type="button"
        className={currentLang === "ru" && "current"}
        disabled={currentLang === "ru"}
        onClick={() => handleClick("ru")}
      >
        ru
      </LangSwitcherBtn>
      <LangSwitcherBtn
        type="button"
        className={currentLang === "ua" && "current"}
        disabled={currentLang === "ua"}
        onClick={() => handleClick("ua")}
      >
        ua
      </LangSwitcherBtn>
    </LangSwitcherBtnWrap>
  );
};

export default LangSwitcher;
