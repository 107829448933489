import React, { useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { login } from "redux/auth/authOperation";
// styled
import {
  AuthFormTag,
  AuthFormBtnSubmit,
  AuthLabel,
  AuthInput,
} from "./AuthForm.styled";
import { BasicFormEyeBlockedIcon, BasicFormEyeBtn, BasicFormEyeIcon } from "components/Form/Form.styled";

const AuthForm = ({ currentLang }) => {
  const [passwordIsHidden, setPasswordIsHidden] = useState(true);

  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    dispatch(
      login({
        email: form.elements.email.value,
        password: form.elements.password.value,
      })
    );
    form.reset();
  };

  return (
    <AuthFormTag onSubmit={handleSubmit} id="authForm">
      <AuthLabel>
        <AuthInput
          type="email"
          name="email"
          placeholder="Email"
          autoComplete="off"
          required={true}
        />
      </AuthLabel>
      <AuthLabel>
        <AuthInput
          type={passwordIsHidden ? "password" : "text"}
          name="password"
          placeholder={text.password[currentLang]}
          autoComplete="off"
          required={true}
        />
        <BasicFormEyeBtn
          type="button"
          onClick={() => setPasswordIsHidden(!passwordIsHidden)}
        >
          {passwordIsHidden ? (
            <BasicFormEyeIcon title={text.show_password[currentLang]} />
          ) : (
            <BasicFormEyeBlockedIcon title={text.hide_password[currentLang]} />
          )}
        </BasicFormEyeBtn>
      </AuthLabel>

      <AuthFormBtnSubmit type="submit"> {text.enter[currentLang]} </AuthFormBtnSubmit>
    </AuthFormTag>
  );
};

export default AuthForm;

const text = {
  enter: {
    de: "Einloggen",
    ru: "Войти",
    ua: "Увійти",
  },
  password: {
    de: "Kennwort",
    ru: "Пароль",
    ua: "Пароль",
  },
  show_password: {
    de: "Kennwort anzeigen",
    ru: "Показать пароль",
    ua: "Показати пароль",
  },
  hide_password: {
    de: "Kennwort ausblenden",
    ru: "Скрыть пароль",
    ua: "Сховати пароль",
  },
};
