import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getTripByIdReverse = createAsyncThunk(
  "trips/getTripByIdReverse",
  async (id, thunkAPI) => {
    try {
      const res = await axios.get(`/trips/${id}`);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);

