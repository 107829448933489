import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { ReactComponent as IconCog } from "./icons/cog.svg";
import { ReactComponent as IconUsers } from "./icons/users.svg";
import { ReactComponent as IconTravelBus } from "./icons/travel-bus.svg";
import { ReactComponent as IconBus } from "./icons/bus.svg";
import { ReactComponent as IconSearch} from "./icons/search.svg";
import { ReactComponent as IconDirection} from "./icons/direction.svg";
import { ReactComponent as IconLocation} from "./icons/location.svg";
import { ReactComponent as IconRoute} from "./icons/map.svg";
import { ReactComponent as IconOffice} from "./icons/office.svg";
import { ReactComponent as IconAgency} from "./icons/travel-agency-2.svg";
import { ReactComponent as IconBaggage} from "./icons/baggage.svg";
import { ReactComponent as IconDiscount} from "./icons/discount.svg";
import { ReactComponent as IconCreditCard} from "./icons/credit-card.svg";
import { ReactComponent as IconTicket} from "./icons/ticket.svg";


import { ReactComponent as IconSwap } from "./icons/swap.svg";
import { maxDevice } from "components/common/breakpoints";

export const PageNavTag = styled.nav`
  flex-grow: 1;
`;
export const PageNavList = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const PageNavItem = styled.li`
  // border-top: 1px solid var(--color--bg-light, #eff1f4);
  // border-bottom: 1px solid var(--color--bg-light, #eff1f4);

  &.settings {
    margin-top: auto;
  }
`;

export const PageNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 48px;
  gap: 8px;
  padding: 0 12px;

  color: var(--color--dark, #1e1e1e);
  font-size: 0px;
  white-space: nowrap;

  // border-bottom: 1px solid var(--color--bg-light, #eff1f4);

  &.active {
    color: var(--color--accent);
    background-color: var(--color--accent-lite); 
    // border-right: 2px solid var(--color--accent);
  }

  &:hover {
    color: var(--color--white, #fff);
    background: var(--color--accent, #14a0fd);
  }

  aside.isActive & {
    font-size: 14px;
  }

  @media ${maxDevice.laptop} { padding: 0 32px; }
  @media ${maxDevice.tablet} { padding: 0 16px; }
`;

export const BurgerMenuBtn = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
`;

export const PageNavIconCog = styled(IconCog)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconUsers = styled(IconUsers)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconBus = styled(IconBus)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconTravelBus = styled(IconTravelBus)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconSearch = styled(IconSearch)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconLocation = styled(IconLocation)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconRoute = styled(IconRoute)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconOffice = styled(IconOffice)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconAgency= styled(IconAgency)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconCreditCard = styled(IconCreditCard)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconBaggage = styled(IconBaggage)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconDiscount = styled(IconDiscount)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconDirection = styled(IconDirection)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
export const PageNavIconTicket = styled(IconTicket)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;




export const PageNavIconSwap = styled(IconSwap)`
  flex: 0 0 24px;
  width: 24px;
  height: 24px;

  color: inherit;
  transition: 200ms linear;

  aside.isActive & {
    flex: 0 0 20px;
    width: 20px;
    height: 20px;
  }
`;
