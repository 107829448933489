import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/users");
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
