export default function getFilteredCitiesByCountry(citiesList, currentCountry) {
  if (!citiesList) return [];
  if (!currentCountry) return citiesList;

  const filteredCitiesList = citiesList?.filter(i => i?.country?.de === currentCountry?.country?.de)
  return filteredCitiesList;
}

// export default function getFilteredCitiesByCountry(citiesList, currentCountry, locationsList) {
//   const allLocationsCurrentCountry = locationsList?.filter(i => i?.country?.de === currentCountry?.country?.de);
//   return citiesList?.filter(i => allLocationsCurrentCountry?.find(item => item?.city?.de === i?.city?.de)?.city);
// };
