import React from "react";
import PageNav from "components/PageNav/PageNav";
import LogoutBtn from "components/LogoutBtn/LogoutBtn";

import { Aside } from "./Sidebar.styled";

const Sidebar = ({ isBurgerMenuOpen, setIsBurgerMenuOpen }) => {
  return (
    <Aside className={isBurgerMenuOpen && "isActive"}>
      <PageNav setIsBurgerMenuOpen={setIsBurgerMenuOpen} />
      <LogoutBtn />
    </Aside>
  );
};

export default Sidebar;
