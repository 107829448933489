// import checkPasswordValidation from './AuthValidationPass';

export const validateName = e => {
  const re = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/;

  if (!re.test(String(e.target.value).toLowerCase())) {
    return 'A name must contain just A-Z, a-z, 0-9 characters';
  } else if (e.target.value.length < 2 || e.target.value.length > 16) {
    return 'A name must contain 2 to 16 characters.';
  } else return '';
};

export const validateText = (value) => {
  const isValidLength = /^.{2,40}$/;
  if (!isValidLength.test(value)) {
    return "text_error_text_1";
    // return "Password must be 2-40 Characters Long.";
  }
}

export const validateEmail = e => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!re.test(String(e.target.value).toLowerCase())) {
    return "Email can contain only latin letters, numbers, '@' and '.'";
  } else return '';
};

export const validateSecurePassword = (value) => {
  const isWhitespace = /^(?=.*\s)/;
  if (isWhitespace.test(value)) {

    return "password_error_text_1";
    // return "Password must not contain Whitespaces.";
  }

  const isValidLength = /^.{5,20}$/;
  if (!isValidLength.test(value)) {
    return "password_error_text_2";
    // return "Password must be 6-16 Characters Long.";
  }

  // const isContainsNumber = /^(?=.*[0-9])/;
  // if (!isContainsNumber.test(value)) {
  //   return "password_error_text_3";
  //   // return "Password must contain at least one Digit.";
  // }

  // const isContainsSymbol =
  //   /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹])/;
  // if (!isContainsSymbol.test(value)) {
  //   return "password_error_text_4";
  //   // return "Password must contain at least one Special Symbol.";
  // }

  return "";
}
