import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
// redux
// import { selectLang } from "redux/lang/langSelectors";
import { getLocationDistancebyUser } from "redux/locations/operations";
import { selectBookingsBooking } from "redux/bookings/bookingsSelectors";
import { getBookingById } from "redux/bookings/operations/getBookingById";
import { selectLocationsDistance } from "redux/locations/locationsSelectors";
// common
import { text_global } from "components/common/text";
// helpers
import { getLocalDate, isAvailableObject } from "helpers";
// components
import Loader from "components/Loader/Loader";
// styled
import { Invoice, InvoiceAgencyBox, InvoiceHeader, InvoiceBookingBox, InvoiceFooter, InvoiceFooterBox, InvoiceLogo, InvoiceLogoText, InvoiceLogoWrap, InvoiceBody, InvoiceTableRow, InvoiceTable, InvoiceMessageBox, InvoiceMessage, } from "./BookingInvoicePage.styled";

const BookingInvoicePage = () => {
  // const currentLang = useSelector(selectLang) || "de";
  const [render, setRender] = useState(true);
  const booking = useSelector(selectBookingsBooking);
  const locationsDistance = useSelector(selectLocationsDistance);
  const bookingId = useParams()?.bookingId;
  const dispatch = useDispatch();
  const { buch, type, departure, arrival, createdAt, creator, agency_info, user_data, carrier_name, passengers_list, passengers_contact_tel, price, commission, departure_reverse, arrival_reverse, invoice_text } = booking ?? {};
  const { short_name, enterprise_owner, enterprise_status, street, postal_code, city, tax } = agency_info ?? {};
  const newEnterpriseStatus = enterprise_status === "ltd" ? "Firnma" : "Reisebüro";

  const departureDate = new Date(departure?.date)?.toLocaleString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });
  const arrivalDate = new Date(arrival?.date)?.toLocaleString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });
  const departureTime = departure?.time;
  const arrivalTime = arrival?.time;
  const departureCity = departure?.city?.de;
  const arrivalCity = arrival?.city?.de;
  const locationsDistanceDeparture = locationsDistance?.find(i => i?.city?.de === departure?.city?.de);
  const locationsDistanceArrival = locationsDistance?.find(i => i?.city?.de === arrival?.city?.de);
  const departureDateIndex = new Date(departure?.date).getDay();
  let departureAddress = departure?.address?.de || "";
  if (departure?.address_for_week && isAvailableObject(departure?.address_for_week)[departureDateIndex]) departureAddress = isAvailableObject(departure?.address_for_week[departureDateIndex]?.address?.de) || departureAddress

  // ===== reverse =====
  const departureDeverseDateIsFree = departure_reverse?.date === 'free';
  const reverseDepartureDate = new Date(departure_reverse?.date)?.toLocaleString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });
  const reverseArrivalDate = new Date(arrival_reverse?.date)?.toLocaleString("uk-UA", {
    day: "numeric",
    month: "numeric",
    year: "2-digit",
  });
  const reverseDepartureTime = departure_reverse?.time;
  const reverseArrivalTime = arrival_reverse?.time;
  const reverseDepartureCity = departure_reverse?.city?.de;
  const reverseArrivalCity = arrival_reverse?.city?.de;
  const reversedepartureDateIndex = new Date(departure_reverse?.date).getDay();
  let reverseDepartureAddress = departure_reverse?.address?.de || "";
  if (departure_reverse?.address_for_week && isAvailableObject(departure_reverse?.address_for_week)[reversedepartureDateIndex]) reverseDepartureAddress = isAvailableObject(departure_reverse?.address_for_week[reversedepartureDateIndex]?.address?.de) || departureAddress

  const newPrice = parseFloat(price).toFixed(2);
  const passengersQuantity = passengers_list?.length;
  let km1 = 0;
  let km2 = 0;

// Deutschland
  if (locationsDistanceDeparture?.country?.de === "Deutschland") {
    km1 = locationsDistanceDeparture?.distance;
    km2 = locationsDistanceArrival?.distance;
  }
  else if (locationsDistanceArrival?.country?.de === "Deutschland") {
    km1 = locationsDistanceArrival?.distance;
    km2 = locationsDistanceDeparture?.distance;
  }
  else if (locationsDistanceDeparture?.country?.de !== "Deutschland" && locationsDistanceArrival?.country?.de !== "Deutschland") {
    km2 = locationsDistanceDeparture?.distance > locationsDistanceArrival?.distance ? locationsDistanceDeparture?.distance : locationsDistanceArrival?.distance;
  }

  let kef = (km1 / (km1 + km2)).toFixed(3);
  let a1 = (price * passengersQuantity * kef).toFixed(2);
  let a2 = (a1 / 1.19).toFixed(2);
  let a3 = (a1 - a2).toFixed(2);

  let b1 = (Number(price) * passengersQuantity * Number(commission || 0) / 100).toFixed(2);
  let b2 = (b1 / 1.19).toFixed(2);
  let b3 = (b1 - b2).toFixed(2);

  let subtotal = (price * passengersQuantity - b1).toFixed(2);

  let c1 = (a1 - b1).toFixed(2);
  let c2 = (a2 - b2).toFixed(2);
  let c3 = (a3 - b3).toFixed(2);

  if (creator?.type === "user") {
    c1 = a1;
    c2 = a2;
    c3 = a3;

    subtotal = (price * passengersQuantity).toFixed(2);
  }

  const total = subtotal;
  // const total = (price * passengersQuantity).toFixed(2);
 
  const downloadPDF = async () => {
    const capture = document.querySelector('#invoice');
    var opt = {
      margin:       [0, 0, -20, 0],
      filename:     `${buch}-invoice.pdf`,
      pagebreak: { mode: 'legacy', after: '.break-point'  },
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 3 },
      jsPDF:        { unit: 'mm', format: "a4", orientation: 'portrait'},
    };
    html2pdf().set(opt).from(capture).save();
  }

  useEffect(() => {
    if (render) return setRender(false);
    dispatch(getBookingById(bookingId));
  }, [render, dispatch, bookingId]);
  useEffect(() => {
    if (render) return
    if(booking) dispatch(getLocationDistancebyUser(booking?.consolidator_id));
  }, [render, dispatch, booking]);


  return booking ? ( 
    <>
      <button type="button" onClick={downloadPDF} style={{ height: "40px", marginBottom: "20px", color: "#fff", backgroundColor: "#63060a" }} > download PDF </button>
      <Invoice id="invoice">
        <InvoiceLogoWrap>
          <InvoiceLogo
            src={`${process.env.PUBLIC_URL}/images/logo-rz.jpg`}
            width={200}
            height={124}
          />
          <InvoiceLogoText>REISE-ZENTRUM</InvoiceLogoText>
        </InvoiceLogoWrap>
        <InvoiceHeader>
          {creator?.type === "user" ? 
            <InvoiceAgencyBox>
              <p style={{fontSize: "10px", textDecoration: "underline", marginBottom: "8px"}}>REISE-ZENTRUM · Hirzerweg 11 · 12107 · Berlin </p>
              <p>{user_data?.salutation}</p>
              <p>{user_data?.user_first_name} {user_data?.user_last_name}</p>
              <p>{user_data?.street}</p>
              <p>{user_data?.postal_code} {user_data?.city}</p>
            </InvoiceAgencyBox>
            :
            <InvoiceAgencyBox>
              <p style={{fontSize: "10px", textDecoration: "underline", marginBottom: "8px"}}>REISE-ZENTRUM · Hirzerweg 11 · 12107 · Berlin </p>
              <p>{newEnterpriseStatus}</p>
              <p>{short_name} Inh. {enterprise_owner}</p>
              <p>{street}</p>
              <p>{postal_code} {city}</p>
              <p>Steuernr.: {tax}</p>
            </InvoiceAgencyBox>
          }
          <InvoiceBookingBox>
            <p><span>Datum:</span> {getLocalDate(new Date())} </p>
            <p><span>Auftrag Nr:.</span> {buch}</p>
            <p><span>Auftragsdatum:</span> {getLocalDate(createdAt)}</p>
            <p><span>Bearbeiter:</span>Natalia Althof</p>
            <p style={{marginLeft: "auto"}}>030-30343979</p>
          </InvoiceBookingBox>
        </InvoiceHeader>

        <InvoiceBody>
          <InvoiceHeader style={{marginBottom: 0}}>
            <div>
              <h3 style={{marginBottom: "4px"}}>Rechnung / Gutschrift Nr.: {buch}</h3>
              <p>zu einem Vermittlungsauftrag</p>
            </div>
            <InvoiceBookingBox>
            <p><span>Kd.Telefon:</span> {passengers_contact_tel}</p>
            </InvoiceBookingBox>
          </InvoiceHeader>
          
          <InvoiceTable>
            <InvoiceTableRow className="title">
              <p>BUS Abfahrt</p>
              <p>Beförderer</p>
              <p>Reiseroute</p>
              <p>Ankunft</p>
              <p>BRD Strecke</p>
              <p>Rest Strecke</p>
            </InvoiceTableRow>
            <InvoiceTableRow className="row-1">
              <p>{departureDate}, {departureTime}</p>
              <p>{carrier_name}</p>
              <p>{departureCity} {"->"} {arrivalCity}</p>
              <p>{arrivalDate}, {arrivalTime}</p>
              <p>{km1} Km</p>
              <p>{km2} Km</p>
            </InvoiceTableRow>
            {type === "rt" && 
              <InvoiceTableRow className="row-1">
                <p>{departureDeverseDateIsFree ? text_global?.free?.de : `${reverseDepartureDate}, ${reverseDepartureTime}`}</p>
                <p>{carrier_name}</p>
                <p>{reverseDepartureCity} {"->"} {reverseArrivalCity}</p>
                <p>{departureDeverseDateIsFree ? text_global?.free?.de : `${reverseArrivalDate}, ${reverseArrivalTime}`}</p>
                <p>{km1} Km</p>
                <p>{km2} Km</p>
              </InvoiceTableRow>
            }

            <InvoiceTableRow className="row-2">
              <p><span style={{fontWeight: 500}}>Abfahrt Hin:</span> {departureAddress}</p>
              <p><span style={{fontWeight: 500}}>Abfahrt Zurück:</span> {departureDeverseDateIsFree ? '' : reverseDepartureAddress}</p>
            </InvoiceTableRow>

            <InvoiceTableRow className="row-3">
              <p>Teilnehmer:</p>
              <p>Geb.Datum</p>
              <p>Passnummer</p>
              <p>Platz</p>
              <p>Reduziert auf</p>
              <p>Preis</p>
            </InvoiceTableRow>
            {passengers_list?.map((i, idx) => (
              <InvoiceTableRow className="row-4" key={i?.passenger_id}>
                <p>{idx + 1}. {i?.last_name} / {i?.first_name}</p>
                <p>{getLocalDate(i?.birth_date)}</p>
                <p>{i?.passport_id}</p>
                <p><span>{i?.ow_seat_number}</span> {i?.rt_seat_number}</p>
                <p></p>
                <p>{newPrice} €</p>
              </InvoiceTableRow>
            ))}

            <InvoiceTableRow className="row-5">
              <p></p>
              <p><span>USt-pflicht. Betrag:</span> {a2} €</p>
              <p><span>zzgl. 19% USt.:</span> {a3} €</p>
              <p style={{display: "flex", justifyContent: "space-between"}}><span>Bruttobetrag:</span> {a1} €</p>
              <p></p>
            </InvoiceTableRow>
            {creator?.type !== "user" && <>
              <InvoiceTableRow className="row-6">
                <p style={{fontWeight: 500, marginLeft: "auto"}}>Provision {commission} %:</p>
                <p>{b1} €</p>
                </InvoiceTableRow>
              <InvoiceTableRow className="row-7">
                <p></p>
                <p><span>USt-pflicht. Betrag:</span> -{b2} €</p>
                <p><span>zzgl. 19% USt.:</span> -{b3} €</p>
                <p style={{display: "flex", justifyContent: "space-between"}}><span>Bruttobetrag:</span> -{b1} €</p>
                <p></p>
              </InvoiceTableRow>
            </> }

            <InvoiceTableRow className="row-8">
              <p style={{fontWeight: 500, marginLeft: "auto"}}>Rechnungsbetrag:</p>
              <p style={{fontWeight: 500}}>{subtotal} €</p>
            </InvoiceTableRow>
            <InvoiceTableRow className="row-9">
              <p>Gesamt USt-pflicht. Betrag:</p>
              <p>{km1 !== 0 && "-"} {c2} € </p>
              <p>zzgl. 19% USt.:</p>
              <p>{km1 !== 0 && "-"} {c3} €</p>
              <p style={{fontWeight: 500, marginLeft: "auto"}}>Gutschrift:</p>
              <p>0,00 €</p>
            </InvoiceTableRow>
            <InvoiceTableRow className="row-10">
              <p>USt. Bruttobetrag:</p>
              <p>{km1 !== 0 && "-"} {c1} €</p>
              <p style={{fontWeight: 500, marginLeft: "auto"}}>Bereits gezahlt:</p>
              <p>0,00 €</p>
            </InvoiceTableRow>
            <InvoiceTableRow className="row-11">
              <p style={{fontWeight: 600}}>Zahlungsplan:</p>
              <p style={{fontWeight: 600, marginLeft: "auto"}}>Offener Betrag:</p>
              <p style={{fontWeight: 600}}>{total} €</p>
            </InvoiceTableRow>
            <InvoiceTableRow className="row-12">
              <p>Zahlungsziel:</p>
              <p>SOFORT ohne Abzug</p>
            </InvoiceTableRow>
          </InvoiceTable>

          <InvoiceMessageBox>
            {/* <InvoiceMessageTitle>
              Bitte zahlen Sie den Gesamtbetrag sofort nach Erhalt der Rechnung. Bei verspaeteter Zahlung besteht keine Befoerderungsgarantie.
            </InvoiceMessageTitle> */}
            <InvoiceMessage>
              {invoice_text}
            </InvoiceMessage>
          </InvoiceMessageBox>
        </InvoiceBody>

        <InvoiceFooter className="break">
          <InvoiceFooterBox style={{maxWidth: "160px"}}>
            <p>REISE-ZENTRUM</p>
            <p>Hirzerweg 11</p>
            <p>12107 Berlin</p>
            <p>St.Nr: 21/205/60646</p>
          </InvoiceFooterBox>
          <InvoiceFooterBox >
            <p><span>Telefon:</span>030-30343979</p>
            <p><span>Fax:</span>030-30343978</p>
            <p><span>Email:</span>info@reise-zentrum.net</p>
            <p><span>USt.-Id.:</span>DE247744764</p>
          </InvoiceFooterBox>
          <InvoiceFooterBox className="break-point">
            <p>Postbank Berlin</p>
            <p>Kto-Nr.: 14527104 / BLZ: 10010010</p>
            <p>IBAN: DE74 1001 0010 0014 5271 04</p>
            <p>BIC / SWIFT: PBNKDEFF</p>
          </InvoiceFooterBox>
        </InvoiceFooter>
      </Invoice>
    </>
  ) : <Loader />
};

export default BookingInvoicePage;
