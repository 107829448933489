import styled from "styled-components";
import { minDevice } from "components/common/breakpoints";
import { Container } from "components/common/common.styled";

export const HeaderTag = styled.header`
  flex: 0 0 100%;
  color: var(--color--white, #fff);
  background-color: var(--color--accent);
  box-shadow: var(--box-shadow--main);
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px 0 8px;
  min-height: 48px;
  max-width: none;
`;
// export const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 12px;
//   min-height: 48px;
//   padding: 0 8px;

//   // @media ${minDevice.tablet} {
//   //   padding: 0 32px;
//   // }
//   @media ${minDevice.laptop} {
//     padding: 0 16px 0 8px;
//   }
// `;

export const HeaderLogoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media ${minDevice.laptop} {
    width: calc(200px - 8px - 12px);
  }
`;

export const HeaderBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color--white, #fff);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
