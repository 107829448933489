import React, { useEffect, useState } from "react";
// helpers
import { getLocalDateWithDayOfWeek } from "helpers";
// styled
import { BasicSelectOptionsList, BasicSelectOptionsBtn, } from "./BasicSelect.styled";
import { BookingFormSelectTag } from "components/BookingForm/BookingForm.styled";

const SelectTripDate = ({placeholder, currentLang = "de", selectOptionsData, handleSelectClick, className, disabled, reserve}) => {
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [activeOptions, setActiveOptions] = useState('');  
  const isActiveOptionsContains = selectOptionsData?.filter(i => i?.date === activeOptions?.date)

  const handleOptionsBtn = (i) => {
    if (i?.date === activeOptions?.date) return setShowOptionsList(!showOptionsList);

    setActiveOptions(i);
    setShowOptionsList(!showOptionsList);
    if(showOptionsList) handleSelectClick(i);
  };

  useEffect(() => {
    if (reserve)
    setActiveOptions('');
    setShowOptionsList(false);
  }, [reserve, selectOptionsData]);


  return (
    <BookingFormSelectTag className={disabled ? `${className} disabled` : className} >
      <BasicSelectOptionsList className={showOptionsList && "active"}>
        <BasicSelectOptionsBtn
          type="button"
          className={(!activeOptions || !isActiveOptionsContains) && "title"}
          style={{ height: "44px"}}
          onClick={() => handleOptionsBtn("")}
        >
          {placeholder ? placeholder : text.select_discounts[currentLang]}
        </BasicSelectOptionsBtn>

        {selectOptionsData &&
          selectOptionsData?.map((i, idx) => {
            
            return (
              <BasicSelectOptionsBtn
                key={idx}
                type="button"
                className={ activeOptions && activeOptions?.date === i?.date && "active" }
                style={{ height: "44px", justifyContent: "space-between"}}
                onClick={() => handleOptionsBtn(i, idx)}
              >
                {i?.date === "free" ? text.free[currentLang] : getLocalDateWithDayOfWeek(i?.date)[currentLang]} <span>{i?.price ? `${i?.price} €` : ''}</span>
              </BasicSelectOptionsBtn>
            );
          })}
      </BasicSelectOptionsList>
    </BookingFormSelectTag>
  );
};

export default SelectTripDate;

const text = {
  select_discounts: {
    de: "Rabatte auswählen",
    ru: "Выберите скидки",
    ua: "Виберіть знижки",
  },
  free: {
    de: "Offenes datum",
    ru: "Открытая дата",
    ua: "Відкрита дата",
  },
};