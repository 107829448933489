import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setErrorRes } from "helpers";

export const getCarriers = createAsyncThunk(
  "carriers/getCarriers",
  async (_, thunkAPI) => {
    try {
      const res = await axios.get("/carriers");
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(setErrorRes(e));
    }
  }
);
