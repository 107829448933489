// global
import styled from "styled-components";
// local
import { minDevice } from "components/common/breakpoints";
// import { ReactComponent as UserIcon } from '../../icons/user.svg';
// import { ReactComponent as MailIcon } from '../../icons/mail.svg';
// import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import { ReactComponent as EyeIcon } from '../../icons/eye.svg';
import { ReactComponent as EyeBlockedIcon } from '../../icons/eye-blocked.svg';

export const BasicForm = styled.form`
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }


  & .delete-text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 11;

    display: none;
  }

  &.delete {
    &::before {
      // content: "Delete ?";
      content: "";
  
      position: absolute;
      top: 0;
      left: 0;
  
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: calc(100% - 48px);
  
      background-color: var(--color--loader-backdroop, #eff1f4bf);
      z-index: 10;
    }

    & .delete-text { display: block; }
  }
`;
export const BasicFormBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--gap);

  & .locations_country { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 160px;
    max-width: 180px;

    background-color: #fff;
    border-radius: var(--border-radius);
    border: 1px solid;
    border-color: transparent;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
  & .locations_city { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 160px;
    max-width: 180px;

    background-color: #fff;
    border-radius: var(--border-radius);
    border: 1px solid;
    border-color: transparent;

    @media ${minDevice.desktop} { flex-grow: 1;}
  }
`;
export const FormSuccessMsgBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const BasicFormDecoreLine = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;

  background-color: var(--color--gray, #a6acb1);
`;

export const BasicFormBoxTitleWrap = styled.div`  
  display: flex;
`;
export const BasicFormBoxTitle = styled.strong`
  flex: 0 0 100%;
  display: block;
  min-height: 20px;
  margin-top: 20px;

  font-size: 16px;
  font-weight: 500;
`;
export const BasicFormBoxNote = styled.p`
  flex: 0 0 100%;
  min-height: 20px;

  font-size: 14px;
`;

export const BasicLabel = styled.label`
  position: relative;

  flex-grow: 1;
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  @media ${minDevice.tablet} {
    --items: 2;
  }

  &.success {
    svg {
      color: var(--color--success, #3cbc81);
    }
    input {
      border-color: var(--color--success, #3cbc81);
      opacity: 1;
    }
    p {
      color: var(--color--success, #3cbc81);
    }
  }
  &.alert {
    svg {
      color: var(--color--alert, #f6c23e);
    }
    input {
      border-color: var(--color--alert, #f6c23e);
      opacity: 1;
    }
    p {
      color: var(--color--alert, #f6c23e);
    }
  }
  &.fail {
    svg {
      color: var(--color--fail, #e74a3b);
    }
    input {
      border-color: var(--color--fail, #e74a3b);
      opacity: 1;
    }
    p {
      color: var(--color--fail, #e74a3b);
    }
  }


  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const BasicLabelSpan = styled.span`
  display: block;
  margin-bottom: 8px;
`;

export const BasicInputTypeText = styled.input`
  width: 100%;
  height: 44px;
  padding: 12px;

  color: var(--color--dark, #1e1e1e);
  font-size: 14px;
  line-height: 1;

  background-color: #fff;
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;

  &:hover {
    box-shadow: var(--box-shadow--main);
  }

  .disabled & {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }

  .edit & {
    height: 30px;
    border-radius: 0;
  }
`;
export const BasicInputTypeRadio = styled.input`
  &.hideDot {
    // display: none;

    position: absolute;
    transform: translateY(-50%);
    bottom: 0;
    left: 50%;

    white-space: nowrap;
    width: 4px;
    height: 4px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -4px;
  }
`;
export const BasicInputTypeRadioText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 60px;
  height: 44px;
  padding: 12px;

  color: var(--color--dark, #1e1e1e);
  font-size: 14px;
  line-height: 1;

  background-color: #fff;
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;
  
  &:hover {
    box-shadow: var(--box-shadow--main);
  }

  input:checked + & {
    color: #fff;
    background-color: var(--color--accent);
  }

  &.h32 {
    height: 32px;
  }
`;
export const BasicInputLangTitle = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;

  text-transform: uppercase;
`;


export const BasicFormValidaMsg = styled.p`
  position: absolute;
  top: calc(100% + 4px);
  
  left: 0px;

  color: var(--color--fail, #e74a3b);
  font-size: 11px;
  line-height: 1;

  .edit & {
    width: 300%;
  }
`;
export const BasicFormEyeBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;

  border:none;
  background-color: transparent;
  opacity: 0.5;

  &:hover, &:focus {
    opacity: 1;
  }

  &.edit {
    display: none;
  }
`;
export const BasicFormEyeIcon = styled(EyeIcon)`
  width: 18px;
  height: 18px;

  fill: var(--color--dark, #1e1e1e);
`;
export const BasicFormEyeBlockedIcon = styled(EyeBlockedIcon)`
  width: 18px;
  height: 18px;

  fill: var(--color--dark, #1e1e1e);
`;

export const BasicSubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  width: 100%;
  max-width: 300px;
  height: 44px;
  padding: 12px;
  margin: 20px 0 0 calc(100% - 300px);

  color: #fff;

  background-color: var(--color--accent);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &.relax {
    color: var(--color--gray);
    background-color: #fff;

    &:hover { color: var(--color--accent); }
  }

  &:hover {
    box-shadow: var(--box-shadow--main);
    border-color: #fff;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

 
`;
export const BasicNextBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 104px;
  height: 32px;
  padding: 12px;

  color: #fff;

  background-color: var(--color--accent);
  border-radius: var(--border-radius);
  border: 1px solid;
  border-color: transparent;
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow--main);
    border-color: #fff;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }

  &.relax {
    color: var(--color--gray);
    background-color: #fff;

    &:hover { color: var(--color--accent); }
  }
`;
export const BasicStatusMessage = styled.p`
  margin-top: 20px;

  font-size: 20px;
  font-weight: 500;

  &.success {color: var(--color--success, #3cbc81);}
  &.fail {color: var(--color--fail, #e74a3b);}
`;


export const InputTypeNumberEdit = styled.input`

  
`;
